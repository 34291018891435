<template>
  <component :is="tag" :id="id" role="group" :class="computedGroupClasses">
    <be-input-group-prepend v-if="hasPrependSlot || prepend">
      <be-input-group-text v-if="prepend">
        {{ prepend }}
      </be-input-group-text>

      <slot name="prepend" />
    </be-input-group-prepend>

    <slot />

    <be-input-group-append v-if="hasAppendSlot || append">
      <be-input-group-text v-if="append">
        {{ append }}
      </be-input-group-text>

      <slot name="append" />
    </be-input-group-append>
  </component>
</template>

<script>
import { generateId } from "@/utils/id";

export default {
  name: "BeInputGroup",

  props: {
    append: {
      type: String,
      required: false,
      default: null,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId(),
    },

    prepend: {
      type: String,
      required: false,
      default: null,
    },

    size: {
      type: String,
      required: false,
      default: null,

      validator: (value) => {
        return ["sm", "lg"].includes(value);
      },
    },

    tag: {
      type: String,
      required: false,
      default: "div",
    },
  },

  computed: {
    computedGroupClasses() {
      const { size } = this;

      return ["input-group", { [`input-group-${size}`]: size }];
    },

    hasAppendSlot() {
      return !!this.$slots.append;
    },

    hasPrependSlot() {
      return !!this.$slots.prepend;
    },
  },
};
</script>
