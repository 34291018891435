<template>
  <component
    :is="isBtnMode ? 'label' : 'div'"
    :class="isBtnMode ? computedButtonClasses : computedClasses"
  >
    <input
      :id="id"
      ref="input"
      v-model="computedLocalValue"
      :checked="isChecked"
      :name="computedName"
      :class="computedInputClasses"
      type="radio"
      :disabled="isDisabled || null"
      :required="isRequired || null"
      :form="computedForm"
      :aria-label="ariaLabel"
      :aria-labelledby="ariaLabelledby"
      :aria-required="isRequired || undefined"
      :value="checkedValue"
      @focus="handleFocus"
      @blur="handleFocus"
    />

    <label v-if="!isBtnMode" :for="id" :class="computedLabelClasses">
      <slot />

      <slot name="description">
        <small v-if="description" class="d-block text-muted">
          {{ description }}
        </small>
      </slot>
    </label>

    <slot v-else />
  </component>
</template>

<script>
import formStateMixin from "@/mixins/forms/form-state";
import { generateId } from "@/utils/id";

export default {
  name: "BeFormRadio",

  mixins: [formStateMixin],

  props: {
    ariaLabel: {
      type: String,
      required: false,
      default: null,
    },

    ariaLabelledby: {
      type: String,
      required: false,
      default: null,
    },

    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },

    checkedValue: {
      type: undefined,
      required: false,
      default: true,
    },

    description: {
      type: String,
      required: false,
      default: null,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    form: {
      type: String,
      required: false,
      default: null,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("be-form-radio"),
    },

    inline: {
      type: Boolean,
      required: false,
      default: false,
    },

    modelValue: {
      type: undefined,
      required: false,
      default: undefined,
    },

    name: {
      type: String,
      required: false,
      default: null,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    size: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["input", "change", "update:modelValue"],

  data() {
    return {
      localValue:
        typeof this.modelValue === "undefined"
          ? this.checkedValue
          : this.modelValue,

      hasFocus: false,
    };
  },

  computed: {
    computedLocalValue: {
      get() {
        if (this.isGroup && this.beGroup) {
          return this.beGroup.localValue;
        } else {
          return this.localValue;
        }
      },

      set(value) {
        const updateValue = value ? this.checkedValue : false;

        this.localValue = updateValue;
        this.$emit("input", updateValue);
        this.$emit("update:modelValue", updateValue);

        if (this.beGroup) {
          this.beGroup.localValue = this.checkedValue;
        }

        this.$nextTick(() => {
          this.$emit("change", this.localValue);

          if (this.beGroup) {
            this.beGroup.$emit("change", this.localValue);
          }
        });
      },
    },

    beGroup() {
      return this.isGroup ? this.$parent : null;
    },

    isBtnMode() {
      return this.isGroup ? this.beGroup.buttons : false;
    },

    isGroup() {
      return this.$parent.$options.name === "BeFormRadioGroup";
    },

    isInline() {
      return this.isGroup ? this.beGroup.inline : this.inline;
    },

    isChecked() {
      return this.isGroup
        ? this.beGroup.localValue === this.checkedValue
        : this.localValue === this.checkedValue;
    },

    isDisabled() {
      // Child can be disabled while group isn't, but is always disabled if group is
      return this.isGroup
        ? this.beGroup.disabled || this.disabled
        : this.disabled;
    },

    isRequired() {
      // Required only works when a name is provided for the input(s)
      // Child can only be required when parent is
      // Groups will always have a name (either user supplied or auto generated)
      return (
        this.computedName &&
        (this.isGroup ? this.beGroup.required : this.required)
      );
    },

    computedName() {
      // Group name preferred over local name
      return (this.isGroup ? this.beGroup.groupName : this.name) || null;
    },

    computedForm() {
      // Group form preferred over local form
      return (this.isGroup ? this.beGroup.form : this.form) || null;
    },

    computedSize() {
      // Group size preferred over local size
      return (this.isGroup ? this.beGroup.size : this.size) || null;
    },

    computedClasses() {
      const { isInline, computedSize } = this;
      return [
        "custom-control",
        "custom-radio",
        {
          "custom-control-inline": isInline,
          // Bootstrap does not support custom sizing for radios, so we add our own
          [`be-custom-control-${computedSize}`]: computedSize,
        },
      ];
    },

    computedInputClasses() {
      return ["custom-control-input", this.isBtnMode ? "" : this.stateClass];
    },

    computedLabelClasses() {
      return ["custom-control-label"];
    },

    computedButtonVariant() {
      const { isGroup, beGroup } = this;

      if (isGroup && beGroup.buttonVariant) {
        return beGroup.buttonVariant;
      }

      return "secondary";
    },

    computedButtonClasses() {
      const {
        computedSize,
        computedButtonVariant,
        isDisabled,
        isChecked,
        hasFocus,
      } = this;

      return [
        "btn",
        `btn-${computedButtonVariant}`,
        {
          [`btn-${computedSize}`]: computedSize,
          active: isChecked,
          disabled: isDisabled,
          focus: hasFocus,
        },
      ];
    },
  },

  watch: {
    modelValue(value) {
      if (value !== this.localValue) {
        this.localValue = value;
      }
    },
  },

  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }
  },

  methods: {
    handleFocus(event) {
      // When in buttons mode, we need to add the 'focus' class to label when input gets focus
      // This is because the input is hidden and the label is what is actually focused
      if (event.target) {
        if (event.type === "focus") {
          this.hasFocus = true;
        } else if (event.type === "blur") {
          this.hasFocus = false;
        }
      }
    },
  },
};
</script>
