<template>
  <be-badge
    v-if="unreadCount && unreadCount > 0"
    v-cloak
    variant="notification"
    pill
  >
    {{ unreadCount }}
  </be-badge>

  <slot v-else />
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const sessionStore = createNamespacedHelpers("session");

export default {
  computed: {
    ...sessionStore.mapGetters({
      unreadCount: "releaseNotesUnreadCount",
    }),
  },
};
</script>
