<template>
  <div>
    <div class="progress">
      <div
        class="progress-bar"
        :style="{ width: `${usedStoragePercentage}%` }"
        :class="{ 'bg-warning': $currentCompany.reached_storage_limit }"
      />
    </div>

    <p class="small">
      <em>
        {{
          $t("documents.index.total_usage", { usage: usedStoragePercentage })
        }}
      </em>
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      usedStoragePercentage: "company/usedStoragePercentage",
    }),
  },

  async created() {
    this.$store.dispatch("company/loadStoragePercentage");
  },
};
</script>
