import { mapGetters, mapActions } from "vuex";

export const InertiaForm = {
  computed: {
    ...mapGetters("inertia_form", [
      "getInertiaForm",
      "getInertiaFormErrors",
      "getInertiaFormGroupErrors",
      "getInertiaFormSuccessful",
      "getInertiaFormGroupAnyProcessing",
    ]),
  },

  methods: {
    ...mapActions("inertia_form", ["submitInertiaForm"]),
  },
};
