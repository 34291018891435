<template>
  <hr v-if="!tableMode" />

  <div class="mb-2 p-3 rounded">
    <h2 v-if="!tableMode" class="h4">
      {{ $t("models.dimension_entry.other") }}
    </h2>

    <be-form-group
      v-if="!tableMode"
      id="dimension-entries-form-group"
      label-for="dimension-entries"
      :label="$t('views.companies.dimensions.index.categories')"
      :error="getInertiaFormGroupErrors(['dimension_entries'])"
      :required="dimensionsRequired"
    >
      <be-form-select
        id="dimension-entries"
        v-model="formDimensionEntryIds"
        name="inquiry[dimension_entry_ids][id]"
        :options="dimensionEntries"
        include-blank-option
        :disabled="
          getInertiaFormGroupAnyProcessing([
            'dimension_entries',
            'dimension_entry_owners',
          ])
        "
        multiple
        @change="handleChangeInDimensionEntriesSelect($event)"
      >
        <template #tag="{ option }">
          {{ option.label }}
          <small class="text-muted text-xs"> ({{ option.parentLabel }}) </small>
        </template>

        <template #option="{ option }">
          {{ option.label }}
          <template v-if="isDimensionRequired(option)">
            <small class="text-muted">
              {{
                $t(
                  "components.companies.inquiries.inquiries_edit.dimensions.required"
                )
              }}
            </small>
          </template>
        </template>
      </be-form-select>
    </be-form-group>

    <template v-if="dimensionEntryOwners.length > 0">
      <h3 v-if="formDimensionEntryIds.length > 0 && !tableMode" class="h5">
        {{
          $t("views.companies.inquiries.create.dimension_entry_owners.quota")
        }}
      </h3>

      <div
        v-for="dimension in strippedDimensions"
        :key="dimension.id"
        class="mb-4"
      >
        <be-accordion>
          <be-accordion-item
            :id="`accordion-dimension-${dimension.id}`"
            :title="dimensionLabel(dimension.id)"
            :open="!tableMode"
          >
            <template #header-right>
              <be-status
                v-if="
                  getInertiaFormErrors({
                    group: 'dimension_entry_owners',
                    identifier: dimension.id,
                    field: 'quota',
                  })
                "
                variant="danger"
              >
                {{
                  $t(
                    "views.companies.inquiries.edit.dimension_entry_owner_error"
                  )
                }}
              </be-status>

              <i
                v-if="dimension.required && !dimension.full_quota"
                id="dimension-missing-quota"
                v-be-tooltip="
                  $t('views.companies.inquiries.edit.dimension_missing_quota')
                "
                class="fa-duotone fa-triangle-exclamation text-warning"
              >
              </i>
            </template>

            <be-alert
              v-if="
                getInertiaFormErrors({
                  group: 'dimension_entry_owners',
                  identifier: dimension.id,
                  field: 'quota',
                })
              "
              variant="danger"
            >
              {{
                getInertiaFormErrors({
                  group: "dimension_entry_owners",
                  identifier: dimension.id,
                  field: "quota",
                })
              }}
            </be-alert>

            <table class="table table-hover table-striped table-sm">
              <thead>
                <tr>
                  <th class="p-3 col-shrink">
                    {{
                      $t(
                        "views.companies.inquiries.create.dimension_entry_owners.category"
                      )
                    }}
                  </th>

                  <th class="p-3">
                    {{
                      $t(
                        "views.companies.inquiries.create.dimension_entry_owners.quota_th"
                      )
                    }}
                    (%)
                  </th>

                  <th class="p-3">
                    {{
                      $t(
                        "views.companies.inquiries.create.dimension_entry_owners.projected_value_th"
                      )
                    }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="entry in dimension.entries"
                  :id="`dimension-entry-${entry.dimension_entry_id}`"
                  :key="entry.id"
                >
                  <td class="p-3 col-shrink">
                    {{ `${dimensionEntryLabel(entry.dimension_entry_id)}` }}
                  </td>

                  <td class="p-3 pl-0">
                    <be-form-group class="col-sm mb-0 pl-0">
                      <be-input-group append="%" class="w-50">
                        <be-form-input
                          v-if="!tableMode"
                          v-model="form[entry.id]"
                          :name="`quota[${entry.dimension_entry_id}]`"
                          type="number"
                          min="0.000000"
                          max="100.000000"
                          step="0.000001"
                          :formatter="formatInputQuota"
                          :state="
                            getInertiaFormSuccessful({
                              group: `dimension_entry_owners`,
                              identifier: entry.dimension_id,
                            })
                          "
                          @change="updateEntryOwnerQuota(entry)"
                        />

                        <be-form-input
                          v-if="tableMode"
                          :model-value="getQuota(entry)"
                          :name="`quota[${entry.dimension_entry_id}]`"
                          :formatter="formatQuota"
                          type="number"
                          :disabled="tableMode"
                        />
                      </be-input-group>
                    </be-form-group>
                  </td>

                  <td class="p-3">
                    <money-format
                      v-if="grantedValue"
                      :id="`projected-value-${entry.dimension_entry_id}`"
                      :currency="currency"
                      :value="
                        decimalOfValue(
                          percentageToDecimal(
                            tableMode ? entry.quota : form[entry.id]
                          ),
                          grantedValue
                        )
                      "
                    />

                    <span v-else>-</span>
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td class="font-weight-bold p-3">
                    {{
                      $t(
                        "views.companies.inquiries.create.dimension_entry_owners.total"
                      )
                    }}
                  </td>

                  <td :id="`total-quota-${dimension.id}`" class="p-3">
                    <strong>{{
                      formatQuota(sumDimensionTotalQuota(dimension.entries))
                    }}</strong>
                  </td>

                  <td :id="`total-amount-${dimension.id}`" class="p-3">
                    <strong>
                      <money-format
                        v-if="grantedValue"
                        :currency="currency"
                        :value="sumDimensionTotalAmount(dimension.entries)"
                      />

                      <span v-else>-</span>
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </be-accordion-item>
        </be-accordion>
      </div>
    </template>
  </div>
</template>

<script>
import { InertiaForm } from "@/mixins/InertiaForm.js";
import { EventBus } from "@/event-bus";

export default {
  mixins: [InertiaForm],

  props: {
    inquiryId: {
      type: Number,
      required: true,
    },

    dimensions: {
      type: Array,
      default: () => [],
      required: false,
    },

    dimensionEntryOwners: {
      type: Array,
      default: () => [],
      required: false,
    },

    grantedValue: {
      type: Number,
      default: null,
    },

    currency: {
      type: String,
      default: "",
    },

    tableMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {},
      formDimensionEntryIds: this.dimensionEntryIds(),
    };
  },

  computed: {
    strippedDimensions() {
      return this.dimensionEntryOwners.reduce((acc, owner) => {
        if (!acc[owner.dimension_id]) {
          const dimension = this.dimensions.find(
            (dimension) => dimension.id === owner.dimension_id
          );
          acc[owner.dimension_id] = {
            id: owner.dimension_id,
            name: owner.name,
            entries: [],
            full_quota: dimension?.full_quota,
            required: dimension?.required,
          };
        }

        acc[owner.dimension_id].entries.push({
          ...owner,
          quota: this.decimalToPercentage(owner.quota),
        });

        return acc;
      }, {});
    },

    dimensionsRequired() {
      return this.dimensions.some((dimension) => dimension.required);
    },

    dimensionEntries() {
      const dimensionEntries = [];

      for (let dimension of this.dimensions) {
        dimensionEntries.push({
          label: dimension.name,

          options: dimension.dimension_entries.map((dimension_entry) => {
            return {
              label: dimension_entry.name,
              value: dimension_entry.id,
              parentLabel: dimension.name,
              dimensionRequired: dimension.required,

              disabled: this.getInertiaFormGroupAnyProcessing([
                "dimension_entries",
                "dimension_entry_owners",
              ]),
            };
          }),
        });
      }

      return dimensionEntries;
    },
  },

  watch: {
    form: {
      handler(oldValue, newValue) {
        if (Object.entries(newValue).length === 0) {
          return;
        }

        EventBus.emit(`dimension_entry_owners-submitting`, "unsaved_changes");
      },

      deep: true,
    },
  },

  created() {
    this.form = this.dimensionEntryOwners.reduce((acc, owner) => {
      acc[owner.id] = this.decimalToPercentage(owner.quota);

      return acc;
    }, {});
  },

  methods: {
    formatInputQuota(value) {
      if (parseFloat(value) > 100) {
        value = 100;
      }

      return this.formatQuota(value);
    },

    formatQuota(value) {
      if (value === "") {
        return "";
      }

      const formatted = Intl.NumberFormat("en-US", {
        style: "decimal",
        maximumFractionDigits: 6,
        roundingMode: "trunc",
      }).format(value);

      return formatted;
    },

    getQuota(entry) {
      return this.decimalToPercentage(
        this.dimensionEntryOwners.filter(
          (owner) => owner.dimension_entry_id === entry.dimension_entry_id
        )[0].quota
      );
    },

    isDimensionRequired(dimensionOption) {
      return dimensionOption.options?.some(
        (option) => option.dimensionRequired
      );
    },

    handleChangeInDimensionEntriesSelect(dimensionEntries) {
      if (!Array.isArray(dimensionEntries)) {
        return;
      }

      // Extract the dimension_entry_id from dimensionEntryOwners
      const savedEntries = this.dimensionEntryOwners.map(
        (owner) => owner.dimension_entry_id
      );

      // Remove dimension entry owners that are no longer selected
      for (const entryId of savedEntries) {
        if (!dimensionEntries.includes(entryId)) {
          this.removeEntryOwner(entryId);
        }
      }

      // Add new dimension entries that are selected but not yet owned
      for (const entryId of dimensionEntries) {
        if (!savedEntries.includes(entryId)) {
          this.addEntryOwner(entryId);
        }
      }
    },

    addEntryOwner(dimension_entry_id) {
      this.submitInertiaForm({
        method: "post",
        path: `/inquiries/${this.inquiryId}/dimension_entry_owners/`,
        formData: { dimension_entry_id },
        group: "dimension_entries",
        identifier: `dimension_entry_owner_${dimension_entry_id}`,
      });
    },

    removeEntryOwner(dimension_entry_id) {
      const dimensionEntryOwnerId = this.dimensionEntryOwners.find(
        (owner) => owner.dimension_entry_id === dimension_entry_id
      ).id;

      this.submitInertiaForm({
        method: "delete",
        path: `/inquiries/${this.inquiryId}/dimension_entry_owners/${dimensionEntryOwnerId}`,
        group: "dimension_entries",
        identifier: `dimension_entry_owner_${dimensionEntryOwnerId}`,
      });
    },

    updateEntryOwnerQuota(entry) {
      const dimensionId = entry.dimension_id;

      const allEntryOwnersFromDimensionId = this.dimensionEntryOwners.filter(
        (entry) => entry.dimension_id === dimensionId
      );

      const matchingFormEntries = Object.keys(this.form)
        .filter((key) =>
          allEntryOwnersFromDimensionId.some(
            (entry) => String(entry.id) === key
          )
        )
        .reduce((acc, key) => {
          acc[key] = this.form[key];
          return acc;
        }, {});

      const formData = {
        dimension_entry_owners: [],
      };

      for (const [key, value] of Object.entries(matchingFormEntries)) {
        const matchingOwner = allEntryOwnersFromDimensionId.find(
          (owner) => String(owner.id) === key
        );

        if (matchingOwner) {
          formData.dimension_entry_owners.push({
            id: matchingOwner.id, // ID of the matching owner
            quota: this.percentageToDecimal(value), // Convert percentage to decimal
            dimension_id: dimensionId,
          });
        }
      }

      this.submitInertiaForm({
        method: "patch",
        path: `/inquiries/${this.inquiryId}/bulk_dimension_entry_owners`,
        formData: formData,
        group: "dimension_entry_owners",
        identifier: entry.dimension_id,
      });
    },

    dimensionEntryIds() {
      const ids = this.dimensionEntryOwners.map(
        (entry) => entry.dimension_entry_id
      );

      if (ids.length === 0) {
        return null;
      }

      return ids;
    },

    percentageToDecimal(value) {
      return value ? (parseFloat(value) / 100).toFixed(8) : "";
    },

    decimalToPercentage(value) {
      return value ? (parseFloat(value) * 100).toFixed(6) : "";
    },

    decimalOfValue(decimal, value) {
      return value * decimal;
    },

    dimensionLabel(id) {
      if (!this.dimensions.length) {
        return "";
      }

      return this.dimensions.find((dimension) => dimension.id === id).name;
    },

    dimensionEntryLabel(id) {
      if (!this.dimensions.length) {
        return "";
      }

      for (let dimension of this.dimensions) {
        for (let entry of dimension.dimension_entries) {
          if (entry.id === id) {
            return entry.name;
          }
        }
      }

      return "";
    },

    sumDimensionTotalQuota(entries) {
      return (
        entries.reduce((acc, entry) => {
          let quota = this.form[entry.id];

          if (this.tableMode) {
            quota = entry.quota;
          }

          return acc + Number(quota);
        }, 0) || 0
      );
    },

    sumDimensionTotalAmount(entries) {
      const totalQuota = this.sumDimensionTotalQuota(entries);
      return this.decimalOfValue(
        this.percentageToDecimal(totalQuota),
        this.grantedValue
      );
    },
  },
};
</script>
