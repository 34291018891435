import Config from "@/config.js";
export default {
  computed: {
    roleOptions() {
      return Config.MEMBERSHIP_ROLES.map((role) => {
        return {
          text: this.$t(`models.membership.roles.${role}`),
          value: role,
        };
      });
    },

    functionOptions() {
      return Config.MEMBERSHIP_FUNCTIONS.map((_function) => {
        return {
          text: this.$t(`models.membership.functions.${_function}`),
          value: _function,
        };
      });
    },

    policyLevelOptions() {
      return [
        {
          text: this.$t("models.membership.policy_levels.admin"),
          value: "admin",
        },
        {
          text: this.$t("models.membership.policy_levels.observer"),
          value: "observer",
        },
        {
          text: this.$t("models.membership.policy_levels.limited"),
          value: "limited",
        },
      ];
    },
  },

  methods: {
    policyInformation(policy) {
      if (policy === "limited") {
        return this.$t(
          "components.memberships.form.policy_levels.limited_html"
        );
      } else if (policy === "observer") {
        return this.$t(
          "components.memberships.form.policy_levels.observer_html"
        );
      } else if (policy === "admin") {
        return this.$t("components.memberships.form.policy_levels.admin_html");
      }
      return "";
    },
  },
};
