<template>
  <tr @click.prevent="localChecked = !localChecked">
    <td class="text-center">
      <be-form-checkbox
        :id="`user-${membership.user.id}`"
        v-model="localChecked"
        @change="toggleChecked"
      >
      </be-form-checkbox>
    </td>

    <td class="shrink">
      <user-avatar :user="membership.user.id" />
    </td>

    <td align="left">
      {{ membership.user.name }}
      <membership-subtitle :membership="membership" />
    </td>

    <td class="shrink">
      <small v-if="userCanAcceptInvitation">
        <be-button
          variant="link"
          :href="url('/memberships')"
          target="_blank"
          rel="noopener noreferrer"
          @click.stop
        >
          {{ $t("invitations.not_accepted") }}
        </be-button>
      </small>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      required: true,
    },

    membership: {
      type: Object,
      required: true,
    },

    primaryMemberships: {
      type: Array,
      required: false,
      default: null,
    },

    secondaryMemberships: {
      type: Array,
      required: false,
      default: null,
    },
  },

  emits: ["user-checked-updated"],

  data() {
    return {
      localChecked: null,
    };
  },

  computed: {
    preChecked() {
      return (
        !!(
          this.primaryMemberships &&
          this.primaryMemberships.find(
            (membership) => membership.user.id == this.membership.user.id
          )
        ) ||
        !!(
          this.secondaryMemberships &&
          this.secondaryMemberships.find(
            (membership) => membership.user.id == this.membership.user.id
          )
        ) ||
        !!this.membership.board_member
      );
    },

    userCanAcceptInvitation() {
      return this.membership.user.can_accept_invitation;
    },
  },

  watch: {
    checked(newValue) {
      this.localChecked = newValue;
      this.$emit("user-checked-updated", this.membership.user, newValue);
    },
  },

  mounted() {
    this.localChecked = this.preChecked;
    this.$emit("user-checked-updated", this.membership.user, this.preChecked);
  },

  methods: {
    toggleChecked(checked) {
      this.$emit("user-checked-updated", this.membership.user, checked);
    },
  },
};
</script>
