<template>
  <div>
    <div class="ms-optgroup-label" @click.prevent="selectGroup()">
      {{ optgroup[0] }}
    </div>

    <div v-if="optgroup[1].length > 0" class="list-group list-group-flush">
      <a
        v-for="item in optgroup[1]"
        :key="item.id"
        href="#"
        class="list-group-item list-group-item-action"
        @click.prevent="selectItem(item)"
      >
        {{ item[valueSelector] }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listName: {
      type: String,
      required: true,
    },

    optgroup: {
      type: Array,
      required: true,
    },

    valueSelector: {
      type: String,
      required: true,
    },
  },

  emits: ["selectedItem", "selectedGroup"],

  methods: {
    selectItem(item) {
      this.$emit("selectedItem", item.id);
    },

    selectGroup() {
      this.$emit("selectedGroup", {
        listName: this.listName,
        groupId: this.optgroup.id,
      });
    },
  },
};
</script>
