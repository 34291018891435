<template>
  <div :class="['be-accordion-item', computedClasses]">
    <div
      class="be-accordion-item-header d-flex align-items-center justify-content-between"
      @click="toggleExpanded"
    >
      <div class="d-flex align-items-center">
        <div v-if="$slots['drag-handle']" class="mr-2">
          <slot name="drag-handle" />
        </div>

        <i v-if="iconLeft" :class="computedIconClasses" />

        <slot name="title">
          <p class="h6 m-0">{{ title }}</p>
        </slot>
      </div>

      <div class="d-flex align-items-center">
        <slot name="header-right" />

        <i v-if="!iconLeft" :class="computedIconClasses" />
      </div>
    </div>

    <be-collapse v-model="expanded">
      <div class="be-accordion-item-body">
        <slot />
      </div>

      <div v-if="$slots.footer" class="be-accordion-item-footer">
        <slot name="footer" />
      </div>
    </be-collapse>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: [Boolean, String],
      required: false,
      default: false,
    },

    title: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      expanded: false,
    };
  },

  computed: {
    computedClasses() {
      return {
        flush: this.flush,
      };
    },

    computedIconClasses() {
      return [
        "far",
        "fa-chevron-down",
        "text-muted",
        { "fa-flip-vertical": this.expanded },
        { "ml-2": !this.iconLeft },
        { "mr-2": this.iconLeft },
      ];
    },

    flush() {
      return this.$parent.flush;
    },

    iconLeft() {
      return this.$parent.iconLeft;
    },
  },

  watch: {
    open: {
      immediate: true,

      handler(value) {
        this.expanded =
          value === "true" ? true : value === "false" ? false : value;
      },
    },
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
