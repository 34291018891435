<template>
  <be-form-group
    :label-for="id"
    :label="label"
    :error="error"
    :required="required"
  >
    <be-form-select
      :id="id"
      v-model="inputValue"
      :disabled="disabled"
      :state="state"
      :options="options"
      :required="required"
      @change="onChange"
    />
  </be-form-group>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    state: {
      type: Boolean,
      required: false,
      default: null,
    },

    error: {
      type: String,
      required: false,
      default: null,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["input", "change"],

  data() {
    return {
      options: this.$i18n.availableLocales
        .filter((language) => language !== "default")
        .map((language) => {
          return { value: language, text: this.$t(`locales.${language}`) };
        }),
    };
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    onChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>
