<template>
  <component :is="tag" class="be-aspect-ratio d-flex">
    <div
      class="be-aspect-ratio-sizer flex-grow-1"
      :style="{
        paddingBottom: computedPaddingBottom,
        height: 0,
      }"
    />

    <div
      class="be-aspect-ratio-content flex-grow-1 w-100 mw-100"
      :style="{ marginLeft: '-100%' }"
    >
      <slot />
    </div>
  </component>
</template>

<script>
const regex = /^\d+(\.\d*)?[/:]\d+(\.\d*)?$/;
const separator = /[:/]/;

export default {
  name: "BeAspectRatio",

  props: {
    aspectRatio: {
      type: String,
      required: false,
      default: "1:1",
    },

    tag: {
      type: String,
      required: false,
      default: "div",
    },
  },

  computed: {
    computedPaddingBottom() {
      const { aspectRatio } = this;

      let ratio = 1;
      if (regex.test(aspectRatio)) {
        const [width, height] = aspectRatio
          .split(separator)
          .map((v) => Number(v) || 1);
        ratio = height / width;
      } else {
        ratio = Number(aspectRatio) || 1;
      }

      return `${ratio * 100}%`;
    },
  },
};
</script>
