<template>
  <component
    :is="wrapTag"
    class="be-overlay-wrap position-relative"
    :aria-busy="computedAriaBusy"
  >
    <slot v-if="!noWrap" />

    <transition
      name="fade"
      @after-enter="onAfterEnter"
      @after-leave="onAfterLeave"
    >
      <component
        :is="overlayTag"
        v-if="show"
        :class="computedOverlayClasses"
        :style="computedOverlayStyles"
        @click="onClick"
      >
        <div :class="computedBlurClasses" :style="computedBlurStyles" />

        <div class="position-absolute" :style="computedSpinnerWrapperStyles">
          <slot name="overlay">
            <be-spinner
              v-if="!noSpinner"
              :variant="spinnerVariant"
              :size="spinnerSize"
            />
          </slot>
        </div>
      </component>
    </transition>
  </component>
</template>

<script>
import { hexToRgb } from "@/utils/color";

export default {
  name: "BeOverlay",

  props: {
    bgColor: {
      type: String,
      required: false,
      default: undefined,
    },

    blur: {
      type: String,
      required: false,
      default: "1px",
    },

    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },

    noSpinner: {
      type: Boolean,
      required: false,
      default: false,
    },

    noWrap: {
      type: Boolean,
      required: false,
      default: false,
    },

    opacity: {
      type: [Number, String],
      required: false,
      default: 0.85,

      validator: (value) => {
        return value >= 0 && value <= 1;
      },
    },

    overlayTag: {
      type: String,
      required: false,
      default: "div",
    },

    overlayClass: {
      type: String,
      required: false,
      default: "",
    },

    rounded: {
      type: [Boolean, String],
      required: false,
      default: false,

      validator: (value) => {
        return [
          true,
          false,
          "sm",
          "lg",
          "pill",
          "circle",
          "top",
          "right",
          "bottom",
          "left",
        ].includes(value);
      },
    },

    show: {
      type: Boolean,
      required: false,
      default: false,
    },

    spinnerSize: {
      type: String,
      required: false,
      default: "lg",
    },

    spinnerVariant: {
      type: String,
      required: false,
      default: undefined,
    },

    variant: {
      type: String,
      required: false,
      default: "light",
    },

    wrapTag: {
      type: String,
      required: false,
      default: "div",
    },

    zIndex: {
      type: [Number, String],
      required: false,
      default: 10,
    },
  },

  emits: ["click", "hidden", "shown"],

  computed: {
    computedAriaBusy() {
      return this.show ? "true" : null;
    },

    computedBlurClasses() {
      return ["position-absolute", this.computedRounded];
    },

    computedBlurStyles() {
      const { bgColor, blur, computedVariant } = this;

      return {
        backdropFilter: blur ? `blur(${blur})` : undefined,
        backgroundColor: bgColor || computedVariant || undefined,
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      };
    },

    computedOverlayClasses() {
      const { fixed, noWrap } = this;

      return [
        "be-overlay",
        {
          "position-absolute": !noWrap || !fixed,
          "position-fixed": noWrap && fixed,
        },
        this.overlayClass,
      ];
    },

    computedOverlayStyles() {
      return {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        zIndex: this.zIndex,
      };
    },

    computedRounded() {
      const { rounded } = this;

      if (rounded === true || rounded === "") {
        return "rounded";
      } else if (!rounded) {
        return "";
      } else {
        return `rounded-${rounded}`;
      }
    },

    computedSpinnerWrapperStyles() {
      return {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      };
    },

    computedVariant() {
      const { opacity, variant } = this;
      const hexColor = this.getCSSColorVariable(variant);
      const rgbColor = hexToRgb(hexColor);

      return rgbColor ? `rgba(${rgbColor}, ${opacity})` : undefined;
    },
  },

  methods: {
    getCSSColorVariable(variant) {
      return (
        getComputedStyle(document.documentElement).getPropertyValue(
          `--${variant}`
        ) || undefined
      );
    },

    onAfterEnter() {
      this.$emit("shown");
    },

    onAfterLeave() {
      this.$emit("hidden");
    },

    onClick(event) {
      this.$emit("click", event);
    },
  },
};
</script>
