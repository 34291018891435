<template>
  <be-skeleton
    v-if="noAspectRatio"
    type="img"
    :class="{ [`card-img-${cardImgPosition}`]: cardImgPosition }"
    :animation="animation"
    :height="height"
    :variant="variant"
    :width="width"
  />

  <be-aspect-ratio v-else :aspect-ratio="aspectRatio">
    <be-skeleton
      type="img"
      :class="{ [`card-img-${cardImgPosition}`]: cardImgPosition }"
      :animation="animation"
      :height="height || '100%'"
      :variant="variant"
      :width="width"
    />
  </be-aspect-ratio>
</template>

<script>
export default {
  name: "BeSkeletonImg",

  props: {
    animation: {
      type: String,
      required: false,
      default: undefined,
    },

    aspectRatio: {
      type: String,
      required: false,
      default: "16:9",
    },

    cardImgPosition: {
      type: String,
      required: false,
      default: undefined,
    },

    height: {
      type: [Number, String],
      required: false,
      default: undefined,
    },

    noAspectRatio: {
      type: Boolean,
      required: false,
      default: false,
    },

    variant: {
      type: String,
      required: false,
      default: undefined,
    },

    width: {
      type: [Number, String],
      required: false,
      default: undefined,
    },
  },
};
</script>
