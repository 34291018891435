<template>
  <li :class="['nav-item', computedItemClasses]">
    <component
      :is="computedTag"
      v-bind="computedLinkAttrs"
      :class="['nav-link w-100', computedLinkClasses]"
      @click="onClick"
    >
      <span v-if="isWizard" class="nav-wizard-text">
        <slot />
      </span>

      <slot v-else />
    </component>
  </li>
</template>

<script>
export default {
  name: "BeNavItem",

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    href: {
      type: String,
      required: false,
      default: "",
    },

    itemClass: {
      type: String,
      required: false,
      default: undefined,
    },

    linkAttrs: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    linkClass: {
      type: String,
      required: false,
      default: undefined,
    },

    rel: {
      type: String,
      required: false,
      default: null,
    },

    tag: {
      type: String,
      required: false,
      default: "a",
    },

    target: {
      type: String,
      required: false,
      default: "_self",
    },
  },

  emits: ["click"],

  computed: {
    computedItemClasses() {
      return [
        {
          active: this.active,
          disabled: this.disabled,
        },
        this.itemClass,
      ];
    },

    computedLinkClasses() {
      return [
        {
          active: this.active,
        },
        this.linkClass,
      ];
    },

    computedLinkAttrs() {
      if (this.computedTag !== "a") {
        return {};
      }

      return {
        href: this.href,
        rel: this.rel,
        target: this.target,
        ...this.linkAttrs,
      };
    },

    computedTag() {
      if (this.tag !== "a") {
        return this.tag;
      }

      return this.href ? "a" : "button";
    },

    isWizard() {
      return this.$parent.$props.wizard;
    },
  },

  methods: {
    onClick(event) {
      if (this.disabled) {
        event.preventDefault();
        return;
      }

      this.$emit("click", event);
    },
  },
};
</script>
