<template>
  <div>
    <template
      v-for="element in rootElements"
      :key="`view-element-${element.id}`"
    >
      <component
        :is="element.component_type.replace(/_/, '-') + '-element-viewer'"
        :element="element"
        :elements-by-parent="elementsByParent"
        :report="report"
        :force-show="forceShow"
        :mode="mode"
      />
    </template>

    <div v-if="autoRead" v-be-visible="observeVisibilityOptions" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";
import sortBy from "lodash/sortBy";
import { computed } from "vue";

import DecisionsElementViewer from "./elements/DecisionsElementViewer.vue";
import GraphElementViewer from "./elements/GraphElementViewer.vue";
import ImageElementViewer from "./elements/ImageElementViewer.vue";
import PageBreakElementViewer from "./elements/PageBreakElementViewer.vue";
import ReportColumnsElementViewer from "./elements/ColumnsElementViewer.vue";
import ReportTableElementViewer from "./elements/TableElementViewer.vue";
import TextElementViewer from "./elements/TextElementViewer.vue";
import TiptapTextElementViewer from "./elements/TiptapTextElementViewer.vue";
import TiptapTitleElementViewer from "./elements/TiptapTitleElementViewer.vue";
import TitleElementViewer from "./elements/TitleElementViewer.vue";
import WidgetElementViewer from "./elements/WidgetElementViewer.vue";
import SpaceElementViewer from "./elements/SpaceElementViewer.vue";
import TasksElementViewer from "./elements/TasksElementViewer.vue";

import ReportMixin from "@/mixins/reports";

const AUTO_READ_AFTER_SECONDS = 1 * 1000; // 1 second

export default {
  components: {
    DecisionsElementViewer,
    GraphElementViewer,
    ImageElementViewer,
    PageBreakElementViewer,
    ReportColumnsElementViewer,
    ReportTableElementViewer,
    TextElementViewer,
    TiptapTextElementViewer,
    TiptapTitleElementViewer,
    TitleElementViewer,
    WidgetElementViewer,
    SpaceElementViewer,
    TasksElementViewer,
  },

  mixins: [ReportMixin],

  provide() {
    return {
      accentColor: computed(() => this.report.accent_color),
    };
  },

  props: {
    report: {
      type: Object,
      required: true,
    },

    mode: {
      type: String,
      required: false,
      default: "report",
    },

    autoRead: {
      type: Boolean,
      required: false,
      default: false,
    },

    forceShow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["reading-updated"],

  data() {
    return {
      read: false,

      observeVisibilityOptions: {
        callback: this.visibilityChanged,
        once: true,
        throttle: AUTO_READ_AFTER_SECONDS,
      },
    };
  },

  computed: {
    elementsByParent() {
      const elements_by_parent = {};

      const elements = this.report.report_components || [];

      elements.forEach((element) => {
        const parent_id = element.parent_component_id || "root";
        if (elements_by_parent[parent_id] === undefined) {
          elements_by_parent[parent_id] = [];
        }

        elements_by_parent[parent_id].push(element);
      });

      for (const key of Object.keys(elements_by_parent)) {
        elements_by_parent[key] = sortBy(elements_by_parent[key], "position");
      }

      return elements_by_parent;
    },

    rootElements() {
      return this.elementsByParent["root"] || [];
    },
  },

  watch: {
    "report.accent_color": {
      handler(value) {
        if (value) {
          document.documentElement.style.setProperty(
            "--report-accent-color",
            value
          );
        } else {
          document.documentElement.style.removeProperty(
            "--report-accent-color"
          );
        }
      },
    },
  },

  async created() {
    this.visualbyReportFinancialUuid(
      this.report.report_type,
      this.report.corporate_group_data
    );
  },

  mounted() {
    if (this.mode !== "template") {
      EventBus.on("LOADED_BI", () => {
        if (!this.report.allow_drill_down) {
          this.visualbyReportSetFeature("drill-down", false);
        }
      });
    }

    document.documentElement.style.setProperty(
      "--report-accent-color",
      this.report.accent_color
    );
  },

  methods: {
    ...mapActions({
      createReading: "readings/createReading",
    }),

    async visibilityChanged(isVisible) {
      if (this.autoRead && !this.read) {
        if (isVisible) {
          this.read = true;

          const reading = await this.createReading(this.report);

          if (reading) {
            this.$emit("reading-updated", reading);
          }
        }
      }
    },
  },
};
</script>
