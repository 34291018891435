<template>
  <div class="table-responsive">
    <be-skeleton-table
      v-if="loading"
      :rows="pageItems"
      :columns="3"
      :table-props="{ striped: true }"
    />

    <table v-else :id="localId" class="table table-striped table-hover">
      <thead>
        <tr>
          <th
            v-for="field in fields"
            :key="field.key"
            :aria-sort="ariaSort(field)"
            :class="headerClass(field)"
            @click="clickSortedHeader(field)"
          >
            <div class="d-inline-block">
              <slot :name="`head(${field.key})`" :field="field">
                {{ field.label }}
              </slot>
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item.id" :class="item.rowClass">
          <td v-for="field in fields" :key="field.key" :class="field.class">
            <slot v-if="hasSlot(field.key)" :name="field.key" :item="item" />

            <template v-else>
              {{ item[field.key] }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>

    <slot v-if="!items.length && !loading" name="empty">
      <be-alert variant="info">
        {{ $t("components.shared.paginated_table.empty") }}
      </be-alert>
    </slot>

    <be-pagination
      v-if="pagination && !loading"
      class="mt-3"
      :model-value="currentPage"
      :total-rows="totalCount"
      :per-page="pageItems"
      :aria-controls="localId"
      @change="$emit('page-changed', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    pagination: {
      type: Object,
      required: false,
      default: null,
    },

    id: {
      type: String,
      required: false,
      default: null,
    },

    sorting: {
      type: Object,
      required: false,
      default: null,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["page-changed", "sorting-changed"],

  data() {
    return {
      localId: this.id || `table-${this.generateUuid()}`,
      sortBy: this.sorting?.sortBy,
      sortDirection: this.sorting?.sortDirection,
    };
  },

  computed: {
    currentPage() {
      return this.pagination?.currentPage || this.pagination?.page || 1;
    },

    totalCount() {
      return this.pagination?.totalCount || this.pagination?.count || 0;
    },

    pageItems() {
      return this.pagination?.pageItems || this.pagination?.limit || 10;
    },
  },

  watch: {
    sorting({ sortBy, sortDirection }) {
      this.sortBy = sortBy;
      this.sortDirection = sortDirection;
    },
  },

  methods: {
    hasSlot(key) {
      return this.$slots[key];
    },

    headerClass(field) {
      return [
        field.class,
        {
          "table-sort-icon-left": field.sortable,
        },
      ];
    },

    ariaSort(field) {
      if (!field.sortable) {
        return null;
      }

      if (this.sortBy === field.key) {
        if (this.sortDirection === "asc") {
          return "ascending";
        } else if (this.sortDirection === "desc") {
          return "descending";
        }
      }

      return "none";
    },

    clickSortedHeader(field) {
      if (!field.sortable) {
        return;
      }

      const before = this.ariaSort(field);
      this.sortBy = field.key;

      if (before === "none" || before == "descending") {
        this.sortDirection = "asc";
      } else {
        this.sortDirection = "desc";
      }

      this.$emit("sorting-changed", {
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
      });
    },
  },
};
</script>
