<template>
  <be-modal
    v-if="showIntroduction"
    id="new-theme-introduction-modal"
    ref="new-theme-introduction"
    no-header
    no-footer
    no-close-on-backdrop
    no-close-on-esc
    visible
    content-class="text-center"
    body-class="p-0"
  >
    <lottie-animation
      ref="anim"
      :animation-data="imageSrc('lottie/confetti.json')"
      loop
      :speed="0.66"
    />

    <div class="p-4 text-left">
      <h1 class="mb-3">{{ $t("components.introduction.title") }}</h1>

      <div
        v-dompurify-html="
          $t('components.introduction.description_w_platform_name_html', {
            platform_name: $platform.theme.translated_platform_name,
          })
        "
      />

      <div
        v-if="withContactInfo"
        v-dompurify-html="
          $t('components.introduction.description_contact_information_html')
        "
      />

      <div class="text-center mt-5">
        <be-button
          variant="primary"
          size="lg"
          :disabled="disabled"
          @click="handleClose"
        >
          {{ $t("components.introduction.action") }}
        </be-button>
      </div>
    </div>
  </be-modal>
</template>

<script>
import { LottieAnimation } from "lottie-web-vue";
import { mapActions } from "vuex";

const DELAY_BUTTON_TIME = 3 * 1000;

export default {
  components: {
    LottieAnimation,
  },

  data() {
    return {
      disabled: true,
    };
  },

  mounted() {
    setTimeout(() => {
      this.disabled = false;
    }, DELAY_BUTTON_TIME);
  },

  computed: {
    showIntroduction() {
      if (this.$currentUser.shown_beta_introduction_at) {
        return false;
      }

      if (this.$platform.theme.theme_name === "boardeaser_new_brand") {
        return true;
      }

      if (
        this.$platform.theme.theme_name === "bank1" &&
        this.flipperFlag("bank1_new_brand")
      ) {
        return true;
      }

      return false;
    },

    withContactInfo() {
      return this.$platform.theme.theme_name === "boardeaser_new_brand";
    },
  },

  methods: {
    ...mapActions({
      setUser: "current_user/setUser",
    }),

    async handleClose() {
      this.$refs["new-theme-introduction"].hide();

      const { data } = await axios.patch("/users/profile", {
        user: {
          shown_intro: true,
        },

        skip_flash: true,
      });

      await this.setUser(data);
    },
  },
};
</script>
