export default {
  methods: {
    getQueryParam(key) {
      return this.$store.getters["request_query/getParam"](key);
    },

    setQueryParam(key, value) {
      this.$store.dispatch("request_query/setParam", { key, value });
    },

    clearQueryParam(key) {
      this.$store.dispatch("request_query/clearParam", key);
    },
  },
};
