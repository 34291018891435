<template>
  <div v-if="loading" class="card-body">
    <be-spinner>
      {{ $t("buttons.states.loading") }}
    </be-spinner>
  </div>

  <be-scroll-area v-else :height="scrollableHeight" :options="scrollbarOptions">
    <be-list-group class="my-tasks" flush>
      <single-task v-for="task in sortedTasks" :key="task.id" :task="task" />
    </be-list-group>

    <p
      v-if="!haveTasks"
      class="text-muted text-center font-italic m-4"
      v-text="$t('components.tasks.no_tasks')"
    />
  </be-scroll-area>
</template>

<script>
import { scrollableSidebar } from "@/mixins/scrollableSidebar";
import { mapActions, mapMutations } from "vuex";
import { EventBus } from "@/event-bus";

import SingleTask from "./components/SingleTask.vue";

export default {
  components: {
    SingleTask,
  },

  mixins: [scrollableSidebar],

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    tasks() {
      return this.$store.getters["tasks/getMyTasks"];
    },

    haveTasks() {
      return this.tasks.length > 0;
    },

    sortedTasks() {
      return [...this.tasks].sort((a, z) => (a.due_at > z.due_at ? 1 : -1));
    },
  },

  async created() {
    if (this.tasks.length > 0) {
      this.loading = false;
    }

    EventBus.on("tasks_coordinated", () => {
      this.syncMyTasks();
    });
  },

  async mounted() {
    setTimeout(async () => {
      // only fetch if current page have not loaded initial tasks
      if (!this.haveTasks) {
        await this.fetchMyTasks();
      } else {
        await this.syncMyTasks();
      }

      this.loading = false;
    }, 300);
  },

  methods: {
    ...mapActions("tasks", ["fetchMyTasks", "syncMyTasks"]),
    ...mapMutations("tasks", ["updateTask"]),
  },
};
</script>
