<template>
  <div
    :class="['progress-bar', progressBarClasses]"
    :style="progressBarStyles"
    role="progressbar"
    aria-valuemin="0"
    :aria-valuemax="computedMax"
    :aria-valuenow="computedValue"
  >
    <slot>
      <div class="d-flex align-items-center justify-content-center">
        <span v-if="showProgress" class="mx-1">{{ computedProgress }}%</span>

        <span v-if="showValue" class="mx-1">{{ computedValue }}</span>

        <span v-if="label" class="mx-1" v-text="label" />
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "BeProgressBar",

  props: {
    animated: {
      type: Boolean,
      required: false,
      default: false,
    },

    label: {
      type: String,
      required: false,
      default: "",
    },

    max: {
      type: Number,
      required: false,
      default: 100,
    },

    precision: {
      type: Number,
      required: false,
      default: 0,
    },

    showProgress: {
      type: Boolean,
      required: false,
      default: false,
    },

    showValue: {
      type: Boolean,
      required: false,
      default: false,
    },

    striped: {
      type: Boolean,
      required: false,
      default: false,
    },

    value: {
      type: Number,
      required: true,
    },

    variant: {
      type: String,
      required: false,
      default: "primary",
    },
  },

  computed: {
    progressBarClasses() {
      const { variant, animated, striped } = this;
      return [
        variant ? `bg-${variant}` : "",
        striped || animated ? "progress-bar-striped" : "",
        animated ? "progress-bar-animated" : "",
      ];
    },

    progressBarStyles() {
      const { computedValue, computedMax } = this;
      return {
        width: 100 * (computedValue / computedMax) + "%",
      };
    },

    computedValue() {
      const precision = Math.pow(10, this.computedPrecision);
      return Math.round(Number(this.value) * precision) / precision;
    },

    computedMax() {
      const max = Number(this.max);
      return max > 0 ? max : 100;
    },

    computedPrecision() {
      const precision = Number(this.precision);
      return precision >= 0 ? precision : 0;
    },

    computedProgress() {
      const precision = Math.pow(10, this.computedPrecision);
      return (
        Math.round((this.computedValue / this.computedMax) * 100 * precision) /
        precision
      );
    },
  },
};
</script>
