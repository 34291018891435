<template>
  <be-button variant="outline-secondary" :size="size" @click="toggleFullscreen">
    <i
      class="far mr-1"
      :class="{
        'fa-expand-alt': !fullscreen,
        'fa-compress-alt': fullscreen,
      }"
    />
    {{ $t(`buttons.titles.${fullscreen ? "minimize" : "fullscreen"}`) }}
  </be-button>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: {
    initialState: {
      type: Boolean,
      required: false,
      default: false,
    },

    size: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      fullscreen: this.initialState,
    };
  },

  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;

      EventBus.emit("TOGGLE_FULLSCREEN");
    },
  },
};
</script>
