<template>
  <div>
    <div v-show="showQuill" :class="['quill-editor', { error }]">
      <!-- Toolbar -->
      <div :id="`${id}-toolbar`" ref="toolbar">
        <!-- Text formatting -->
        <button
          v-be-tooltip="$t('components.form.text_editor.tooltips.bold')"
          class="ql-bold"
        />

        <button
          v-be-tooltip="$t('components.form.text_editor.tooltips.italic')"
          class="ql-italic"
        />

        <button
          v-be-tooltip="$t('components.form.text_editor.tooltips.underline')"
          class="ql-underline"
        />

        <button
          v-be-tooltip="
            $t('components.form.text_editor.tooltips.strikethrough')
          "
          class="ql-strike"
        />

        <!-- Lists -->
        <button
          v-be-tooltip="
            $t('components.form.text_editor.tooltips.numbered_list')
          "
          class="ql-list"
          value="ordered"
        />

        <button
          v-be-tooltip="
            $t('components.form.text_editor.tooltips.bulleted_list')
          "
          class="ql-list"
          value="bullet"
        />

        <!-- Indentation -->
        <button
          v-be-tooltip="
            $t('components.form.text_editor.tooltips.decrease_indent')
          "
          class="ql-indent"
          value="-1"
        />

        <button
          v-be-tooltip="
            $t('components.form.text_editor.tooltips.increase_indent')
          "
          class="ql-indent"
          value="+1"
        />

        <!-- Custom buttons -->
        <!-- Link -->
        <button
          v-be-tooltip="$t('components.form.text_editor.tooltips.link')"
          class="ql-link"
        />

        <!-- Phrases -->
        <button
          v-if="phrasesEnabled"
          :id="phraseButtonId"
          v-be-tooltip="$t('components.form.text_editor.tooltips.phrases')"
          class="ql-phrases"
          @click="togglePhrasesPopover"
        />

        <!-- Remove format -->
        <button
          v-be-tooltip="
            $t('components.form.text_editor.tooltips.remove_formatting')
          "
          class="ql-remove-format"
          @click="removeFormat"
        />
      </div>

      <!-- Editor -->
      <div :id="`${id}-editor`" ref="editor" />
    </div>

    <phrases-popover
      v-if="phrasesEnabled"
      :id="phrasesPopoverId"
      :target="phraseButtonId"
      @insert-phrase="insertPhrase"
    />

    <input type="hidden" :name="name" :value="body" />
  </div>
</template>

<script>
import Quill from "quill";
import { generateId } from "@/utils/id";
import PhrasesPopover from "@/components/form/PhrasesPopover.vue";

// Import mixins for the custom modules
import BeMention from "@/mixins/forms/be-mention";
import BePhrases from "@/mixins/forms/be-phrases";

// Import and register the custom modules
import Link from "@/vendor/quill/be-link";
import RemoveFormat from "@/vendor/quill/be-remove-format";
Quill.register("modules/link", Link, true);
Quill.register(RemoveFormat, true);

// Override the default icons with FontAwesome icons
const icons = Quill.import("ui/icons");
icons.bold = '<i class="far fa-fw fa-bold"></i>';
icons.italic = '<i class="far fa-fw fa-italic"></i>';
icons.underline = '<i class="far fa-fw fa-underline"></i>';
icons.strike = '<i class="far fa-fw fa-strikethrough"></i>';
icons.list["ordered"] = '<i class="far fa-fw fa-list-ol"></i>';
icons.list["bullet"] = '<i class="far fa-fw fa-list-ul"></i>';
icons.indent["-1"] = '<i class="far fa-fw fa-outdent"></i>';
icons.indent["+1"] = '<i class="far fa-fw fa-indent"></i>';
icons.link = '<i class="far fa-fw fa-link"></i>';
icons["remove-format"] = '<i class="far fa-fw fa-text-slash"></i>';

export default {
  components: {
    PhrasesPopover,
  },

  mixins: [BeMention, BePhrases],

  props: {
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },

    content: {
      type: String,
      required: false,
      default: "",
    },

    error: {
      type: String,
      required: false,
      default: null,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("quill"),
    },

    mode: {
      type: String,
      required: false,
      default: "text",
    },

    name: {
      type: String,
      required: false,
      default: "text-editor",
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableHashtags: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["update:content", "text-change", "selection-change", "editor-change"],

  data() {
    return {
      body: this.content || "",
      showQuill: false,
    };
  },

  computed: {
    quillOptions() {
      return {
        modules: {
          toolbar: `#${this.id}-toolbar`,
        },

        theme: "snow",
        readOnly: this.readonly,
      };
    },
  },

  watch: {
    content(value) {
      const editor = this.getEditorElement();

      if (document.activeElement !== editor) {
        this.body = value;
      }
    },

    readonly() {
      this.initQuill();
    },
  },

  mounted() {
    this.initQuill();
  },

  methods: {
    initQuill() {
      // Destroy the previous instance of Quill if it exists
      if (this.quill) {
        this.quill = null;
      }

      const options = { ...this.quillOptions };

      if (this.enableHashtags) {
        options.modules = {
          ...options.modules,
          ...this.beMentionQuillOptions,
        };
      }

      // Create a new instance of Quill
      this.quill = new Quill(`#${this.id}-editor`, options);

      // Set the editor content
      this.quill.pasteHTML(this.content);

      // Set up event listeners
      this.quill.on("text-change", this.handleTextChange);
      this.quill.on("selection-change", this.handleSelectionChange);
      this.quill.on("editor-change", this.handleEditorChange);

      if (this.enableHashtags) {
        this.refreshHashtagValues();
      }

      this.$nextTick(() => {
        this.showQuill = true;

        if (this.autofocus) {
          this.quill.setSelection(this.content.length, 0);
        }
      });
    },

    handleTextChange(...quillArgs) {
      const content = this.getEditorContent();

      if (this.content !== content) {
        this.body = content;
        this.$emit("update:content", content);
      }

      this.$emit("text-change", ...quillArgs);
    },

    handleSelectionChange(...quillArgs) {
      this.$emit("selection-change", ...quillArgs);
    },

    handleEditorChange(...quillArgs) {
      this.$emit("editor-change", ...quillArgs);
    },

    getEditorContent() {
      return this.quill?.root.innerHTML || "";
    },

    getEditorElement() {
      return this.$refs.editor?.querySelector(".ql-editor");
    },

    getToolbarElement() {
      return this.$refs.toolbar.$el;
    },

    removeFormat() {
      const range = this.quill.getSelection(true);
      this.quill.removeFormat(range.index, range.length);
    },
  },
};
</script>
