<template>
  <div class="be-form-custom-select-dropdown-search">
    <i class="be-form-custom-select-dropdown-search-icon fal fa-search fa-fw" />

    <input
      :value="modelValue"
      class="be-form-custom-select-dropdown-search-input"
      type="text"
      :placeholder="$t('components.shared.be_form_select.search_placeholder')"
      @input="onInput"
      @keydown="onKeydown"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },

  emits: ["update:modelValue", "keydown"],

  methods: {
    onInput(event) {
      this.$emit("update:modelValue", event.target.value);
    },

    onKeydown(event) {
      this.$emit("keydown", event);
    },
  },
};
</script>
