<template>
  <div
    v-bind="computedAttrs"
    :key="loading"
    :class="{ 'be-skeleton-wrapper': loading }"
  >
    <slot v-if="loading" name="loading" />

    <slot v-else />
  </div>
</template>

<script>
export default {
  name: "BeSkeletonWrapper",

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    computedAttrs() {
      const { loading } = this;

      return loading
        ? {
            "aria-busy": "true",
            "aria-live": "polite",
            role: "alert",
          }
        : {};
    },
  },
};
</script>
