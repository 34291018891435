<template>
  <div v-if="card && warning" class="card">
    <div class="card-body">
      <be-alert variant="warning">
        {{ warning }}
      </be-alert>
    </div>
  </div>

  <be-alert v-else-if="warning" variant="warning" class="mb-3">
    {{ warning }}
  </be-alert>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

const POLLING_INTERVAL = 2000;

export default {
  props: {
    userActivitySession: {
      type: Object,
      required: false,
      default: null,
    },

    card: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      poller: null,
      allSessions: [],
    };
  },

  computed: {
    ...mapGetters("current_user", ["getUserActivitySessions"]),

    otherUserIds() {
      const ids = new Set();
      this.allSessions
        .filter((session) => session.user_id !== this.$currentUser.id)
        .forEach((session) => ids.add(session.user_id));

      return ids;
    },

    warning() {
      if (this.otherUserIds.size > 0) {
        return this.$t(
          "components.shared.user_activity_session.warning_multiple_users_edit_w_users",
          { users: this.otherUserNames.join(", ") }
        );
      } else if (this.allSessions.length > 1) {
        return this.$t(
          "components.shared.user_activity_session.warning_multiple_tabs_edit"
        );
      }

      return null;
    },

    otherUserNames() {
      return this.otherUserIds.map((id) => this.getUserName(id));
    },
  },

  watch: {
    "userActivitySession.id": {
      handler(value, oldValue) {
        if (oldValue) {
          this.destroyUserActivitySession({ id: oldValue });
        }
        this.updateUserActivitySession();
      },

      immediate: true,
    },
  },

  mounted() {
    this.startPolling();
    window.addEventListener("beforeunload", () =>
      this.destroyUserActivitySession()
    );
  },

  beforeUnmount() {
    window.removeEventListener("beforeunload", () =>
      this.destroyUserActivitySession()
    );
    this.stopPolling();
  },

  methods: {
    ...mapMutations("current_user", [
      "setUserActivitySession",
      "removeUserActivitySession",
    ]),

    startPolling() {
      this.poller = setInterval(
        this.updateUserActivitySession,
        POLLING_INTERVAL
      );
    },

    stopPolling() {
      clearInterval(this.poller);
    },

    async updateUserActivitySession() {
      if (!this.userActivitySession) {
        return;
      }

      try {
        const { data } = await axios.patch(
          `/users/activity_sessions/${this.userActivitySession.id}`
        );

        this.allSessions = data;
        const currentSession = data.find(
          (session) => session.id === this.userActivitySession.id
        );
        this.setUserActivitySession(currentSession);
      } catch (error) {
        if (error.response?.status === 404) {
          this.removeUserActivitySession({ id: this.userActivitySession.id });
          this.stopPolling();
        }
      }
    },

    async destroyUserActivitySession(session) {
      session = session || this.userActivitySession;

      if (!session) {
        return;
      }

      try {
        await axios.delete(`/users/activity_sessions/${session.id}`);
        this.removeUserActivitySession({ id: session.id });
      } catch (error) {
        if (error.response?.status === 404) {
          this.removeUserActivitySession({ id: this.userActivitySession.id });
          this.stopPolling();
        }
      }
    },
  },
};
</script>
