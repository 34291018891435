<template>
  <be-form-group
    :label="computedLabel"
    :label-for="id"
    :error="error"
    :required="required"
  >
    <be-input-group>
      <be-form-input
        :id="id"
        v-model="password"
        :name="name"
        class="border-right-0"
        :type="visible ? 'text' : 'password'"
        autocomplete="new-password"
        :minlength="minPasswordLength"
        :state="state"
        :required="required"
        @change="$emit('change', password)"
      />

      <be-input-group-append>
        <be-button
          variant="outline-secondary"
          class="py-0 border-left-0"
          :class="{
            'border-danger': state == false,
          }"
          @click="visible = !visible"
        >
          {{ $t(`buttons.titles.${visible ? "hide" : "show"}`) }}
        </be-button>
      </be-input-group-append>
    </be-input-group>

    <!-- Password requirements -->
    <template #description>
      {{ $t("components.user.password_requirements.label") }}:

      <ul class="fa-ul mt-1 mb-0 ml-3">
        <li
          v-for="(requirement, index) in requirements"
          :key="index"
          :class="{
            'text-danger': error && invalidValidations.includes(requirement),
          }"
        >
          <span class="fa-li">
            <i
              :class="{
                'far fa-circle': !this[requirement],
                'fas fa-circle-check text-success': this[requirement],
              }"
            />
          </span>

          {{
            $t(
              `components.user.password_requirements.${camelCaseToSnakeCase(
                requirement
              )}`,
              { length: minPasswordLength }
            )
          }}
        </li>
      </ul>
    </template>
  </be-form-group>
</template>

<script>
import Config from "@/config.js";
import { generateId } from "@/utils/id";

export default {
  props: {
    error: {
      type: [Boolean, String],
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("password"),
    },

    modelValue: {
      type: String,
      required: false,
      default: "",
    },

    name: {
      type: String,
      require: false,
      default: "password",
    },

    label: {
      type: String,
      required: false,
      default: undefined,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    state: {
      type: Boolean,
      required: false,
      default: null,
    },
  },

  emits: ["change", "input", "update:modelValue"],

  data() {
    return {
      localPassword: "",
      visible: false,
      minPasswordLength: Config.PASSWORD_MIN_LENGTH,
      requirements: ["minLength", "hasUppercase", "hasLowercase", "hasNumber"],
    };
  },

  computed: {
    computedLabel() {
      return this.label || this.$t("activerecord.attributes.user.password");
    },

    password: {
      set(value) {
        this.localPassword = value;
        this.$emit("input", this.localPassword);
        this.$emit("update:modelValue", this.localPassword);
      },

      get() {
        return this.localPassword;
      },
    },

    minLength() {
      return this.localPassword.length >= this.minPasswordLength;
    },

    hasUppercase() {
      return /[A-Z]/.test(this.localPassword);
    },

    hasLowercase() {
      return /[a-z]/.test(this.localPassword);
    },

    hasNumber() {
      return /[0-9]/.test(this.localPassword);
    },

    isValid() {
      return (
        this.minLength &&
        this.hasUppercase &&
        this.hasLowercase &&
        this.hasNumber
      );
    },

    invalidValidations() {
      return this.requirements.filter((requirement) => !this[requirement]);
    },
  },

  watch: {
    modelValue(password) {
      this.localPassword = password;
    },
  },

  mounted() {
    this.localPassword = this.modelValue;
  },

  methods: {
    camelCaseToSnakeCase(string) {
      return string
        .replace(/[\w]([A-Z])/g, function (m) {
          return m[0] + "_" + m[1];
        })
        .toLowerCase();
    },
  },
};
</script>
