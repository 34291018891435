<template>
  <div>
    <be-input-group>
      <be-form-input :model-value="value" />

      <be-input-group-append>
        <be-button variant="outline-secondary" @click="copyContents">
          {{ localCopyButtonText }}
        </be-button>
      </be-input-group-append>
    </be-input-group>

    <small v-if="helpText" class="form-text text-muted">
      <em>
        {{ helpText }}
      </em>
    </small>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },

    helpText: {
      type: String,
      required: false,
      default: null,
    },

    copyButtonText: {
      type: String,
      required: false,

      default: null,
    },

    successMessage: {
      type: String,
      required: false,
      default: null,
    },

    failureMessage: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    localCopyButtonText() {
      if (this.copyButtonText === null) {
        return this.$t("buttons.titles.copy");
      }
      return this.copyButtonText;
    },

    localSuccessMessage() {
      if (this.successMessage === null) {
        return this.$t("components.form.be_form_input_copy.success_message");
      }
      return this.successMessage;
    },

    localFailureMessage() {
      if (this.failureMessage === null) {
        return this.$t("components.form.be_form_input_copy.failure_message");
      }
      return this.failureMessage;
    },
  },

  methods: {
    async copyContents() {
      try {
        await navigator.clipboard.writeText(this.value);

        EventBus.emit("new-notification", {
          message: this.localSuccessMessage,
          status: "notice",
        });
      } catch (error) {
        EventBus.emit("new-notification", {
          message: this.localFailureMessage,
          status: "danger",
        });
      }
    },
  },
};
</script>
