<template>
  <div class="page-break-before" />
</template>

<script>
import { EventBus } from "@/event-bus";
export default {
  props: {
    element: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    EventBus.emit("report-component-initialized", this.element);
  },
};
</script>
