/**
 * Phrases wrapper for the TextEditor component
 */

import Quill from "quill";
import { generateId } from "@/utils/id";
import { EventBus } from "@/event-bus";

// Import the Phrase module and register it
import Phrase from "@/vendor/quill/be-phrase";
Quill.register(Phrase, true);

// Set the icon for the Phrase button
const icons = Quill.import("ui/icons");
icons["phrases"] = '<i class="fas fa-section"></i>';

export default {
  data() {
    return {
      phraseButtonId: generateId("phrases-button"),
      phrasesPopoverId: generateId("phrases-popover"),
      quillSelection: null,
    };
  },

  computed: {
    phrasesEnabled() {
      // The variables `readonly` and `mode` are defined in the TextEditor component
      return !this.readonly && this.mode === "meeting";
    },
  },

  methods: {
    togglePhrasesPopover() {
      if (!this.phrasesEnabled) {
        return;
      }

      // Store the current selection, which includes the cursor position
      this.quillSelection = this.quill.getSelection(true);

      // Emit an event to toggle the popover
      EventBus.emit("be::toggle::popover", this.phrasesPopoverId);
    },

    insertPhrase(phrase) {
      if (this.mode !== "meeting" || this.readonly) {
        return;
      }

      // Get the current cursor position
      const { index } = this.quillSelection;

      // Insert the phrase at the current cursor position
      this.quill.insertText(index, phrase.content);

      // Refresh the hashtag values
      this.hashtagRefreshKey = this.generateUuid();

      // Move the cursor to the end of the inserted phrase
      this.$nextTick(() => {
        this.quill.setSelection(index + phrase.content.length);
      });

      // Reset the stored selection
      this.quillSelection = null;
    },
  },
};
