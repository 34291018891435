<template>
  <div>
    <be-dropdown
      v-be-hover="onHover"
      variant="light"
      class="w-100"
      toggle-class="text-decoration-none"
      menu-class="dropdown-organization-select"
      no-caret
      no-flip
      @shown="focusSearch"
    >
      <template #button-content>
        <i class="fas fa-grid mr-md-2 text-reset" />

        <span class="d-none d-md-inline">
          {{ $t("application.my_organizations") }}</span
        >

        <be-badge
          v-if="invitations.length > 0"
          variant="notification"
          class="ml-1"
        >
          {{ invitations.length }}
        </be-badge>
      </template>

      <be-dropdown-item v-if="!hasLoadedOrganizations">
        <be-spinner>
          {{ $t("buttons.states.loading") }}
        </be-spinner>
      </be-dropdown-item>

      <template v-else>
        <be-dropdown-item
          v-if="invitations.length > 0"
          href="/home/invitations"
        >
          {{ $t("views.layouts.header.invitations") }}
          <div class="float-right align-middle">
            <be-badge variant="notification">
              {{ invitations.length }}
            </be-badge>
          </div>
        </be-dropdown-item>

        <be-dropdown-text
          v-if="searchEnabled"
          class="bg-white"
          text-class="px-0"
        >
          <be-input-group>
            <be-input-group-prepend>
              <be-input-group-text class="border-0 bg-white">
                <i class="fal fa-search" />
              </be-input-group-text>
            </be-input-group-prepend>

            <be-form-input
              id="context-filter"
              ref="search"
              v-model="searchQuery"
              type="search"
              class="border-0 rounded-0"
              :placeholder="
                $t(
                  'components.shared.layouts.context_switcher.search.placeholder'
                )
              "
              autocomplete="off"
              @input="searchList"
            />
          </be-input-group>
        </be-dropdown-text>

        <be-dropdown-text
          v-if="!companies.length"
          class="bg-white text-wrap"
          text-class="text-muted text-center pt-3 pb-0 text-nowrap"
        >
          {{
            $t("components.shared.layouts.context_switcher.no_organizations")
          }}
        </be-dropdown-text>

        <be-dropdown-text
          v-if="
            companies.length &&
            !displayedAdminPanels.length &&
            !displayedCompanies.length
          "
          class="bg-white text-wrap"
          text-class="text-muted text-center pt-3 pb-0 text-nowrap"
        >
          {{
            $t("components.shared.layouts.context_switcher.search.no_results")
          }}
        </be-dropdown-text>

        <be-dropdown-group
          v-if="displayedAdminPanels.length > 0"
          header-class="d-flex justify-content-between align-items-center"
        >
          <template #header>
            <span>{{ $t("views.layouts.header.admin_panels") }}</span>

            <div
              v-if="invisibleAdminPanels > 0"
              v-be-tooltip="{
                title: showInvisibleAdminPanels
                  ? $t(
                      'components.shared.layouts.context_switcher.hide_invisible_admin_panels'
                    )
                  : $t(
                      'components.shared.layouts.context_switcher.show_invisible_admin_panels'
                    ),
              }"
              class="text-muted cursor-pointer"
              @click="toggleInvisibleAdminPanels"
            >
              <i class="fal fa-eye-slash" />
              {{ invisibleAdminPanels }}
            </div>
          </template>

          <template v-for="adminPanel in displayedAdminPanels">
            <be-dropdown-item
              v-if="showAdminPanel(adminPanel)"
              :key="`admin-panel-${adminPanel.id}`"
              :href="`/admin_panel/${adminPanel.id}`"
            >
              <div class="row flex-nowrap">
                <div class="col">{{ adminPanel.title }}</div>

                <div class="col-auto text-muted align-middle">
                  <i
                    v-if="adminPanel.is_favorite"
                    v-be-tooltip="
                      $t(
                        'components.shared.layouts.context_switcher.favorites',
                        1
                      )
                    "
                    class="fal fa-heart fa-fw mr-1"
                  />

                  <i
                    v-be-tooltip="$t('views.layouts.header.require_mfa')"
                    class="fal fa-key fa-fw mr-1"
                  />

                  <i class="fal fa-angle-right" />
                </div>
              </div>
            </be-dropdown-item>
          </template>
        </be-dropdown-group>

        <be-dropdown-group
          v-if="displayedCompanies.length > 0"
          header-class="d-flex justify-content-between align-items-center"
        >
          <template #header>
            <span>{{ this.$platform.organizations_title }}</span>

            <div
              v-if="invisibleCompanies > 0"
              v-be-tooltip="{
                title: showInvisibleCompanies
                  ? $t(
                      'components.shared.layouts.context_switcher.hide_invisible_companies'
                    )
                  : $t(
                      'components.shared.layouts.context_switcher.show_invisible_companies'
                    ),
              }"
              class="text-muted cursor-pointer"
              @click="toggleInvisibleCompanies"
            >
              <i class="fal fa-eye-slash" />
              {{ invisibleCompanies }}
            </div>
          </template>

          <template v-for="company in displayedCompanies">
            <be-dropdown-item
              v-if="showCompany(company)"
              :key="company.nanoid"
              :href="url('/', { nanoid: company.nanoid })"
            >
              <div class="row flex-nowrap">
                <div class="col">
                  {{ company.title }}
                  <small
                    v-if="company.admin_panel_title"
                    class="d-block text-muted"
                  >
                    {{ company.admin_panel_title }}
                  </small>
                </div>

                <div
                  class="col-auto text-muted d-flex align-items-center align-middle"
                >
                  <i
                    v-if="company.no_subscription"
                    v-be-tooltip="$t('views.layouts.header.no_subscription')"
                    class="fal fa-pause-circle fa-fw mr-1"
                  />

                  <i
                    v-if="company.require_mfa"
                    v-be-tooltip="$t('views.layouts.header.require_mfa')"
                    class="fal fa-key fa-fw mr-1"
                  />

                  <i
                    v-if="company.is_favorite"
                    v-be-tooltip="
                      $t(
                        'components.shared.layouts.context_switcher.favorites',
                        1
                      )
                    "
                    class="fal fa-heart fa-fw mr-1"
                  />

                  <i
                    v-if="!company.is_visible"
                    v-be-tooltip="
                      $t('components.shared.layouts.context_switcher.invisible')
                    "
                    class="fal fa-eye-slash fa-fw mr-1"
                  />

                  <i class="fal fa-angle-right" />
                </div>
              </div>
            </be-dropdown-item>
          </template>
        </be-dropdown-group>

        <be-dropdown-text class="py-3">
          <div class="d-flex">
            <be-button
              v-if="
                platformEnabled('create_new_company') &&
                adminPanels.length === 0 &&
                companies.length === 0
              "
              class="text-nowrap"
              href="/companies/new"
              variant="light"
              icon="fa-plus"
              block
            >
              {{ $t("application.add_new_company") }}
            </be-button>

            <be-button
              v-if="adminPanels.length > 0 || companies.length > 0"
              class="text-nowrap"
              href="/users/organizations/edit"
              variant="light"
              icon="fa-cog"
              block
            >
              {{
                $t(
                  "components.shared.layouts.context_switcher.manage_organizations"
                )
              }}
            </be-button>
          </div>
        </be-dropdown-text>
      </template>
    </be-dropdown>
  </div>
</template>
<script>
import Fuse from "fuse.js";
import { EventBus } from "@/event-bus";
import { mapGetters, mapActions } from "vuex";
import { compareText } from "@/utils/text-utils";

const ENABLE_SEARCH_AT = 20;

export default {
  props: {
    adminPanelId: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      enableSearchAt: ENABLE_SEARCH_AT,

      filteredAdminPanels: [],
      filteredCompanies: [],
      showInvisibleAdminPanels: false,
      showInvisibleCompanies: false,

      searchQuery: "",

      locallyLoaded: false,

      searchOptions: {
        id: "id",
        keys: ["title"],
        threshold: 0.3,
        distance: 100,
        findAllMatches: true,
        includeMatches: true,
      },
    };
  },

  computed: {
    ...mapGetters("context_switcher", {
      adminPanels: "getAdminPanels",
      companies: "getCompanies",
      invitations: "getPendingGroupOwnerships",
      isLoadingOrganizations: "isLoadingOrganizations",
      hasLoadedOrganizations: "hasLoadedOrganizations",
    }),

    organizationCount() {
      return this.adminPanels.length + this.companies.length;
    },

    searchItems() {
      let adminPanels = this.adminPanels.map((adminPanel) => ({
        id: adminPanel.id,
        title: adminPanel.title,
        type: "adminPanel",
      }));

      return [...adminPanels, ...this.companies];
    },

    searchEnabled() {
      return this.organizationCount > this.enableSearchAt;
    },

    invisibleCompanies() {
      return this.companies.filter((company) => !company.is_visible).length;
    },

    invisibleAdminPanels() {
      return this.adminPanels.filter((adminPanel) => !adminPanel.is_visible)
        .length;
    },

    hideInactiveCompanies() {
      return this.$currentUser.hide_inactive_companies_in_switcher;
    },

    displayedAdminPanels() {
      return this.sortFavoriteAndTitle(this.filteredAdminPanels);
    },

    displayedCompanies() {
      return this.sortFavoriteAndTitle(this.filteredCompanies);
    },
  },

  beforeMount() {
    this.fetchPendingGroupOwnerships();
    this.filteredAdminPanels = this.adminPanels;
    this.filteredCompanies = this.companies;
  },

  mounted() {
    EventBus.on("context_switcher:reload", () => {
      this.load();
    });
  },

  methods: {
    ...mapActions("context_switcher", [
      "loadSwitcher",
      "fetchPendingGroupOwnerships",
    ]),

    /**
     * Search the list for products with the given search term
     * @param  {[type]} list Which list to search from. unselectedItems or selectedItems
     */
    async searchList() {
      if (!this.searchQuery.length) {
        this.filteredAdminPanels = this.adminPanels;
        this.filteredCompanies = this.companies;

        return;
      }

      const fuse = new Fuse(this.searchItems, this.searchOptions);
      const results = fuse.search(this.searchQuery);

      this.filteredAdminPanels = [];
      this.filteredCompanies = [];

      results.map((result) => {
        if (result.item.type == "adminPanel") {
          this.filteredAdminPanels.push(result.item);
        } else {
          this.filteredCompanies.push(result.item);
        }
      });
    },

    /**
     *  Focus the search input if enabled.
     */
    focusSearch() {
      if (this.searchEnabled) {
        // Wait for the input to be rendered
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
      }
    },

    async onHover() {
      if (this.locallyLoaded) {
        return;
      }

      this.filteredAdminPanels = this.adminPanels;
      this.filteredCompanies = this.companies;

      this.load();
    },

    async load() {
      if (this.isLoadingOrganizations) {
        return;
      }

      await this.loadSwitcher({
        company_id: this.$currentCompany?.id,
        admin_panel_id: this.adminPanelId,
      });

      if (!this.searchQuery.length) {
        this.filteredAdminPanels = this.adminPanels;
        this.filteredCompanies = this.companies;
      }

      this.locallyLoaded = true;
    },

    toggleInvisibleAdminPanels() {
      this.showInvisibleAdminPanels = !this.showInvisibleAdminPanels;
    },

    toggleInvisibleCompanies() {
      this.showInvisibleCompanies = !this.showInvisibleCompanies;
    },

    showCompany(company) {
      if (
        this.searchQuery.length > 0 ||
        company.is_favorite ||
        this.showInvisibleCompanies
      ) {
        return true;
      }

      if (this.hideInactiveCompanies && company.no_subscription) {
        return false;
      }

      return company.is_visible;
    },

    showAdminPanel(adminPanel) {
      return (
        this.searchQuery.length > 0 ||
        adminPanel.is_favorite ||
        this.showInvisibleAdminPanels ||
        adminPanel.is_visible
      );
    },

    sortFavoriteAndTitle(collection) {
      return collection.sort((a, b) => {
        if (a.is_favorite && !b.is_favorite) {
          return -1;
        }

        if (!a.is_favorite && b.is_favorite) {
          return 1;
        }

        return compareText(a, b, "title", this.$i18n.locale);
      });
    },
  },
};
</script>
