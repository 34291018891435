<template>
  <div :class="computedClass">
    <div v-be-tooltip="tooltip" :class="computedIndicatorClass">
      <be-spinner v-if="loading" />

      <i v-else-if="icon" :class="computedIconClass" />

      <span v-else :class="computedDotClass" />
    </div>

    <div v-if="!emptySlot" class="be-status-label">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeStatus",

  props: {
    icon: {
      type: String,
      required: false,
      default: undefined,

      validator: (value) => {
        // Must start with "fa-"
        return value.startsWith("fa-");
      },
    },

    iconCustomClass: {
      type: String,
      required: false,
      default: undefined,
    },

    iconStyle: {
      type: String,
      required: false,
      default: "fal",

      validator: (value) => {
        return ["fal", "fas", "far", "fab", "fa-duotone"].includes(value);
      },
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    size: {
      type: String,
      required: false,
      default: undefined,

      validator: (value) => {
        return ["sm", "lg", "xl"].includes(value);
      },
    },

    tooltip: {
      type: String,
      required: false,
      default: undefined,
    },

    variant: {
      type: String,
      required: false,
      default: "secondary",

      // Remove `primary` when the old theme is removed
      validator: (value) => {
        return [
          "primary",
          "secondary",
          "success",
          "danger",
          "warning",
          "info",
        ].includes(value);
      },
    },
  },

  computed: {
    computedClass() {
      return [
        "be-status",
        {
          [`be-status-${this.size}`]: this.size,
        },
      ];
    },

    computedDotClass() {
      const { variant, loading } = this;

      return [
        "be-status-dot",
        `bg-${variant}`,
        {
          invisible: loading,
        },
      ];
    },

    computedIconClass() {
      const { icon, iconCustomClass, computedIconStyle, loading, variant } =
        this;

      return [
        "be-status-icon",
        "fa-fw",
        computedIconStyle,
        icon,
        {
          invisible: loading,
        },
        `text-${variant}`,
        iconCustomClass,
      ];
    },

    computedIconStyle() {
      const { icon, iconStyle } = this;

      // Default these icons to "fas"
      const fasIcons = ["fa-times", "fa-check"];

      if (fasIcons.includes(icon)) {
        return "fas";
      } else {
        return iconStyle;
      }
    },

    computedIndicatorClass() {
      const { emptySlot } = this;

      return ["be-status-indicator", { "mr-2": !emptySlot }];
    },

    emptySlot() {
      return this.$slots.default === undefined;
    },
  },
};
</script>
