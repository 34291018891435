<template>
  <component
    :is="tag"
    :class="['badge', `badge-${variant}`, { 'badge-pill': pill }]"
  >
    <slot />

    <span v-if="srText" class="sr-only">
      {{ srText }}
    </span>
  </component>
</template>

<script>
export default {
  name: "BeBadge",

  props: {
    pill: {
      type: Boolean,
      required: false,
      default: false,
    },

    srText: {
      type: String,
      required: false,
      default: null,
    },

    tag: {
      type: String,
      required: false,
      default: "span",
    },

    variant: {
      type: String,
      required: false,
      default: "secondary",

      validator: (value) => {
        return [
          "primary",
          "secondary",
          "success",
          "danger",
          "warning",
          "info",
          "light",
          "notification",
        ].includes(value);
      },
    },
  },
};
</script>
