<template>
  <div v-if="meetingsCount > 0" :class="`${collapsed ? null : 'mb-3'}`">
    <template v-if="collapsed">
      <be-list-group tag="nav">
        <be-list-group-item
          id="upcoming-meetings-link"
          class="w-100 px-4 justify-content-center position-relative"
          action
        >
          <i class="fal fa-fw fa-calendar-star mr-0" />

          <div
            :class="[
              'd-flex align-items-center',
              { 'position-absolute': collapsed },
            ]"
          >
            <be-badge pill variant="notification">
              {{ meetingsCount }}
            </be-badge>
          </div>
        </be-list-group-item>
      </be-list-group>

      <be-popover
        target="upcoming-meetings-link"
        trigger="click"
        placement="right"
      >
        <upcoming-meetings :header-css="`${collapsed ? 'pl-0' : ''}`" />
      </be-popover>
    </template>

    <upcoming-meetings
      v-else-if="!collapsed"
      :list-group-css="`${!collapsed ? 'mx-4' : ''}`"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UpcomingMeetings from "@/components/meetings/UpcomingMeetings.vue";

export default {
  components: {
    UpcomingMeetings,
  },

  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters("upcoming_meetings", ["meetingsCount", "hasLoadedMeetings"]),
  },

  mounted() {
    if (!this.hasLoadedMeetings) {
      this.fetchMeetings();
    }
  },

  methods: {
    ...mapActions("upcoming_meetings", ["fetchMeetings"]),
  },
};
</script>
