import Quill from "quill";

const Inline = Quill.import("blots/inline");

class RemoveFormat extends Inline {
  static blotName = "remove-format";
  static tagName = "span";
}

export default RemoveFormat;
// Dummy format just to have something to add to the editor.
