<template>
  <div
    class="card card-activity-log d-block h-100 shadow-sm border-left-1 rounded-0"
    :class="{
      'activity-log-collapsed': !open,
    }"
  >
    <div v-show="open" class="sticky-top overflow-hidden">
      <div class="card-header py-1 py-md-3 px-2">
        <div class="row no-gutters align-items-center">
          <div class="col pl-3">
            <h5 class="card-title" v-text="headerTitle" />
          </div>

          <div class="col-auto">
            <be-button
              v-be-tooltip="$t('application.minimize_menu')"
              variant="link"
              class="text-muted text-decoration-none"
              inline
              @click="toggleActivityLog(false)"
            >
              <i class="d-none d-lg-block fal fa-arrow-to-right fa-lg" />

              <i class="d-lg-none fal fa-times" />
            </be-button>
          </div>
        </div>
      </div>

      <div class="card-body p-0">
        <my-tasks v-show="tab === 'my-tasks'" />

        <recent-activities v-show="tab === 'recent-activities'" />
      </div>
    </div>

    <div v-if="!open" class="d-none d-lg-block text-center pt-3">
      <be-button
        v-be-tooltip="$t('application.expand_menu')"
        variant="link"
        class="text-muted text-decoration-none"
        inline
        @click="toggleActivityLog(true)"
      >
        <i class="fal fa-arrow-to-left fa-lg" />
      </be-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MyTasks from "./MyTasks.vue";
import RecentActivities from "./RecentActivities.vue";

// TODO: portal functionality?
export default {
  components: {
    MyTasks,
    RecentActivities,
  },

  computed: {
    ...mapGetters("session", {
      haveSetDefaultUI: "haveSetDefaultUI",
      tab: "currentActivityLogTab",
      open: "activityLogOpen",
    }),

    headerTitle() {
      const scope = this.tab.replace(/-/g, "_");
      return this.$t(`components.activity_log.tabs.${scope}.title`);
    },
  },

  mounted() {
    if (this.$currentUser.open_activity_log) {
      this.openActivityLog();
    }

    if (!this.haveSetDefaultUI) {
      this.setDefaultUI();
    }
  },

  methods: {
    ...mapActions("session", [
      "setDefaultUI",
      "openActivityLog",
      "closeActivityLog",
      "setActivityLogTab",
    ]),

    ...mapActions("current_user", ["setPreferences"]),

    async toggleActivityLog(opened) {
      try {
        await this.setPreferences({ open_activity_log: opened });

        if (this.$currentUser.open_activity_log) {
          this.openActivityLog();
        } else {
          this.closeActivityLog();
        }
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
