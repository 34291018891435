<template>
  <component
    :is="computedTag"
    v-bind="computedLinkAttrs"
    :class="computedClasses"
    @click="onClick"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "BeListGroupItem",

  props: {
    action: {
      type: Boolean,
      required: false,
      default: false,
    },

    active: {
      type: Boolean,
      required: false,
      default: false,
    },

    button: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    href: {
      type: String,
      required: false,
      default: null,
    },

    rel: {
      type: String,
      required: false,
      default: null,
    },

    tag: {
      type: String,
      required: false,
      default: "div",
    },

    target: {
      type: String,
      required: false,
      default: "_self",
    },

    variant: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["click"],

  computed: {
    computedClasses() {
      const { active, action, disabled, variant } = this;

      const isActionable = action || this.href || this.button;

      return [
        "list-group-item",
        {
          active,
          disabled,
          "cursor-pointer": isActionable,
          "list-group-item-action": isActionable,
          [`list-group-item-${variant}`]: variant,
        },
      ];
    },

    computedLinkAttrs() {
      const { href, rel, target } = this;

      if (href) {
        return {
          href,
          rel,
          target,
        };
      } else {
        return null;
      }
    },

    computedTag() {
      const { href, button, tag } = this;

      if (href) {
        return "a";
      } else if (button) {
        return "button";
      } else {
        return tag;
      }
    },
  },

  methods: {
    onClick(event) {
      if (this.disabled) {
        event.preventDefault();
        event.stopPropagation();
      }

      this.$emit("click", event);
    },
  },
};
</script>
