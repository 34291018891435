<template>
  <div :class="computedClasses" :style="computedStyles" />
</template>

<script>
// Regex to check if a value is a valid CSS sizing value
const regex =
  /px|%|vw|vh|em|rem|ex|ch|vmin|vmax|cm|mm|in|pt|pc|auto|initial|inherit/;

const normalizeSizingValue = (value) => {
  if (typeof value === "number") {
    return `${value}px`;
  } else if (typeof value === "string" && !regex.test(value)) {
    return `${value}px`;
  }

  return value;
};

export default {
  name: "BeSkeleton",

  props: {
    animation: {
      type: String,
      required: false,
      default: "wave",
    },

    height: {
      type: [Number, String],
      required: false,
      default: null,
    },

    size: {
      type: [Number, String],
      required: false,
      default: null,
    },

    type: {
      type: String,
      required: false,
      default: "text",
    },

    variant: {
      type: String,
      required: false,
      default: "text",
    },

    width: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },

  computed: {
    computedClasses() {
      const { animation, type, variant } = this;

      return [
        "be-skeleton",
        [`be-skeleton-${type}`],
        {
          [`be-skeleton-animate-${animation}`]: animation,
          [`bg-${variant}`]: variant,
        },
      ];
    },

    computedStyles() {
      const { height, size, width } = this;

      return {
        width: size || normalizeSizingValue(width),
        height: size || normalizeSizingValue(height),
      };
    },
  },
};
</script>
