<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <h5 class="card-title">
        {{ $t("components.shared.comments.title_and_activities") }}

        <div v-if="allowActivityFiltering" class="small text-muted">
          {{ $t(`components.shared.comments.filters.subtitles.${mode}`) }}
        </div>
      </h5>

      <be-dropdown v-if="allowActivityFiltering" ellipsis>
        <be-dropdown-item @click="toggleMode">
          <template v-if="mode === 'filtered'">
            {{ $t("components.shared.comments.filters.all") }}
          </template>

          <template v-else>
            {{ $t("components.shared.comments.filters.filtered") }}
          </template>
        </be-dropdown-item>
      </be-dropdown>
    </div>

    <div class="card-body p-0">
      <comments-list
        :commentable-object="commentableObject"
        :comments="comments"
        :activities="activities"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    commentableObject: {
      type: Object,
      required: true,
    },

    comments: {
      type: Array,
      required: false,
      default: () => [],
    },

    activities: {
      type: Array,
      required: false,
      default: () => [],
    },

    allowActivityFiltering: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      mode: "activities/getMode",
    }),
  },

  methods: {
    toggleMode() {
      this.$store.commit("activities/toggleMode");
    },
  },
};
</script>
