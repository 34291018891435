<template>
  <be-form-radio-group-alternative
    v-if="alternative"
    :id="id"
    :name="groupName"
    :options="normalizedOptions"
    :model-value="modelValue"
    :responsive="responsive"
    :label-class="labelClass"
    :text-class="textClass"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    @update:model-value="$emit('update:modelValue', $event)"
  />

  <div
    v-else
    :id="id"
    :class="groupClasses"
    :aria-invalid="computedAriaInvalid || null"
    :aria-required="required ? 'true' : null"
    role="radiogroup"
    tabindex="-1"
  >
    <slot name="first" />

    <be-form-radio
      v-for="(option, index) in normalizedOptions"
      :key="index"
      :checked-value="option.value"
      :autofocus="autofocus && index === 0"
      :disabled="disabled || option.disabled"
      :description="option.description"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-if="option.html" v-html="option.html" />

      <span v-else v-text="option.text" />
    </be-form-radio>

    <slot />
  </div>
</template>

<script>
import formStateMixin from "@/mixins/forms/form-state";
import formOptionsMixin from "@/mixins/forms/form-options";
import { generateId } from "@/utils/id";

import BeFormRadioGroupAlternative from "./BeFormRadioGroupAlternative.vue";

export default {
  name: "BeFormRadioGroup",

  components: {
    BeFormRadioGroupAlternative,
  },

  mixins: [formStateMixin, formOptionsMixin],

  props: {
    alternative: {
      type: Boolean,
      required: false,
      default: false,
    },

    ariaInvalid: {
      type: [Boolean, String],
      required: false,
      default: false,
    },

    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },

    buttons: {
      type: Boolean,
      required: false,
      default: false,
    },

    buttonVariant: {
      type: String,
      required: false,
      default: null,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    form: {
      type: String,
      required: false,
      default: null,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("be-form-radio-group"),
    },

    labelClass: {
      type: String,
      required: false,
      default: undefined,
    },

    modelValue: {
      type: undefined,
      required: false,
      default: null,
    },

    name: {
      type: String,
      required: false,
      default: null,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    responsive: {
      type: Boolean,
      required: false,
      default: true,
    },

    size: {
      type: String,
      required: false,
      default: null,
    },

    stacked: {
      type: Boolean,
      required: false,
      default: false,
    },

    textClass: {
      type: String,
      required: false,
      default: undefined,
    },

    validated: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["input", "change", "update:modelValue"],

  data() {
    return {
      localValue: this.modelValue,
    };
  },

  computed: {
    inline() {
      return !this.stacked;
    },

    groupName() {
      return this.name || this.id;
    },

    groupClasses() {
      const { buttons, inline, size, validated } = this;

      return {
        "was-validated": validated,
        "btn-group-toggle": buttons,
        "btn-group": buttons && inline,
        "btn-group-vertical": buttons && !inline,
        [`btn-group-${size}`]: buttons && size,
      };
    },
  },

  watch: {
    modelValue(value) {
      if (value !== this.localValue) {
        this.localValue = value;
      }
    },

    localValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("input", newValue);
        this.$emit("update:modelValue", newValue);
      }
    },
  },
};
</script>
