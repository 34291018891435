<template>
  <li role="presentation">
    <component
      :is="headerTag"
      :id="headerId"
      ref="header"
      :class="headerClasses"
      :role="headerTag === 'header' ? 'heading' : null"
    >
      <slot name="header">
        {{ header }}
      </slot>
    </component>

    <ul
      class="list-unstyled"
      role="group"
      :aria-describedby="computedAriaDescribedBy"
    >
      <slot />
    </ul>
  </li>
</template>

<script>
export default {
  name: "BeDropdownGroup",

  props: {
    ariaDescribedBy: {
      type: String,
      required: false,
      default: null,
    },

    header: {
      type: String,
      required: false,
      default: null,
    },

    headerClass: {
      type: [Array, Object, String],
      required: false,
      default: null,
    },

    headerTag: {
      type: String,
      required: false,
      default: "header",
    },

    headerVariant: {
      type: String,
      required: false,
      default: null,
    },

    id: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    headerId() {
      const { id } = this;

      return id ? `${id}-dropdown-header` : null;
    },

    headerClasses() {
      const { headerClass, headerVariant } = this;

      return [
        "dropdown-header",
        headerClass,
        {
          [`text-${headerVariant}`]: headerVariant,
        },
      ];
    },

    computedAriaDescribedBy() {
      return (
        [this.headerId, this.ariaDescribedBy]
          .filter(Boolean)
          .join(" ")
          .trim() || null
      );
    },
  },
};
</script>
