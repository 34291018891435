<template>
  <component
    :is="tag"
    class="dropdown-divider"
    role="separator"
    aria-orientation="horizontal"
  />
</template>

<script>
export default {
  name: "BeDropdownDivider",

  props: {
    tag: {
      type: String,
      required: false,
      default: "div",
    },
  },
};
</script>
