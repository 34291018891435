<template>
  <li v-if="enabled" :class="['nav-item', { active: isActive }]">
    <a
      v-bind="link.linkAttrs"
      :href="href"
      :class="['nav-link', { 'p-1': collapsed }]"
    >
      {{ text }}

      <template v-if="showBadge">
        <navigation-link-badge
          v-if="collapsed"
          :badge="link.badge"
          :collapsed="collapsed"
          class="d-inline-block"
        />

        <div
          v-else
          class="d-flex flex-grow-1 align-items-center justify-content-end"
        >
          <navigation-link-badge :badge="link.badge" :collapsed="collapsed" />
        </div>
      </template>
    </a>
  </li>
</template>

<script>
import { mapGetters } from "vuex";
import menuUtilities from "@/mixins/menuUtilities";

import NavigationLinkBadge from "./NavigationLinkBadge.vue";

export default {
  components: {
    NavigationLinkBadge,
  },

  mixins: [menuUtilities],

  inject: ["action", "controller"],

  props: {
    link: {
      type: Object,
      required: true,
    },

    collapsed: {
      type: Boolean,
      required: true,
    },

    policies: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters("company", ["hasActiveFeature", "hasCorporateGroup"]),

    enabled() {
      if (
        this.link.enabled === undefined ||
        typeof this.link.enabled !== "function"
      ) {
        return true;
      }

      return this.link.enabled(this);
    },

    hasActiveCorporateGroup() {
      return (
        this.platformEnabledAndSubscribed(
          "economy-corporate-group-consolidation"
        ) && this.hasCorporateGroup
      );
    },

    isActive() {
      return this.link.active && this.link.active(this);
    },

    sbsManagerLink() {
      return this.$platform?.sbs_manager_link;
    },

    href() {
      const url = this.convertFunctionToValue(this.link.url);

      if (this.link.absoluteUrl) {
        return url;
      } else {
        return this.url(url);
      }
    },

    showBadge() {
      return this.link.badge;
    },

    text() {
      return this.convertFunctionToValue(this.link.text);
    },
  },
};
</script>
