<template>
  <be-list-group-item :href="taskPath(task)" class="text-decoration-none p-2">
    <div class="media px-2">
      <div class="align-self-center mr-2">
        <i
          v-be-tooltip="{
            title: symbolTooltip,
          }"
          class="far fa-clock fa-fw"
          :class="{
            'text-danger': passedDueDate,
            'text-warning': closeToDueDate,
            invisible: !passedDueDate && !closeToDueDate,
          }"
        />
      </div>

      <div class="media-body">
        {{ task.description }}
        <div class="small text-muted font-italic">
          {{ dueAtWithCompanyText }}
        </div>
      </div>
    </div>
  </be-list-group-item>
</template>

<script>
import { isAfter, differenceInBusinessDays, differenceInDays } from "date-fns";

// config?
const CLOSE_TO_DUE_DATE_DAYS = 2;
const BUSINESS_SCHEDULE = true;

export default {
  props: {
    task: {
      type: Object,
      required: true,
    },
  },

  computed: {
    company() {
      return (
        this.$store.getters["company/getById"](this.task.company_id) ||
        this.$currentCompany
      );
    },

    dueAtWithCompanyText() {
      const text = [];
      if (this.company) {
        text.push(`${this.company.title}`);
      }

      if (this.task.due_at) {
        text.push(
          `${this.translateAttribute("task", "due_at")} ${this.$d(
            new Date(this.task.due_at),
            "date"
          )}`
        );
      }

      return text.join(" · ");
    },

    passedDueDate() {
      return this.task.due_at
        ? isAfter(new Date(), new Date(this.task.due_at))
        : false;
    },

    closeToDueDate() {
      if (this.task.due_at) {
        const diff = BUSINESS_SCHEDULE
          ? differenceInBusinessDays(new Date(this.task.due_at), new Date())
          : differenceInDays(new Date(this.task.due_at), new Date());
        return diff > 0 && diff < CLOSE_TO_DUE_DATE_DAYS;
      } else {
        return false;
      }
    },

    symbolTooltip() {
      if (this.closeToDueDate) {
        return this.$t("components.tasks.close_to_due_date");
      } else if (this.passedDueDate) {
        return this.$t("components.tasks.passed_due_date");
      } else {
        return "";
      }
    },
  },

  methods: {
    taskPath(task) {
      return this.url(`/tasks/${task.id}`, { company: this.company });
    },
  },
};
</script>
