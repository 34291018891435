<template>
  <div
    v-dompurify-html="description"
    class="tiptap-viewer"
    :class="{
      'border rounded p-2 mb-3': mode === 'template',
      'text-muted': mode === 'template' && !element.description,
    }"
  />
</template>

<script>
import { EventBus } from "@/event-bus";
export default {
  props: {
    element: {
      type: Object,
      required: true,
    },

    mode: {
      type: String,
      required: false,
      default: "report",
    },
  },

  computed: {
    description() {
      if (this.mode === "template") {
        return (
          this.element.description ||
          this.$t("components.reports.components.title.placeholder")
        );
      } else {
        return this.element.description;
      }
    },
  },

  mounted() {
    EventBus.emit("report-component-initialized", this.element);
  },
};
</script>
