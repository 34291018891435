<template>
  <component :is="tag" :class="computedClasses" v-bind="computedAttrs">
    <slot />
  </component>
</template>

<script>
import { generateId } from "@/utils/id";

export default {
  name: "BeFormValidFeedback",

  props: {
    ariaLive: {
      type: String,
      required: false,
      default: undefined,
    },

    forceShow: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("be-form-valid-feedback"),
    },

    role: {
      type: String,
      required: false,
      default: undefined,
    },

    state: {
      type: Boolean,
      required: false,
      default: null,

      validator(value) {
        return value === null || typeof value === "boolean";
      },
    },

    tag: {
      type: String,
      required: false,
      default: "div",
    },

    tooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    computedAttrs() {
      const { ariaLive, id, role } = this;

      return {
        id: id || null,
        role: role || null,
        "aria-live": ariaLive || null,
        "aria-atomic": ariaLive ? "true" : null,
        tabindex: "-1",
      };
    },

    computedClasses() {
      const { showFeedback, tooltip } = this;

      return {
        "d-block": showFeedback,
        "valid-feedback": !tooltip,
        "valid-tooltip": tooltip,
      };
    },

    showFeedback() {
      return this.forceShow === true || this.state === true;
    },
  },
};
</script>
