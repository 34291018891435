import Config from "@/config.js";

const MOBILE_COMPONENT_SIZE = "30vh";

export const scrollableSidebar = {
  data() {
    return {
      scrollbarOptions: {
        maxScrollbarLength: 60,
      },

      scrollableHeight: "calc(100vh - 62px)", // desktop default..
    };
  },

  mounted() {
    // no vue alternative
    window.addEventListener("resize", this.setScrollableHeight);
    window.addEventListener("scroll", this.setScrollableHeight);
    window.addEventListener("orientationchange", this.setScrollableHeight);

    // Set initial size
    this.setScrollableHeight();
  },

  unmounted() {
    window.removeEventListener("resize", this.setScrollableHeight);
    window.removeEventListener("scroll", this.setScrollableHeight);
    window.removeEventListener("orientationchange", this.setScrollableHeight);
  },

  methods: {
    isLarge() {
      return window.innerWidth > Config.LAYOUT.BREAKPOINTS.lg;
    },

    getViewSize() {
      return this.isLarge() ? "100vh" : MOBILE_COMPONENT_SIZE;
    },

    setScrollableHeight() {
      this.scrollableHeight = `calc(${this.getViewSize()} - ${
        this.isLarge() ? this.$el.getBoundingClientRect().y : 0
      }px)`;
    },
  },
};
