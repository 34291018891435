import Quill from "quill";

const MentionBlot = Quill.import("blots/mention");

class HashtagBlot extends MentionBlot {
  static render(data) {
    const element = document.createElement("span");
    element.innerText = data.value;
    return element;
  }
}
HashtagBlot.blotName = "hashtag-blot";

export default HashtagBlot;
