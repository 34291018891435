<template>
  <div>
    <div v-for="(group, key) in documentGroups" :key="key">
      <div class="bg-light p-2 my-1">
        <h6
          class="m-0"
          v-text="$t(`companies.meetings.file_list.group_titles.${group.key}`)"
        />
      </div>

      <div v-if="group.documents.length == 0" class="pl-2">-</div>

      <div
        v-for="(doc, innerKey) in group.documents"
        :key="innerKey"
        class="py-1 px-2"
      >
        <document-link
          :company-id="companyId"
          :document-id="doc.id"
          :filename="doc.filename"
          :title="doc.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MaterialMixin from "@/mixins/meetings/material";

export default {
  mixins: [MaterialMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    folder: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      documentGroups: {},

      documentGroupsTemplate: {
        PREVIOUS_MINUTES: {
          key: "previous_minutes",
          documents: [],
        },

        CURRENT_AGENDA: {
          key: "agenda",
          documents: [],
        },

        CURRENT_MINUTES: {
          key: "minutes",
          documents: [],
        },

        OTHER: {
          key: "attachments_and_other",
          documents: [],
        },
      },
    };
  },

  computed: {
    agenda() {
      return this.getAgenda(this.meeting);
    },

    minutes() {
      return this.getMinutes(this.meeting);
    },

    folderDocuments() {
      return this.folder?.subtree_documents || this.folder?.documents || [];
    },

    companyId() {
      return this.meeting.company_id;
    },
  },

  watch: {
    folder: {
      handler: function () {
        this.groupDocuments();
      },

      deep: true,
    },

    agenda() {
      this.groupDocuments();
    },

    minutes() {
      this.groupDocuments();
    },
  },

  mounted() {
    this.fetchAgendaForMeeting(this.meeting);
    this.fetchMinutesForMeeting(this.meeting);

    this.groupDocuments();
  },

  methods: {
    groupDocuments() {
      if (!this.hasLoadedMaterials) {
        // Wait until everything is loaded
        return;
      }

      this.documentGroups = this.cloneDeep(this.documentGroupsTemplate);

      this.folderDocuments.forEach((doc) => {
        let group = "OTHER";

        if (!!doc.owner_id && !!doc.owner_type) {
          if (doc.owner_type === "Material") {
            if (doc.owner_id === this.agenda?.id) {
              group = "CURRENT_AGENDA";
            } else if (doc.owner_id === this.minutes?.id) {
              group = "CURRENT_MINUTES";
            } else {
              group = "PREVIOUS_MINUTES";
            }
          }
        }

        this.documentGroups[group].documents.push(doc);
      });
    },
  },
};
</script>
