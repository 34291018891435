const flattenOptions = (options, labelKey = "label") => {
  const flattenedOptions = [];

  for (const option of options) {
    // If the option has nested options, process them
    if (Array.isArray(option.options)) {
      // Push the group header
      flattenedOptions.push({
        [labelKey]: option.label,
        options: option.options,
      });

      // Then push all the nested options with an additional 'group' property
      for (const groupOption of option.options) {
        flattenedOptions.push({
          ...groupOption,
          group: option.label,
        });
      }
    } else {
      // If the option does not have nested options, just push it
      flattenedOptions.push(option);
    }
  }

  return flattenedOptions;
};

const formatOptions = (options, labelKey = "label", valueKey = "value") => {
  return options.map((option) => {
    if (typeof option === "string" || typeof option === "number") {
      return {
        label: option,
        value: option,
      };
    } else {
      // Check if the option is a group header and recursively format the nested options
      if (option.options) {
        return {
          label: option.label,
          options: formatOptions(option.options, labelKey, valueKey),
        };
      }

      // If labelKey does not exist, try "text" as a fallback key since we commonly use it
      if (!option[labelKey] && option.text) {
        labelKey = "text";
      }

      return {
        ...option,
        label: option[labelKey],
        value: option[valueKey],
      };
    }
  });
};

export { flattenOptions, formatOptions };
