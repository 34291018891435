<template>
  <component :is="tag" :class="classes">
    <slot />
  </component>
</template>

<script>
export default {
  name: "BeNav",

  props: {
    align: {
      type: String,
      required: false,
      default: null,
    },

    // TODO: This might be redundant
    cardHeader: {
      type: Boolean,
      required: false,
      default: false,
    },

    fill: {
      type: Boolean,
      required: false,
      default: false,
    },

    justified: {
      type: Boolean,
      required: false,
      default: false,
    },

    pills: {
      type: Boolean,
      required: false,
      default: false,
    },

    tabs: {
      type: Boolean,
      required: false,
      default: false,
    },

    tag: {
      type: String,
      required: false,
      default: "ul",
    },

    vertical: {
      type: Boolean,
      required: false,
      default: false,
    },

    wizard: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    classes() {
      const { align, cardHeader, fill, justified, pills, tabs, vertical } =
        this;

      return [
        "nav",
        {
          "nav-tabs": tabs,
          "nav-pills": pills && !tabs,
          "nav-fill": !vertical && fill,
          "nav-justified": !vertical && justified,
          "nav-wizard": !vertical && this.wizard,
          "flex-column": vertical,
          "card-header-tabs": !vertical && cardHeader && tabs,
          "card-header-pills": !vertical && cardHeader && pills && !tabs,
          [this.computeJustifyContent(align)]: align,
        },
      ];
    },
  },

  methods: {
    computeJustifyContent(align) {
      if (align === "left") {
        align = "start";
      } else if (align === "right") {
        align = "end";
      }

      return `justify-content-${align}`;
    },
  },
};
</script>
