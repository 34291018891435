// This file is generated by app/lib/prepare_javascript_config.rb
export default [
  { code: "AED", name: "United Arab Emirates Dirham", symbol: "د.إ" },
  { code: "AFN", name: "Afghan Afghani", symbol: "؋" },
  { code: "ALL", name: "Albanian Lek", symbol: "L" },
  { code: "AMD", name: "Armenian Dram", symbol: "դր." },
  { code: "ANG", name: "Netherlands Antillean Gulden", symbol: "ƒ" },
  { code: "AOA", name: "Angolan Kwanza", symbol: "Kz" },
  { code: "ARS", name: "Argentine Peso", symbol: "$" },
  { code: "AUD", name: "Australian Dollar", symbol: "$" },
  { code: "AWG", name: "Aruban Florin", symbol: "ƒ" },
  { code: "AZN", name: "Azerbaijani Manat", symbol: "₼" },
  {
    code: "BAM",
    name: "Bosnia and Herzegovina Convertible Mark",
    symbol: "КМ",
  },
  { code: "BBD", name: "Barbadian Dollar", symbol: "$" },
  { code: "BDT", name: "Bangladeshi Taka", symbol: "৳" },
  { code: "BGN", name: "Bulgarian Lev", symbol: "лв." },
  { code: "BHD", name: "Bahraini Dinar", symbol: "د.ب" },
  { code: "BIF", name: "Burundian Franc", symbol: "Fr" },
  { code: "BMD", name: "Bermudian Dollar", symbol: "$" },
  { code: "BND", name: "Brunei Dollar", symbol: "$" },
  { code: "BOB", name: "Bolivian Boliviano", symbol: "Bs." },
  { code: "BRL", name: "Brazilian Real", symbol: "R$" },
  { code: "BSD", name: "Bahamian Dollar", symbol: "$" },
  { code: "BTN", name: "Bhutanese Ngultrum", symbol: "Nu." },
  { code: "BWP", name: "Botswana Pula", symbol: "P" },
  { code: "BYN", name: "Belarusian Ruble", symbol: "Br" },
  { code: "BYR", name: "Belarusian Ruble", symbol: "Br" },
  { code: "BZD", name: "Belize Dollar", symbol: "$" },
  { code: "CAD", name: "Canadian Dollar", symbol: "$" },
  { code: "CDF", name: "Congolese Franc", symbol: "Fr" },
  { code: "CHF", name: "Swiss Franc", symbol: "CHF" },
  { code: "CLF", name: "Unidad de Fomento", symbol: "UF" },
  { code: "CLP", name: "Chilean Peso", symbol: "$" },
  { code: "CNY", name: "Chinese Renminbi Yuan", symbol: "¥" },
  { code: "COP", name: "Colombian Peso", symbol: "$" },
  { code: "CRC", name: "Costa Rican Colón", symbol: "₡" },
  { code: "CUC", name: "Cuban Convertible Peso", symbol: "$" },
  { code: "CUP", name: "Cuban Peso", symbol: "$" },
  { code: "CVE", name: "Cape Verdean Escudo", symbol: "$" },
  { code: "CZK", name: "Czech Koruna", symbol: "Kč" },
  { code: "DJF", name: "Djiboutian Franc", symbol: "Fdj" },
  { code: "DKK", name: "Danish Krone", symbol: "kr." },
  { code: "DOP", name: "Dominican Peso", symbol: "$" },
  { code: "DZD", name: "Algerian Dinar", symbol: "د.ج" },
  { code: "EGP", name: "Egyptian Pound", symbol: "ج.م" },
  { code: "ERN", name: "Eritrean Nakfa", symbol: "Nfk" },
  { code: "ETB", name: "Ethiopian Birr", symbol: "Br" },
  { code: "EUR", name: "Euro", symbol: "€" },
  { code: "FJD", name: "Fijian Dollar", symbol: "$" },
  { code: "FKP", name: "Falkland Pound", symbol: "£" },
  { code: "GBP", name: "British Pound", symbol: "£" },
  { code: "GEL", name: "Georgian Lari", symbol: "ლ" },
  { code: "GHS", name: "Ghanaian Cedi", symbol: "₵" },
  { code: "GIP", name: "Gibraltar Pound", symbol: "£" },
  { code: "GMD", name: "Gambian Dalasi", symbol: "D" },
  { code: "GNF", name: "Guinean Franc", symbol: "Fr" },
  { code: "GTQ", name: "Guatemalan Quetzal", symbol: "Q" },
  { code: "GYD", name: "Guyanese Dollar", symbol: "$" },
  { code: "HKD", name: "Hong Kong Dollar", symbol: "$" },
  { code: "HNL", name: "Honduran Lempira", symbol: "L" },
  { code: "HRK", name: "Croatian Kuna", symbol: "kn" },
  { code: "HTG", name: "Haitian Gourde", symbol: "G" },
  { code: "HUF", name: "Hungarian Forint", symbol: "Ft" },
  { code: "IDR", name: "Indonesian Rupiah", symbol: "Rp" },
  { code: "ILS", name: "Israeli New Sheqel", symbol: "₪" },
  { code: "INR", name: "Indian Rupee", symbol: "₹" },
  { code: "IQD", name: "Iraqi Dinar", symbol: "ع.د" },
  { code: "IRR", name: "Iranian Rial", symbol: "﷼" },
  { code: "ISK", name: "Icelandic Króna", symbol: "kr." },
  { code: "JMD", name: "Jamaican Dollar", symbol: "$" },
  { code: "JOD", name: "Jordanian Dinar", symbol: "د.ا" },
  { code: "JPY", name: "Japanese Yen", symbol: "¥" },
  { code: "KES", name: "Kenyan Shilling", symbol: "KSh" },
  { code: "KGS", name: "Kyrgyzstani Som", symbol: "som" },
  { code: "KHR", name: "Cambodian Riel", symbol: "៛" },
  { code: "KMF", name: "Comorian Franc", symbol: "Fr" },
  { code: "KPW", name: "North Korean Won", symbol: "₩" },
  { code: "KRW", name: "South Korean Won", symbol: "₩" },
  { code: "KWD", name: "Kuwaiti Dinar", symbol: "د.ك" },
  { code: "KYD", name: "Cayman Islands Dollar", symbol: "$" },
  { code: "KZT", name: "Kazakhstani Tenge", symbol: "₸" },
  { code: "LAK", name: "Lao Kip", symbol: "₭" },
  { code: "LBP", name: "Lebanese Pound", symbol: "ل.ل" },
  { code: "LKR", name: "Sri Lankan Rupee", symbol: "₨" },
  { code: "LRD", name: "Liberian Dollar", symbol: "$" },
  { code: "LSL", name: "Lesotho Loti", symbol: "L" },
  { code: "LYD", name: "Libyan Dinar", symbol: "ل.د" },
  { code: "MAD", name: "Moroccan Dirham", symbol: "د.م." },
  { code: "MDL", name: "Moldovan Leu", symbol: "L" },
  { code: "MGA", name: "Malagasy Ariary", symbol: "Ar" },
  { code: "MKD", name: "Macedonian Denar", symbol: "ден" },
  { code: "MMK", name: "Myanmar Kyat", symbol: "K" },
  { code: "MNT", name: "Mongolian Tögrög", symbol: "₮" },
  { code: "MOP", name: "Macanese Pataca", symbol: "P" },
  { code: "MRU", name: "Mauritanian Ouguiya", symbol: "UM" },
  { code: "MUR", name: "Mauritian Rupee", symbol: "₨" },
  { code: "MVR", name: "Maldivian Rufiyaa", symbol: "MVR" },
  { code: "MWK", name: "Malawian Kwacha", symbol: "MK" },
  { code: "MXN", name: "Mexican Peso", symbol: "$" },
  { code: "MYR", name: "Malaysian Ringgit", symbol: "RM" },
  { code: "MZN", name: "Mozambican Metical", symbol: "MTn" },
  { code: "NAD", name: "Namibian Dollar", symbol: "$" },
  { code: "NGN", name: "Nigerian Naira", symbol: "₦" },
  { code: "NIO", name: "Nicaraguan Córdoba", symbol: "C$" },
  { code: "NOK", name: "Norwegian Krone", symbol: "kr" },
  { code: "NPR", name: "Nepalese Rupee", symbol: "Rs." },
  { code: "NZD", name: "New Zealand Dollar", symbol: "$" },
  { code: "OMR", name: "Omani Rial", symbol: "ر.ع." },
  { code: "PAB", name: "Panamanian Balboa", symbol: "B/." },
  { code: "PEN", name: "Peruvian Sol", symbol: "S/" },
  { code: "PGK", name: "Papua New Guinean Kina", symbol: "K" },
  { code: "PHP", name: "Philippine Peso", symbol: "₱" },
  { code: "PKR", name: "Pakistani Rupee", symbol: "₨" },
  { code: "PLN", name: "Polish Złoty", symbol: "zł" },
  { code: "PYG", name: "Paraguayan Guaraní", symbol: "₲" },
  { code: "QAR", name: "Qatari Riyal", symbol: "ر.ق" },
  { code: "RON", name: "Romanian Leu", symbol: "Lei" },
  { code: "RSD", name: "Serbian Dinar", symbol: "РСД" },
  { code: "RUB", name: "Russian Ruble", symbol: "₽" },
  { code: "RWF", name: "Rwandan Franc", symbol: "FRw" },
  { code: "SAR", name: "Saudi Riyal", symbol: "ر.س" },
  { code: "SBD", name: "Solomon Islands Dollar", symbol: "$" },
  { code: "SCR", name: "Seychellois Rupee", symbol: "₨" },
  { code: "SDG", name: "Sudanese Pound", symbol: "£" },
  { code: "SEK", name: "Swedish Krona", symbol: "kr" },
  { code: "SGD", name: "Singapore Dollar", symbol: "$" },
  { code: "SHP", name: "Saint Helenian Pound", symbol: "£" },
  { code: "SKK", name: "Slovak Koruna", symbol: "Sk" },
  { code: "SLL", name: "Sierra Leonean Leone", symbol: "Le" },
  { code: "SOS", name: "Somali Shilling", symbol: "Sh" },
  { code: "SRD", name: "Surinamese Dollar", symbol: "$" },
  { code: "SSP", name: "South Sudanese Pound", symbol: "£" },
  { code: "STD", name: "São Tomé and Príncipe Dobra", symbol: "Db" },
  { code: "SVC", name: "Salvadoran Colón", symbol: "₡" },
  { code: "SYP", name: "Syrian Pound", symbol: "£S" },
  { code: "SZL", name: "Swazi Lilangeni", symbol: "E" },
  { code: "THB", name: "Thai Baht", symbol: "฿" },
  { code: "TJS", name: "Tajikistani Somoni", symbol: "ЅМ" },
  { code: "TMT", name: "Turkmenistani Manat", symbol: "T" },
  { code: "TND", name: "Tunisian Dinar", symbol: "د.ت" },
  { code: "TOP", name: "Tongan Paʻanga", symbol: "T$" },
  { code: "TRY", name: "Turkish Lira", symbol: "₺" },
  { code: "TTD", name: "Trinidad and Tobago Dollar", symbol: "$" },
  { code: "TWD", name: "New Taiwan Dollar", symbol: "$" },
  { code: "TZS", name: "Tanzanian Shilling", symbol: "Sh" },
  { code: "UAH", name: "Ukrainian Hryvnia", symbol: "₴" },
  { code: "UGX", name: "Ugandan Shilling", symbol: "USh" },
  { code: "USD", name: "United States Dollar", symbol: "$" },
  { code: "UYU", name: "Uruguayan Peso", symbol: "$U" },
  { code: "UZS", name: "Uzbekistan Som", symbol: "so'm" },
  { code: "VES", name: "Venezuelan Bolívar Soberano", symbol: "Bs" },
  { code: "VND", name: "Vietnamese Đồng", symbol: "₫" },
  { code: "VUV", name: "Vanuatu Vatu", symbol: "Vt" },
  { code: "WST", name: "Samoan Tala", symbol: "T" },
  { code: "XAF", name: "Central African Cfa Franc", symbol: "CFA" },
  { code: "XAG", name: "Silver (Troy Ounce)", symbol: "oz t" },
  { code: "XAU", name: "Gold (Troy Ounce)", symbol: "oz t" },
  { code: "XBA", name: "European Composite Unit", symbol: "" },
  { code: "XBB", name: "European Monetary Unit", symbol: "" },
  { code: "XBC", name: "European Unit of Account 9", symbol: "" },
  { code: "XBD", name: "European Unit of Account 17", symbol: "" },
  { code: "XCD", name: "East Caribbean Dollar", symbol: "$" },
  { code: "XDR", name: "Special Drawing Rights", symbol: "SDR" },
  { code: "XOF", name: "West African Cfa Franc", symbol: "Fr" },
  { code: "XPD", name: "Palladium", symbol: "oz t" },
  { code: "XPF", name: "Cfp Franc", symbol: "Fr" },
  { code: "XPT", name: "Platinum", symbol: "oz t" },
  {
    code: "XTS",
    name: "Codes specifically reserved for testing purposes",
    symbol: "",
  },
  { code: "YER", name: "Yemeni Rial", symbol: "﷼" },
  { code: "ZAR", name: "South African Rand", symbol: "R" },
  { code: "ZMK", name: "Zambian Kwacha", symbol: "ZK" },
  { code: "ZMW", name: "Zambian Kwacha", symbol: "K" },
  { code: "BCH", name: "Bitcoin Cash", symbol: "₿" },
  { code: "BTC", name: "Bitcoin", symbol: "₿" },
  { code: "JEP", name: "Jersey Pound", symbol: "£" },
  { code: "GGP", name: "Guernsey Pound", symbol: "£" },
  { code: "IMP", name: "Isle of Man Pound", symbol: "£" },
  { code: "XFU", name: "UIC Franc", symbol: "" },
  { code: "GBX", name: "British Penny", symbol: "" },
  { code: "CNH", name: "Chinese Renminbi Yuan Offshore", symbol: "¥" },
  { code: "EEK", name: "Estonian Kroon", symbol: "KR" },
  { code: "GHS", name: "Ghanaian Cedi", symbol: "₵" },
  { code: "LTL", name: "Lithuanian Litas", symbol: "Lt" },
  { code: "LVL", name: "Latvian Lats", symbol: "Ls" },
  { code: "MRO", name: "Mauritanian Ouguiya", symbol: "UM" },
  { code: "MTL", name: "Maltese Lira", symbol: "₤" },
  { code: "TMM", name: "Turkmenistani Manat", symbol: "m" },
  { code: "JPY", name: "Japanese Yen", symbol: "¥" },
  { code: "ZWD", name: "Zimbabwean Dollar", symbol: "$" },
  { code: "ZWL", name: "Zimbabwean Dollar", symbol: "$" },
  { code: "ZWN", name: "Zimbabwean Dollar", symbol: "$" },
  { code: "ZWR", name: "Zimbabwean Dollar", symbol: "$" },
  { code: "VEF", name: "Venezuelan Bolívar", symbol: "Bs.F" },
];
