<template>
  <div>
    <div class="d-flex">
      <h6
        v-if="!hideHeader"
        v-text="
          $t('components.meetings.tabs.components.meeting_documents.title')
        "
      />

      <div v-if="!hideHeader" class="ml-auto">
        <be-link
          v-if="!hideListButton"
          :class="state === 'list' ? 'text-primary' : 'text-secondary'"
          @click.prevent="state = 'list'"
        >
          <i class="fal fa-list" />
        </be-link>

        <be-link
          v-if="!hideFolderButton"
          class="ml-2"
          :class="state === 'folder' ? 'text-primary' : 'text-secondary'"
          @click.prevent="state = 'folder'"
        >
          <i class="fal fa-folder" />
        </be-link>
      </div>
    </div>

    <template v-if="activeFolder.id && (hasDocuments || hasChildren)">
      <grouped-documents-list
        v-if="state === 'list'"
        v-model:folder="activeFolder"
        :meeting="meeting"
      />

      <folder-list
        v-else
        v-model:folder="activeFolder"
        :initially-opened="true"
        :editable="allowedToEdit"
      />
    </template>

    <template v-else>
      {{
        $t("components.meetings.tabs.components.meeting_documents.no_documents")
      }}
    </template>
  </div>
</template>

<script>
import GroupedDocumentsList from "./GroupedDocumentsList.vue";

export default {
  components: {
    GroupedDocumentsList,
  },

  props: {
    folder: {
      type: Object,
      required: true,
    },

    meeting: {
      type: Object,
      required: true,
    },

    hideListButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideFolderButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },

    initialView: {
      type: String,
      required: false,
      default: "list",
      validator: (value) => ["list", "folder"].includes(value),
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["update:folder"],

  data() {
    return {
      state: "list",
    };
  },

  computed: {
    activeFolder: {
      get() {
        return this.folder;
      },

      set(newValue) {
        this.$emit("update:folder", newValue);
      },
    },

    hasDocuments() {
      return (this.activeFolder?.documents || []).length > 0;
    },

    hasChildren() {
      return (this.activeFolder?.children || []).length > 0;
    },

    allowedToEdit() {
      return this.editable && this.folder.policy?.update;
    },
  },

  mounted() {
    this.state = this.initialView;
  },
};
</script>
