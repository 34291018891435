<template>
  <object
    v-if="showPdf"
    :class="`w-100 ${fullHeight ? 'min-vh-100' : 'min-vh-75'}`"
    type="application/pdf"
    :data="fileUrl"
  >
    <embed type="application/pdf" :src="fileUrl" />
  </object>

  <div v-else-if="showAudio" class="w-100">
    <audio controls class="w-100">
      <source :src="fileUrl" :type="fileType" />
    </audio>
  </div>

  <div
    v-else-if="showImage"
    class="w-100 d-flex align-items-center justify-content-center"
  >
    <be-img :src="fileUrl" class="mw-100" />
  </div>

  <div v-else-if="showVideo" class="w-100">
    <video :src="fileUrl" controls class="w-100"></video>
  </div>

  <div v-else :class="['w-100', { 'min-vh-75': iframeLoaded }]">
    <div
      v-if="loadingIframe"
      class="d-flex justify-content-center align-items-center"
    >
      <be-spinner class="pt-4" size="lg" />
    </div>

    <be-alert v-else-if="!iframeLoaded" id="iframe-error-alert" variant="info">
      {{ $t("components.shared.be_file_viewer.iframe_error") }}
    </be-alert>

    <iframe
      ref="iframe"
      :src="fileUrl"
      :class="[
        'w-100',
        'h-100',
        { invisible: loadingIframe, 'min-vh-75': iframeLoaded },
      ]"
      frameborder="0"
      @load="onIframeLoad"
    />
  </div>
</template>

<script>
const TIME_OUT = 5000;

export default {
  name: "BeFileViewer",

  props: {
    fileUrl: {
      type: String,
      required: true,
    },

    fileType: {
      type: String,
      required: true,
    },

    fullHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      loadingIframe: true,
      iframeLoaded: false,
    };
  },

  computed: {
    showAudio() {
      return this.fileType.startsWith("audio");
    },

    showImage() {
      return this.fileType.startsWith("image");
    },

    showPdf() {
      return this.fileType === "application/pdf" && !this.macOrIOS;
    },

    showVideo() {
      return this.fileType.startsWith("video");
    },

    macOrIOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) || navigator.userAgent.includes("Mac")
      );
    },
  },

  mounted() {
    if (
      !this.showPdf &&
      !this.showAudio &&
      !this.showImage &&
      !this.showVideo
    ) {
      setTimeout(() => {
        if (!this.iframeLoaded) {
          this.loadingIframe = false;
          this.iframeLoaded = false;
          if (this.$refs?.iframe) {
            this.$refs.iframe.style.display = "none";
          }
        }
      }, TIME_OUT);
    }
  },

  methods: {
    onIframeLoad() {
      this.loadingIframe = false;
      this.iframeLoaded = true;
    },
  },
};
</script>
