<template>
  <li role="presentation">
    <form
      :id="id"
      ref="form"
      :class="formClasses"
      tabindex="-1"
      :disabled="disabled || null"
      :novalidate="novalidate"
    >
      <slot />
    </form>
  </li>
</template>

<script>
export default {
  name: "BeDropdownForm",

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    formClass: {
      type: [Array, Object, String],
      required: false,
      default: null,
    },

    id: {
      type: String,
      required: false,
      default: null,
    },

    inline: {
      type: Boolean,
      required: false,
      default: false,
    },

    novalidate: {
      type: Boolean,
      required: false,
      default: false,
    },

    validated: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    formClasses() {
      const { inline, validated } = this;

      return [
        "be-dropdown-form",
        this.formClass,
        {
          "form-inline": inline,
          "was-validated": validated,
        },
      ];
    },
  },
};
</script>
