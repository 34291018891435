<template>
  <div :style="{ height: spacingHeight }" />
</template>

<script>
const HEIGHT_OPTIONS = {
  S: 2,
  M: 4,
  L: 6,
};
import { EventBus } from "@/event-bus";
export default {
  props: {
    element: {
      type: Object,
      required: true,
    },

    mode: {
      type: String,
      required: false,
      default: "report",
    },
  },

  computed: {
    spacingHeight() {
      return `${HEIGHT_OPTIONS[this.element.description]}em`;
    },
  },

  mounted() {
    EventBus.emit("report-component-initialized", this.element);
  },
};
</script>
