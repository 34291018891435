<template>
  <be-form-group :label="label" :label-for="id" class="m-0">
    <be-form-select
      :id="id"
      :options="options"
      :include-blank-option="$t('components.shared.be_table.show_all')"
      @change="onChange"
    />
  </be-form-group>
</template>

<script>
import { generateId } from "@/utils/id";

export default {
  props: {
    label: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },
  },

  emits: ["change"],

  data() {
    return {
      id: generateId("be-table-select-filter"),
    };
  },

  methods: {
    onChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>
