<template>
  <div>
    <be-button
      v-be-modal="modalId"
      v-be-tooltip="{
        title: $t('models.company.errors.user_limit_reached'),
        disabled: showInvitationModal,
      }"
      :variant="buttonVariant"
      :disabled="!showInvitationModal"
    >
      {{ localButtonText }}

      <be-badge v-if="unsentInvitations.length > 0" variant="light">
        {{ unsentInvitations.length }}

        <span class="sr-only">
          {{ $t("components.memberships.invitations.unsent") }}
        </span>
      </be-badge>
    </be-button>

    <be-modal
      :id="modalId"
      :title="title"
      size="xl"
      :no-footer="state === 'form'"
      @show="onShow"
      @close="onClose"
    >
      <be-alert
        v-if="displayMembershipInvitationWarningMessage"
        variant="warning"
      >
        {{
          $t("components.memberships.invitations.membership_invitation_message")
        }}
      </be-alert>

      <div v-if="state == 'preview'">
        <membership-table
          v-if="unsentInvitations.length > 0"
          class="mb-3"
          :memberships="unsentInvitations"
          :fields="[
            'avatar',
            'name',
            'email',
            'function',
            'role',
            'title',
            'policy_level',
            'options',
          ]"
          :skip-confirmations="true"
          @edit="membershipEdit"
          @destroy="membershipDestroy"
        />

        <be-form-group
          label-for="membership-invitation-message"
          :label="$t('activerecord.attributes.membership.invitation_message')"
          :description="$t('models.membership.hints.invitation_message')"
        >
          <be-form-textarea
            v-if="unsentInvitations.length > 0"
            id="membership-invitation-message"
            v-model="invitationMessage"
            rows="3"
            max-rows="6"
          />

          <blockquote v-else>
            {{ invitationMessage }}
          </blockquote>
        </be-form-group>
      </div>

      <template v-if="state == 'form'">
        <membership-form
          v-if="localMembership"
          :membership="localMembership"
          :button-text="$t('buttons.titles.add_more')"
          @submit="addInvitation"
          @cancel="cancelEdit"
        />

        <be-skeleton-table
          v-else
          :rows="7"
          :columns="2"
          :table-props="{ striped: true }"
        />
      </template>

      <template #footer>
        <be-button
          :variant="unsentInvitations.length == 0 ? 'primary' : 'light'"
          :disabled="loading && unsentInvitations.length > 0"
          @click="close"
        >
          <template v-if="unsentInvitations.length == 0">
            {{ $t("buttons.titles.close") }}
          </template>

          <template v-else>
            {{ $t("buttons.titles.cancel") }}
          </template>
        </be-button>

        <be-button
          v-if="canAddUsers"
          variant="outline-secondary"
          @click="newUserForm"
        >
          {{ $t("components.memberships.invitations.add_another_user") }}
        </be-button>

        <be-button
          v-if="state === 'preview' && unsentInvitations.length > 0"
          variant="primary"
          :loading="loading"
          :disabled="loading"
          icon="fa-envelope"
          @click="sendInvitations"
        >
          {{ $t("components.memberships.invitations.send") }}
        </be-button>
      </template>
    </be-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import MembershipForm from "./MembershipForm.vue";
import MembershipTable from "./MembershipTable.vue";

export default {
  components: {
    MembershipForm,
    MembershipTable,
  },

  // Handle warning in MembershipForm
  provide: {
    originOptions: [],
  },

  props: {
    buttonText: {
      type: String,
      required: false,
      default: undefined, // $t("components.memberships.buttons.invite_users")
    },

    buttonVariant: {
      type: String,
      default: "outline-primary",
    },

    displayMembershipInvitationWarningMessage: {
      type: Boolean,
      required: false,
      default: false,
    },

    modalId: {
      type: String,
      required: false,
      default: "new-membership",
    },
  },

  emits: ["updated"],

  data() {
    return {
      state: "preview",

      invitationMessage: this.$t(
        "models.membership.default_invitation_message"
      ),

      localMembership: null,
    };
  },

  computed: {
    ...mapGetters({
      invitations: "memberships/invitations",
      loading: "memberships/loading",
      unsentInvitations: "memberships/unsentInvitations",
      membership: "memberships/newMembership",
      memberships: "memberships/getMemberships",
      loadedMemberships: "memberships/loaded",
      company: "company/getCompany",
    }),

    localButtonText() {
      if (this.buttonText === undefined) {
        return this.$t("components.memberships.buttons.invite_users");
      }

      return this.buttonText;
    },

    canAddUsers() {
      const currentUsers =
        this.memberships.length + this.unsentInvitations.length;

      return !this.userLimitReached(currentUsers);
    },

    showInvitationModal() {
      if (this.unsentInvitations.length > 0) {
        return true;
      }

      return !this.userLimitReached(this.memberships.length);
    },

    subscriptionPlan() {
      return this.company.subscriptions.find((subscription) => {
        return (
          subscription.subscribed_type == "Plan" && subscription.current == true
        );
      });
    },

    title() {
      return this.$t("components.memberships.invitations.title");
    },

    notEditing() {
      return this.invitations.filter(
        (membership) =>
          membership.user.email !== this.localMembership.user.email
      );
    },

    userLimitReachedTooltip() {
      return this.$i18n.t("models.company.errors.user_limit_reached");
    },
  },

  watch: {
    membership(new_value) {
      this.localMembership = this.cloneDeep(new_value);
    },
  },

  mounted() {
    if (!this.loadedMemberships) {
      this.$store.commit(
        "memberships/setMemberships",
        this.$currentCompany.memberships
      );
    }
  },

  methods: {
    preview() {
      if (this.state == "form") {
        this.state = "preview";
      }
    },

    async sendInvitations() {
      await this.$store.dispatch(
        "memberships/sendInvitations",
        this.invitationMessage
      );
      this.$emit("updated");
      this.close();
    },

    async addInvitation(membership) {
      if (!membership.uuid) {
        membership.uuid = this.generateUuid();
      }
      await this.$store.dispatch("memberships/addInvitation", membership);
      this.localMembership = this.cloneDeep(this.membership);
      this.state = "preview";
    },

    membershipEdit(membership) {
      this.localMembership = membership;
      this.state = "form";
    },

    membershipDestroy(membership) {
      this.$store.dispatch("memberships/removeInvitation", membership);
    },

    close() {
      this.$beModal.hide(this.modalId);

      // Clear invitations
      if (this.state == "preview" && this.unsentInvitations == 0) {
        this.onClose();
      }
    },

    async onShow() {
      if (this.unsentInvitations.length > 0) {
        this.state = "preview";
      } else {
        this.state = "form";
      }

      if (this.localMembership == null) {
        await this.$store.dispatch("memberships/loadNewMembership");
        this.localMembership = this.cloneDeep(this.membership);
      }
    },

    onClose() {
      this.$store.dispatch("memberships/clearInvitations");
    },

    newUserForm() {
      this.localMembership = this.cloneDeep(this.membership);
      this.state = "form";
    },

    cancelEdit() {
      if (this.invitations.length == 0) {
        this.close();
      } else {
        this.localMembership = this.cloneDeep(this.membership);
        this.state = "preview";
      }
    },

    userLimitReached(currentUsers) {
      return (
        this.subscriptionPlan &&
        currentUsers == this.subscriptionPlan.user_limit
      );
    },
  },
};
</script>
