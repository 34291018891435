<template>
  <component
    :is="tag"
    :class="['input-group-append', { 'input-group-append-inline': inline }]"
  >
    <be-input-group-text v-if="isText || isCheckbox || isRadio">
      <slot />
    </be-input-group-text>

    <slot v-else />
  </component>
</template>

<script>
export default {
  name: "BeInputGroupAppend",

  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },

    isCheckbox: {
      type: Boolean,
      required: false,
      default: false,
    },

    isRadio: {
      type: Boolean,
      required: false,
      default: false,
    },

    isText: {
      type: Boolean,
      required: false,
      default: false,
    },

    tag: {
      type: String,
      required: false,
      default: "div",
    },
  },
};
</script>
