<template>
  <div class="d-flex align-items-center">
    <span>{{ option[optionLabel] }}</span>

    <span v-if="option.symbol" class="ml-1 text-muted text-center">
      ({{ option.symbol }})
    </span>
  </div>
</template>

<script>
export default {
  inject: ["optionLabel"],

  props: {
    option: {
      type: Object,
      required: true,
    },
  },
};
</script>
