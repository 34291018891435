<template>
  <div v-if="mode === 'template'" class="mb-3 p-4 border rounded">
    <h5>{{ $t("models.report_component.titles.tasks") }}</h5>

    <be-skeleton-table
      :rows="2"
      :columns="1"
      :table-props="{ striped: true }"
    />
  </div>

  <div v-else class="mb-3" data-element="tasks-viewer">
    <div v-if="taskReferences.length > 0" class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("activerecord.models.task.one") }}</th>

            <th class="col-shrink">
              {{ $t("activerecord.attributes.task.done_at") }}
            </th>

            <th class="col-shrink">
              {{ $t("activerecord.attributes.task.due_at") }}
            </th>

            <th class="col-shrink">
              {{ $t("activerecord.attributes.task.assigned_to") }}
            </th>

            <th v-if="allowRemove" class="col-shrink" />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="reference in taskReferences"
            :key="`task-${reference.reference_id}`"
          >
            <td>
              <a
                v-dompurify-html="truncateText(reference.task.description)"
                :href="
                  url(`tasks/${reference.reference_id}`, {
                    company: $currentCompany,
                  })
                "
              />
            </td>

            <td class="col-shrink">
              <span v-if="!!reference.task.done_at">
                {{ $d(new Date(reference.task.done_at)) }}
              </span>
            </td>

            <td class="col-shrink">
              <span v-if="!!reference.task.due_at">
                {{ $d(new Date(reference.task.due_at)) }}
              </span>
            </td>

            <td class="col-shrink text-center">
              <user-avatar
                v-if="reference.task.user_id"
                :user="reference.task.user_id"
                class="d-print-none"
              />

              <p class="text-left m-0 d-none d-print-block">
                {{ getUserName(reference.task.user_id) }}
              </p>
            </td>

            <td v-if="allowRemove" class="col-shrink">
              <be-button
                variant="danger"
                size="sm"
                :loading="loadingIds.includes(reference.reference_id)"
                icon="fa-times"
                @click="$emit('remove-reference', reference)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <comments-viewer
      v-if="!hideComment && element.comments"
      :comments="element.comments"
    />
  </div>
</template>

<script>
import BeButton from "@/components/shared/BeButton.vue"; // Needed since this component is rendered in PDF-view
import { EventBus } from "@/event-bus";
import CommentsViewer from "./CommentsViewer.vue";
import TextUtilities from "@/mixins/textUtilities";

export default {
  components: {
    BeButton,
    CommentsViewer,
  },

  mixins: [TextUtilities],

  props: {
    editing: {
      type: Boolean,
      required: false,
      default: false,
    },

    element: {
      type: Object,
      required: true,
    },

    hideComment: {
      type: Boolean,
      required: false,
      default: false,
    },

    mode: {
      type: String,
      required: false,
      default: "report",
    },

    loadingIds: {
      type: Array,
      required: false,
      default: () => [],
    },

    allowRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["remove-reference"],

  computed: {
    taskReferences() {
      return this.element.report_component_references.filter(
        (reference) => reference.reference_type === "Task"
      );
    },
  },

  mounted() {
    EventBus.emit("report-component-initialized", this.element);
  },
};
</script>
