import Quill from "quill";

const Link = Quill.import("formats/link");
Link.sanitize = function (url) {
  // prefix default protocol.
  let protocol = url.slice(0, url.indexOf(":"));
  if (this.PROTOCOL_WHITELIST.indexOf(protocol) === -1) {
    url = "http://" + url;
  }
  // Link._sanitize function
  let anchor = document.createElement("a");
  anchor.href = url;
  protocol = anchor.href.slice(0, anchor.href.indexOf(":"));
  return this.PROTOCOL_WHITELIST.indexOf(protocol) > -1
    ? url
    : this.SANITIZED_URL;
};

export default Link;
