<template>
  <be-modal
    :id="id"
    ref="modal"
    :title="title"
    :ok-title="localOkTitle"
    :ok-variant="okVariant"
    :ok-disabled="okDisabled || !confirmTextValid"
    :cancel-title="localCancelTitle"
    :cancel-disabled="cancelDisabled"
    :no-header="!title"
    centered
    @ok="handleOk"
    @cancel="handleCancel"
    @close="handleClose"
    @hidden="onHidden"
    @show="onShow"
  >
    <template v-if="stayOpenAfterConfirm && isConfirmed" #ok-button>
      <be-spinner variant="light" />
    </template>

    <slot />

    <div v-if="confirmWithInput" :class="{ 'mb-3': slotPassed('below-input') }">
      <label :for="`${id}-input`" class="font-weight-normal">
        <i18n-t keypath="components.shared.confirm_modal.input_label">
          <template #validInput>
            <code>{{ validInputText }}</code>
          </template>
        </i18n-t>
      </label>

      <be-form-input
        :id="`${id}-input`"
        v-model="confirmText"
        autofocus
        trim
        @keyup.enter="onEnterClick"
      />
    </div>

    <slot name="below-input" />
  </be-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: false,
      default: () => `confirm-modal-${this.generateUuid()}`,
    },

    title: {
      type: String,
      required: false,
      default: "",
    },

    okTitle: {
      type: String,
      required: false,
      default: null,
    },

    okVariant: {
      type: String,
      required: false,
      default: "primary",
    },

    okDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    cancelTitle: {
      type: String,
      required: false,
      default: null,
    },

    cancelDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    confirmWithInput: {
      type: Boolean,
      required: false,
      default: false,
    },

    validInputText: {
      type: String,
      required: false,
      default: new Date().toLocaleDateString(),
    },

    stayOpenAfterConfirm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["ok", "confirm", "cancel", "close", "hidden", "show"],

  data() {
    return {
      confirmText: "",
      isConfirmed: false,
    };
  },

  computed: {
    confirmTextValid() {
      if (!this.confirmWithInput) {
        return true;
      }

      return this.confirmText === this.validInputText;
    },

    localOkTitle() {
      if (this.okTitle == null) {
        return this.$t("buttons.titles.ok");
      }

      return this.okTitle;
    },

    localCancelTitle() {
      if (this.cancelTitle == null) {
        return this.$t("buttons.titles.cancel");
      }

      return this.cancelTitle;
    },
  },

  methods: {
    handleOk(beModalEvt) {
      if (this.stayOpenAfterConfirm) {
        beModalEvt.preventDefault();
      }

      this.$emit("ok");
      this.$emit("confirm");
      this.isConfirmed = true;
    },

    handleCancel() {
      this.$emit("cancel");
    },

    handleClose() {
      this.$emit("close");
    },

    onEnterClick() {
      if (this.confirmTextValid) {
        this.handleOk();
        this.$refs.modal.hide();
      }
    },

    onHidden() {
      this.$emit("hidden");
      this.resetModal();
    },

    onShow() {
      this.$emit("show");
    },

    resetModal() {
      this.confirmText = "";
      this.isConfirmed = false;
    },

    slotPassed(slotName) {
      return !!this.$slots[slotName];
    },
  },
};
</script>
