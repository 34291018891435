<template>
  <div class="card">
    <div class="card-header d-flex justify-content-end">
      <be-button :href="`${urlPrefix}/tables/new`" variant="outline-primary">
        {{ $t("components.financials.tables.new") }}
      </be-button>
    </div>

    <div class="card-body">
      <be-table
        v-if="configurations.length > 0"
        :items="configurations"
        :fields="fields"
      >
        <template #label="{ item }">
          <be-link :href="`${urlPrefix}/tables/${item.uuid}/edit`">
            {{ item.label }}
          </be-link>
        </template>

        <template #update-time="{ item }">
          {{ $d(new Date(item.updateTime || item.createTime), "dateTime") }}
        </template>

        <template #visibility="{ item }">
          <be-badge
            :variant="item.visibility == 'DEFAULT' ? 'success' : 'secondary'"
          >
            {{
              $t(
                `components.financials.shared.visibility.${item.visibility.toLowerCase()}`
              )
            }}
          </be-badge>
        </template>

        <template #options="{ item }">
          <be-dropdown ellipsis>
            <be-dropdown-item
              v-be-modal="`remove-modal-${item.uuid}`"
              variant="danger"
            >
              {{ $t("buttons.titles.remove") }}
            </be-dropdown-item>
          </be-dropdown>

          <financial-component-remove-modal
            :component="item"
            :modal-id="`remove-modal-${item.uuid}`"
            :url-prefix="urlPrefix"
          />
        </template>
      </be-table>

      <be-alert v-else variant="info">
        {{ $t("components.financials.tables.empty") }}
      </be-alert>
    </div>
  </div>
</template>
<script>
import RequestQueryMixin from "@/mixins/RequestQuery";
import FinancialComponentRemoveModal from "@/components/financials/data_management/FinancialComponentRemoveModal.vue";

export default {
  components: {
    FinancialComponentRemoveModal,
  },

  mixins: [RequestQueryMixin],

  props: {
    urlPrefix: {
      type: String,
      required: true,
    },

    configurations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fields: [
        {
          key: "label",
          label: this.$t("views.companies.financials.components.tables.name"),
          sortable: true,
        },

        {
          key: "updateTime",
          label: this.$t("components.financials.shared.last_updated"),
          class: "col-shrink",
          sortable: true,
        },

        {
          key: "visibility",
          label: this.$t("components.financials.shared.visibility.title"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
        },
      ],
    };
  },
};
</script>
