<template>
  <div v-if="activeTab === id" role="tabpanel">
    <slot />
  </div>
</template>

<script>
import { generateId } from "@/utils/id";

export default {
  name: "BeTab",

  inject: [
    "activeTab",
    "changeTab",
    "registerTab",
    "updateTab",
    "unregisterTab",
  ],

  props: {
    count: {
      type: Number,
      required: false,
      default: null,
    },

    countVariant: {
      type: String,
      required: false,
      default: "light",

      validator: (value) => {
        return ["light", "notification"].includes(value);
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("tab"),
    },

    title: {
      type: String,
      required: true,
    },

    warning: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  mounted() {
    this.registerTab({
      ...this.$props,
    });
  },

  updated() {
    this.updateTab({
      ...this.$props,
    });
  },

  beforeUnmount() {
    this.unregisterTab(this.id);
  },
};
</script>
