export default {
  props: {
    options: {
      type: [Array, Object],
      default: () => [],
    },
  },

  computed: {
    normalizedOptions() {
      return this.normalizeOptions(this.options);
    },
  },

  methods: {
    normalizeOption(option, key = null) {
      // When the option is an object, normalize it
      if (typeof option === "object") {
        const { description, disabled, html, icon, text, value, variant } =
          option;

        return {
          value: value === undefined ? key || text : value,
          text: String(value === undefined ? key : text),
          html: html || null,
          disabled: Boolean(disabled),
          description: description || null,
          variant: variant || null,
          icon: icon || null,
        };
      }

      // Otherwise create an `<option>` object from the given value
      return {
        value: key || option,
        text: String(option),
        disabled: false,
      };
    },

    normalizeOptions(options) {
      // Normalize the given options array
      if (Array.isArray(options)) {
        return options.map((option) => this.normalizeOption(option));
      }

      // If the given options is not an array, return an empty array
      return [];
    },
  },
};
