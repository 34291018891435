<template>
  <div>
    <div v-if="!!file.copy_from_id">
      <input
        type="hidden"
        :name="getModelName('[copy_from_id]')"
        :value="file.copy_from_id"
      />

      <input
        type="hidden"
        :name="getModelName('[copy_to_attachment]')"
        :value="getAttachmentName()"
      />
    </div>

    <div v-else>
      <input
        type="hidden"
        :name="getModelName('[relative_path]')"
        :value="relativePath"
        placeholder="relpath"
      />

      <input
        v-if="file.key"
        type="hidden"
        :name="getName('[key]')"
        :value="file.key"
      />

      <template v-else>
        <input type="hidden" :name="getName('[id]')" :value="file.id" />

        <input
          type="hidden"
          :name="getName('[storage]')"
          :value="file.storage"
        />
      </template>

      <input
        type="hidden"
        :name="getName('[metadata][size]')"
        :value="file.metadata.size"
      />

      <input
        type="hidden"
        :name="getName('[metadata][filename]')"
        :value="file.metadata.filename"
      />

      <input
        type="hidden"
        :name="getName('[metadata][mime_type]')"
        :value="file.metadata.mime_type"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    file: {
      type: [File, Object],
      required: true,
    },

    isSingleFile: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    relativePath() {
      return this.file.fullPath || "";
    },
  },

  methods: {
    getName(key) {
      if (this.isSingleFile) {
        return `${this.name}${key}`;
      }

      return `${this.name.replace(/_index_/, this.index)}${key}`;
    },

    getModelName(key) {
      if (this.isSingleFile) {
        return `${this.name.split(/\[/)[0]}${key}`;
      }
      let modelName = this.name.split(/\w*(\[\w*\])$/)[0];

      return `${modelName.replace(/_index_/, this.index)}${key}`;
    },

    getAttachmentName() {
      if (this.isSingleFile) {
        let parts = this.name.match(/\[(\w*)\]/);
        return parts[parts.length - 1];
      }
      let parts = this.name
        .split(/\[(\w*)\]/)
        .filter((part) => part.length > 0);
      return parts[parts.length - 1];
    },
  },
};
</script>
