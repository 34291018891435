<template>
  <export-modal
    id="table-pdf-export"
    :export-types="['financial_table_pdf']"
    :title="$t('components.financials.financial_table_export.title')"
    :export-params="exportParams"
    direct-export
  />
</template>

<script>
import { EventBus } from "@/event-bus";
import { format } from "date-fns";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      exportParams: {
        uuid: null,
        origin: null,
        date: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      inCorporateGroup: "company/inCorporateGroup",
    }),
  },

  mounted() {
    EventBus.on("export-table-pdf", (data) => {
      this.tableToPdf(data);
    });
  },

  methods: {
    tableToPdf(data) {
      this.exportParams = {
        uuid: data.uuid,
        origin: data.origin,
        date: format(data.date, "yyyy-MM-dd"),
        corporate_group: this.inCorporateGroup,
      };

      this.$nextTick(() => {
        this.$beModal.show("table-pdf-export");
      });
    },
  },
};
</script>
