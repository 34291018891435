<template>
  <p class="text-pre-line">
    <em>{{ comments }}</em>
  </p>
</template>
<script>
export default {
  props: {
    comments: {
      type: String,
      required: true,
    },
  },
};
</script>
