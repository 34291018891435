<template>
  <div :class="wrapperCss">
    <slot v-if="urgentCount && urgentCount > 0" name="urgent"></slot>

    <span v-if="unreadCount && unreadCount > 0" :class="css">
      <slot name="unread" :unread-count="unreadCount">
        {{ unreadCount }}
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: [Number, String],
      required: true,
    },

    messageThreadId: {
      type: String,
      required: false,
      default: null,
    },

    css: {
      type: String,
      default: "badge badge-light badge-pill ml-1",
    },

    wrapperCss: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      unreadCount: null,
      urgentCount: null,
    };
  },

  computed: {
    queryUrl() {
      if (this.messageThreadId) {
        return `/companies/${this.companyId}/message_threads/readings/${this.messageThreadId}`;
      } else {
        return `/companies/${this.companyId}/message_threads/readings`;
      }
    },
  },

  async created() {
    try {
      const response = await axios.get(this.queryUrl);
      this.unreadCount = Number(response.data.unread) || null;
      this.urgentCount = Number(response.data.unread_urgent) || null;
    } catch (error) {
      this.handleError(error);
    }
  },
};
</script>
