<template>
  <div>
    <component
      v-bind="formatComponentProps(badge.componentProps)"
      :is="badge.component"
      v-if="isComponent"
    />

    <be-badge v-else :variant="variant" pill>
      {{ content }}
    </be-badge>
  </div>
</template>

<script>
import menuUtilities from "@/mixins/menuUtilities";

export default {
  mixins: [menuUtilities],

  props: {
    badge: {
      type: Object,
      required: true,
    },

    collapsed: {
      type: Boolean,
      required: true,
    },

    wrapperClass: {
      type: String,
      required: false,
      default: "d-flex align-items-center",
    },
  },

  data() {
    return {
      content: this.convertFunctionToValue(this.badge.content),
      variant: this.convertFunctionToValue(this.badge.variant),
    };
  },

  computed: {
    isComponent() {
      return this.badge.component !== undefined;
    },
  },
};
</script>
