<template>
  <li
    :class="[
      'be-form-custom-select-dropdown-option',
      {
        disabled: option.disabled,
        group: isGroupHeader,
        highlighted: isHighlighted,
        selected: isSelected,
        selectable: isSelectable,
        'mt-2': isGroupHeader && index !== 0,
      },
    ]"
    :disabled="option.disabled || null"
    @click="onClick"
    @mouseover="onMouseover"
  >
    <slot name="option" :option="option" :index="index">
      {{ getNestedValue(option, optionLabel) }}
    </slot>
  </li>
</template>

<script>
export default {
  inject: [
    "filteredOptions",
    "highlighted",
    "multiple",
    "groupSelectable",
    "optionLabel",
    "optionValue",
    "selected",
  ],

  props: {
    index: {
      type: Number,
      required: true,
    },

    option: {
      type: Object,
      required: true,
    },
  },

  emits: ["click", "mouseover"],

  computed: {
    isGroupHeader() {
      return this.option.options;
    },

    isHighlighted() {
      return this.highlighted === this.index;
    },

    isSelectable() {
      return this.option.options && this.multiple && this.groupSelectable;
    },

    isSelected() {
      return (
        !this.multiple &&
        this.selected &&
        this.selected[this.optionValue] === this.option[this.optionValue] &&
        !this.isGroupHeader
      );
    },
  },

  methods: {
    getNestedValue(obj, path) {
      // Get nested value from object, e.g. "user.name"
      // If nested value is null, return empty string
      return path.split(".").reduce((o, i) => o?.[i] ?? "", obj);
    },

    onClick(event) {
      // We need to stop the event propagation to prevent the dropdown from closing
      // when an option is clicked, due to the click outside event listener.
      event.stopPropagation();

      this.$emit("click");
    },

    onMouseover() {
      this.$emit("mouseover");
    },
  },
};
</script>
