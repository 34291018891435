<template>
  <div>
    <be-table :items="contacts" :fields="fields">
      <template #avatar="{ item }">
        <user-avatar :avatar="item.avatar" />
      </template>

      <template #name="{ item }">
        <be-link :href="url(`/contacts/${item.id}`)">
          {{ item.name }}
        </be-link>
      </template>

      <template #function="{ item }">
        {{ item.function }}
      </template>

      <template #email="{ item }">
        <be-link v-if="item.email" :href="`mailto:${item.email}`">
          {{ item.email }}
        </be-link>
      </template>

      <template #telephone="{ item }">
        {{ item.telephone }}
      </template>

      <template #options="{ item }">
        <be-dropdown size="sm" ellipsis>
          <be-dropdown-item :href="url(`/contacts/${item.id}/edit`)">
            {{ $t("buttons.titles.edit") }}
          </be-dropdown-item>

          <template v-if="item.policy.destroy">
            <be-dropdown-divider />

            <be-dropdown-item
              variant="danger"
              @click.prevent="removeContact(item)"
            >
              {{ $t("buttons.titles.remove") }}
            </be-dropdown-item>
          </template>
        </be-dropdown>
      </template>
    </be-table>
  </div>
</template>

<script>
export default {
  props: {
    contacts: {
      type: Array,
      required: true,
    },
  },

  emits: ["contact-removed"],

  data() {
    return {
      fields: [
        {
          key: "avatar",
          label: "",
          class: "col-shrink",
          sortable: false,
        },
        {
          key: "name",
          label: this.translateAttribute("contact", "name"),
          sortable: true,
        },
        {
          key: "function",
          label: this.translateAttribute("contact", "function"),
          sortable: true,
          class: "col-shrink",
        },
        {
          key: "email",
          label: this.translateAttribute("contact", "email"),
          sortable: true,
        },
        {
          key: "telephone",
          label: this.translateAttribute("contact", "telephone"),
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
        },
      ],

      loading: false,
    };
  },

  methods: {
    async removeContact(contact) {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: contact.name,
        })
      );

      if (isConfirmed) {
        this.$emit("contact-removed", contact);
      }
    },
  },
};
</script>
