import { isBefore, startOfYear } from "date-fns";

export default {
  methods: {
    dateFormat() {
      return isBefore(new Date(this.activity.due_at), startOfYear(new Date()))
        ? "short"
        : "localShort";
    },

    badgeClass() {
      return isBefore(new Date(this.activity.due_at), new Date())
        ? "danger"
        : "success";
    },
  },
};
