<template>
  <div class="w-100">
    <div class="card mb-2 p-3">
      <be-nav wizard>
        <be-nav-item
          v-for="(step, index) in steps"
          :key="step.value"
          :active="activeStepIndex === index"
          :disabled="step.disabled"
          @click="changeStep(index)"
        >
          {{ step.title }}
        </be-nav-item>
      </be-nav>
    </div>

    <div class="card">
      <div class="card-body">
        <template v-for="(step, index) in steps">
          <template v-if="lazy">
            <div
              v-if="stepIsLoaded(index)"
              v-show="activeStepIndex === index"
              :key="step.value"
            >
              <slot :name="step.value" />
            </div>
          </template>

          <div v-else v-show="activeStepIndex === index" :key="index">
            <slot :name="step.value" />
          </div>
        </template>
      </div>

      <div v-if="!hideFooter" class="card-footer">
        <div
          :class="[
            'd-flex flex-column flex-md-row',
            {
              'justify-content-end': isFirstStep,
              'justify-content-between': !isFirstStep,
            },
          ]"
        >
          <be-button
            v-if="!isFirstStep"
            :variant="previousButtonVariant"
            :size="buttonSize"
            :disabled="previousButtonDisabled"
            @click="previousStep"
          >
            {{ computedPreviousButtonText }}
          </be-button>

          <be-button
            v-if="!isLastStep"
            :variant="nextButtonVariant"
            :size="buttonSize"
            :disabled="nextButtonDisabled"
            @click="nextStep"
          >
            {{ computedNextButtonText }}
          </be-button>

          <be-button
            v-if="isLastStep"
            :variant="submitButtonVariant"
            :size="buttonSize"
            :disabled="submitButtonDisabled"
            @click="submit"
          >
            {{ submitButtonText || $t("buttons.titles.save") }}
          </be-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeWizard",

  props: {
    buttonSize: {
      type: String,
      required: false,
      default: null,
    },

    hideFooter: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideSubmitButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    includeStepNameInButtons: {
      type: Boolean,
      required: false,
      default: false,
    },

    lazy: {
      type: Boolean,
      required: false,
      default: false,
    },

    nextButtonText: {
      type: String,
      required: false,
      default: undefined, // $t("buttons.titles.next_step")
    },

    nextButtonVariant: {
      type: String,
      required: false,
      default: "primary",
    },

    previousButtonText: {
      type: String,
      required: false,
      default: undefined, // $t("buttons.titles.previous_step")
    },

    previousButtonVariant: {
      type: String,
      required: false,
      default: "outline-primary",
    },

    steps: {
      type: Array,
      required: true,

      validator: (steps) => {
        return steps.every((step) => {
          return (
            typeof step === "object" &&
            Object.prototype.hasOwnProperty.call(step, "title") &&
            Object.prototype.hasOwnProperty.call(step, "value")
          );
        });
      },
    },

    submitButtonDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    submitButtonText: {
      type: String,
      required: false,
      default: undefined, // $t("buttons.titles.save")
    },

    submitButtonVariant: {
      type: String,
      required: false,
      default: "primary",
    },
  },

  emits: ["change-step", "next-step", "previous-step", "submit"],

  data() {
    return {
      activeStepIndex: 0,
      loadedSteps: [0],
    };
  },

  computed: {
    activeStep() {
      return this.steps[this.activeStepIndex];
    },

    computedNextButtonText() {
      if (this.includeStepNameInButtons) {
        return this.isLastStep
          ? ""
          : this.steps[this.activeStepIndex + 1].title;
      } else {
        return this.nextButtonText || this.$t("buttons.titles.next_step");
      }
    },

    computedPreviousButtonText() {
      if (this.includeStepNameInButtons) {
        return this.isFirstStep
          ? ""
          : this.steps[this.activeStepIndex - 1].title;
      } else {
        return (
          this.previousButtonText || this.$t("buttons.titles.previous_step")
        );
      }
    },

    isLastStep() {
      return this.activeStepIndex === this.steps.length - 1;
    },

    isFirstStep() {
      return this.activeStepIndex === 0;
    },

    nextButtonDisabled() {
      return this.steps[this.activeStepIndex + 1].disabled;
    },

    previousButtonDisabled() {
      return this.steps[this.activeStepIndex - 1].disabled;
    },
  },

  watch: {
    activeStepIndex(value) {
      if (this.lazy && !this.stepIsLoaded(value)) {
        this.loadedSteps.push(value);
      }

      this.$emit("change-step", this.steps[value]);
    },
  },

  methods: {
    stepIsLoaded(index) {
      return this.loadedSteps.includes(index);
    },

    changeStep(index) {
      if (this.lazy && !this.stepIsLoaded(index)) {
        this.loadedSteps.push(index);
      }

      this.activeStepIndex = index;
    },

    nextStep() {
      if (this.isLastStep) {
        return;
      }

      this.activeStepIndex += 1;
      this.$emit("next-step", this.steps[this.activeStepIndex]);
    },

    previousStep() {
      if (this.isFirstStep) {
        return;
      }

      this.activeStepIndex -= 1;
      this.$emit("previous-step", this.steps[this.activeStepIndex]);
    },

    submit() {
      this.$emit("submit");
    },
  },
};
</script>
