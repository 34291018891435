<template>
  <div>
    <be-table :fields="fields" :items="documentsWithUsers">
      <template
        v-for="user in sortedUsersById"
        #[`head(user-${user.id})`]
        :key="user.id"
      >
        <user-avatar :user="user.id" />
      </template>

      <template #title="{ item }">
        <document-link
          :document-id="item.document.id"
          :filename="item.document.filename"
          :title="item.document.title"
        />
      </template>

      <template #actions="{ item }">
        <be-button
          v-be-tooltip="$t('buttons.titles.share')"
          variant="outline-secondary"
          size="sm"
          icon="fa-share"
          inline
          @click="handleShare(item.document)"
        />
      </template>

      <template
        v-for="user in sortedUsersById"
        #[`user-${user.id}`]="{ item }"
        :key="user.id"
      >
        <be-button
          v-if="
            user.id == $currentUser.id &&
            !documentRead(item.document.readings, user)
          "
          v-be-tooltip="$t('buttons.titles.mark_as_read')"
          variant="outline-success"
          size="sm"
          icon="fa-check"
          inline
          @click="readDocument(item.document)"
        />

        <be-status
          v-else-if="documentRead(item.document.readings, user)"
          size="xl"
          variant="success"
          class="justify-content-center"
          :tooltip="$t('activerecord.attributes.reading.read')"
        />
      </template>
    </be-table>

    <inform-user-modal
      :document="shareDocument"
      :form-url="url('documents/send_reminder')"
      show-automatically
      exclude-current-user
      @reset="() => (shareDocument = {})"
    />
  </div>
</template>

<script>
import InformUserModal from "@/components/shared/InformUserModal.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    InformUserModal,
  },

  props: {
    documents: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      shareDocument: {},
    };
  },

  computed: {
    ...mapGetters({
      companyUsers: "company/users",
      membershipUsers: "memberships/getUsers",
    }),

    organizationUsers() {
      if (this.$currentAdminPanel?.id) {
        return this.membershipUsers;
      } else {
        return this.companyUsers;
      }
    },

    documentsWithUsers() {
      return this.documents.map((currentDocument) => {
        return {
          document: currentDocument,
          users: this.sortedUsersById,
        };
      });
    },

    fields() {
      let formattedFields = [
        {
          key: "title",
          label: this.translateAttribute("document", "title"),
          sortable: true,
          sortByFormatted: true,

          formatter: (value, key, item) => {
            return item.document.title;
          },
        },
        {
          key: "actions",
          label: "",
          class: "col-shrink",
        },
        {
          key: "created_at",
          label: this.translateAttribute("document", "created_at"),
          class: "col-shrink",
          sortable: true,
          sortByFormatted: true,

          formatter: (value, key, item) => {
            return this.$d(new Date(item.document.created_at), {
              format: "mini",
            });
          },
        },
      ];

      this.sortedUsersById.forEach((user) => {
        formattedFields.push({
          key: `user-${user.id}`,
          label: "",
          class: "col-shrink text-center",
        });
      });

      return formattedFields;
    },

    sortedUsersById() {
      return [...this.organizationUsers].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
  },

  methods: {
    documentRead(readings, user) {
      if (readings.length > 0) {
        return !!readings.find((reading) => reading.user_id == user.id);
      }

      return false;
    },

    async readDocument(readingDocument) {
      try {
        const response = await axios.post(
          this.url(`/documents/${readingDocument.id}/readings`)
        );

        readingDocument.readings.push(response.data);
      } catch (error) {
        this.handleError(error);
      }
    },

    handleShare(doc) {
      this.shareDocument = doc;
    },
  },
};
</script>
