<template>
  <be-button
    variant="outline-secondary"
    :size="size"
    icon="fa-download"
    :title="!title"
    @click="download"
  >
    <template v-if="title">
      {{ title }}
    </template>
  </be-button>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },

    variant: {
      type: String,
      required: true,
    },

    size: {
      type: String,
      required: false,
      default: "md",
    },
  },

  methods: {
    download() {
      // Check if the function exists
      const func = window["wzGetController"].call()[this.variant];

      if (!(typeof func == "function")) {
        console.error(
          `[VisualBy] Download function '${this.variant}' does not exist in the wzGetController namespace.`
        );

        return false;
      }

      if (!window.gapi) {
        window.wzGetController().loadGoogleAuth2Api(() => {
          this.initializeDownload();
        });
      } else {
        this.initializeDownload();
      }
    },

    initializeDownload() {
      const hasGoogleDrive =
        this.$store.getters["company/hasEnabledIntegration"]("google_drive");

      // Trigger the download of the file
      window.wzGetController()[this.variant](
        this.$activeFinancialsUuid,
        hasGoogleDrive,
        () => {
          return true;
        },
        (message) => {
          EventBus.emit("new-notification", {
            message: message.data.message,
            status: "danger",
          });
        }
      );
    },
  },
};
</script>
