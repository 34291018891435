<template>
  <div id="editor"></div>
</template>

<script>
export default {
  props: {
    quickLinks: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      initialized: false,
      selector: "#editor",
      loading: false,
    };
  },

  computed: {
    groupUuid() {
      return this.$currentCompany.corporate_group_uuid;
    },
  },

  mounted() {
    this.waitForFinancials(this.initializeComponent);
  },

  methods: {
    initializeComponent() {
      if (this.initialized) {
        return;
      }

      this.initialized = true;

      const ownerDistributionSettingsLinks = {};
      ownerDistributionSettingsLinks[this.groupUuid] = {
        text: this.$t("application.corporate_groups"),
        url: this.url("/group"),
      };

      window
        .wzGetController()
        .addCorporateGroupConsolidation(
          this.selector,
          this.groupUuid,
          this.quickLinks,
          ownerDistributionSettingsLinks
        );
    },
  },
};
</script>
