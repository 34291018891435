<template>
  <be-popover :id="id" ref="phrases" :target="target" placement="right">
    <template #title>
      <div class="d-flex justify-content-between align-items-center">
        {{ $t("activerecord.models.phrase.other") }}

        <be-button
          v-be-tooltip="$t('buttons.titles.close')"
          class="text-reset"
          variant="link"
          size="sm"
          icon="fa-times"
          icon-style="fas"
          @click="closePhrasesPopover"
        />
      </div>
    </template>

    <div>
      <be-form-input
        ref="phrasesSearchInput"
        v-model="phraseQuery"
        class="mb-2"
        :placeholder="$t('buttons.titles.search')"
        @keyup.escape="closePhrasesPopover"
      />

      <template v-if="filteredPhrases.length">
        <be-list-group id="phrases">
          <be-list-group-item
            v-for="phrase in paginatedPhrases"
            :key="`phrase-${phrase.id}`"
            button
            @click="insertPhrase(phrase)"
            @keyup.escape="closePhrasesPopover"
          >
            {{ phrase.content }}
          </be-list-group-item>
        </be-list-group>

        <div
          v-if="filteredPhrases.length > phrasePerPage"
          class="d-flex justify-content-center mt-2"
        >
          <be-pagination
            v-model="phrasePage"
            :total-rows="filteredPhrases.length"
            :per-page="phrasePerPage"
            aria-controls="phrases"
            hide-end-buttons
            hide-ellipsis
          />
        </div>
      </template>

      <div v-else class="item">
        {{ $t("components.form.text_editor.phrases.no_results") }}
      </div>
    </div>
  </be-popover>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },

    target: {
      type: String,
      required: true,
    },
  },

  emits: ["insert-phrase"],

  data() {
    return {
      phrasePage: 1,
      phrasePerPage: 5,
      phraseQuery: "",
    };
  },

  computed: {
    ...mapGetters({ phrases: "phrases/getPhrases" }),

    filteredPhrases() {
      const query = this.phraseQuery.toLowerCase();
      return this.phrases.filter((phrase) =>
        phrase.content.toLowerCase().includes(query)
      );
    },

    paginatedPhrases() {
      return this.filteredPhrases.slice(
        (this.phrasePage - 1) * this.phrasePerPage,
        this.phrasePage * this.phrasePerPage
      );
    },
  },

  watch: {
    phraseQuery() {
      this.phrasePage = 1;
    },
  },

  methods: {
    closePhrasesPopover() {
      EventBus.emit("be::hide::popover", this.id);
      this.phraseQuery = "";
      this.phrasePage = 1;
    },

    insertPhrase(phrase) {
      this.$emit("insert-phrase", phrase);
      this.closePhrasesPopover();
    },
  },
};
</script>
