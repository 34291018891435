<template>
  <form
    ref="form"
    :class="computedClasses"
    @input="onInput"
    @submit.prevent="onSubmit"
  >
    <be-form-group
      :label="label"
      label-for="code-input-1"
      :error="error || localError"
      :error-class="justify ? null : 'text-center'"
      :label-class="{ 'text-center': !justify, 'text-left': justify }"
      class="mb-0"
    >
      <div :class="computedInputWrapperClasses">
        <be-form-input
          v-for="index in parsedLength"
          :id="`code-input-${index}`"
          :key="index"
          :ref="`input-${index}`"
          :type="type"
          maxlength="1"
          :pattern="pattern"
          autocomplete="off"
          :class="['p-0 text-center text-uppercase', { 'is-invalid': error }]"
          :aria-label="`Code character ${index + 1}`"
          :size="size"
          @focus="onFocus"
          @keydown="onKeyDown"
          @paste="onPaste"
        />
      </div>
    </be-form-group>
  </form>
</template>

<script>
import {
  KEY_CODE_BACKSPACE,
  KEY_CODE_LEFT,
  KEY_CODE_RIGHT,
} from "@/constants/key-codes";

export default {
  name: "BeCodeInput",

  props: {
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },

    error: {
      type: String,
      required: false,
      default: undefined,
    },

    justify: {
      type: Boolean,
      required: false,
      default: false,
    },

    label: {
      type: String,
      required: false,
      default: undefined,
    },

    length: {
      type: [Number, String],
      required: false,
      default: 6,
    },

    modelValue: {
      type: String,
      required: false,
      default: undefined,
    },

    pattern: {
      type: String,
      required: false,
      default: "[a-zA-Z0-9]",
    },

    patternError: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.be_code_input.invalid_character")
    },

    size: {
      type: String,
      required: false,
      default: undefined,

      validator: (value) => {
        return ["sm", "lg"].includes(value);
      },
    },

    type: {
      type: String,
      required: false,
      default: "text",

      validator: (value) => {
        return ["text", "password"].includes(value);
      },
    },
  },

  emits: ["done", "input", "update:modelValue"],

  data() {
    return {
      form: null,
      inputs: [],
      localError: null,
      localValue: this.modelValue,
    };
  },

  computed: {
    computedClasses() {
      return [
        "be-code-input",
        {
          [`be-code-input-${this.size}`]: this.size,
          justify: this.justify,
        },
      ];
    },

    computedInputWrapperClasses() {
      return [
        "d-flex",
        "align-items-center",
        "flex-nowrap",
        "gap-1",
        {
          "justify-content-center": !this.justify,
          "justify-content-start": this.justify,
        },
      ];
    },

    parsedLength() {
      return parseInt(this.length) || 6;
    },

    firstInput() {
      return this.inputs[0] || null;
    },

    allInputsFilled() {
      return this.inputs.every((input) => input.value);
    },

    fullCode() {
      return this.inputs.map((input) => input.value).join("");
    },
  },

  watch: {
    modelValue(newValue) {
      this.localValue = newValue;

      if (newValue) {
        this.inputs.forEach((input, index) => {
          input.value = newValue[index] || "";
        });
      }
    },
  },

  mounted() {
    this.form = this.$refs.form;
    this.inputs = Array.from(this.form.querySelectorAll("input"));

    if (this.localValue) {
      this.inputs.forEach((input, index) => {
        input.value = this.localValue[index] || "";
      });
    }

    if (this.autofocus) {
      this.$nextTick(() => {
        if (this.firstInput) {
          this.firstInput.focus();
        }
      });
    }
  },

  methods: {
    handleArrowLeft(event) {
      const input = event.target;
      const previousInput = input.previousElementSibling;

      if (previousInput) {
        previousInput.focus();
      }
    },

    handleArrowRight(event) {
      const input = event.target;
      const nextInput = input.nextElementSibling;

      if (nextInput) {
        nextInput.focus();
      }
    },

    handleBackspace(event) {
      const input = event.target;
      const previousInput = input.previousElementSibling;

      if (input.value) {
        input.value = "";
        return;
      }

      if (previousInput) {
        previousInput.focus();
      }
    },

    onFocus(event) {
      this.$nextTick(() => {
        event.target.select();
      });
    },

    onInput(event) {
      const input = event.target;
      const nextInput = input.nextElementSibling;
      const regex = new RegExp(this.pattern);

      this.inputs = Array.from(this.form.querySelectorAll("input"));

      // Validate input value
      if (input.value && !regex.test(input.value)) {
        input.classList.add("is-invalid");

        this.localError =
          this.patternError ||
          this.$t("components.shared.be_code_input.invalid_character");

        this.inputs.forEach(($input) => {
          if ($input !== input) {
            $input.disabled = true;
          }
        });
        return;
      } else {
        input.classList.remove("is-invalid");
        this.localError = null;
        this.inputs.forEach(($input) => {
          $input.disabled = false;
        });
      }

      if (nextInput && input.value) {
        nextInput.focus();

        if (nextInput.value) {
          nextInput.select();
        }
      } else if (this.allInputsFilled) {
        this.onSubmit();
      }

      this.$emit("input", this.fullCode);
      this.$emit("update:modelValue", this.fullCode);
    },

    onKeyDown(event) {
      switch (event.keyCode) {
        case KEY_CODE_LEFT:
          this.handleArrowLeft(event);
          break;

        case KEY_CODE_RIGHT:
          this.handleArrowRight(event);
          break;

        case KEY_CODE_BACKSPACE:
          this.handleBackspace(event);
          break;

        default:
          break;
      }
    },

    onPaste(event) {
      event.preventDefault();

      const paste = event.clipboardData.getData("text").trim();

      this.inputs.forEach((input, index) => {
        input.value = paste[index] || "";
      });

      this.inputs[this.inputs.length - 1].focus();

      if (this.allInputsFilled) {
        this.onSubmit(paste);
      }
    },

    onSubmit(code) {
      this.$emit("done", code || this.fullCode);
    },

    reset() {
      this.inputs.forEach((input) => {
        input.value = "";
      });

      this.inputs[0].focus();
    },
  },
};
</script>
