<template>
  <div>
    <div class="d-flex flex-wrap align-items-start justify-content-between">
      <div class="d-flex flex-row">
        <div class="mb-3">
          <label for="search-state">
            {{ $t("components.shared.tasks_search.status") }}
          </label>

          <div class="row no-gutters mb-2 mb-md-0">
            <div class="col-12 col-md-auto mr-1">
              <be-form-select
                id="search-state"
                v-model="searchState"
                :options="stateOptions"
                required
              />
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label for="search-source">
            {{ $t("components.shared.tasks_search.source") }}
          </label>

          <div class="row no-gutters mb-2 mb-md-0">
            <div class="col-12 col-md-auto">
              <be-form-select
                v-model="searchSource"
                :options="sourceOptions"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-flex justify-content-between">
          <label for="search-date-start">
            {{ $t("components.tasks.filter_due_at") }}
          </label>
        </div>

        <div class="row no-gutters mb-2 mb-md-0">
          <div class="col-12 col-md-auto mb-2 mb-lg-0">
            <be-form-datepicker
              id="search-date-start"
              v-model="searchDateStart"
              :max-date="maxDateStart"
            />
          </div>

          <div class="d-none d-lg-inline col-md-auto">
            <div class="input-group-prepend input-group-append">
              <span class="input-group-text">-</span>
            </div>
          </div>

          <div class="col-12 col-md-auto">
            <be-form-datepicker
              id="search-date-end"
              v-model="searchDateEnd"
              :min-date="minDateEnd"
            />
          </div>
        </div>
      </div>

      <div class="mb-3">
        <be-form-group
          :label="$t('components.shared.be_table.search')"
          label-for="task-search"
        >
          <template #label-after>
            <i
              v-be-tooltip="
                $t(
                  'components.shared.tasks_search.search_by_description_and_user'
                )
              "
              class="fal fa-question-circle ml-1"
            />
          </template>

          <be-input-group>
            <be-input-group-prepend>
              <be-input-group-text class="bg-transparent pr-0">
                <i class="search-icon fal fa-search" />
              </be-input-group-text>
            </be-input-group-prepend>

            <be-form-input
              id="task-search"
              v-model="searchQuery"
              type="search"
              class="border-left-0"
              :placeholder="$t('components.shared.be_table.type_to_search')"
            />
          </be-input-group>
        </be-form-group>
      </div>
    </div>

    <paginated-table
      :items="tasks"
      :fields="taskFields"
      :pagination="pagination"
      :sorting="{ sortBy, sortDirection }"
      :loading="searchLoading"
      @page-changed="paginationChanged"
      @sorting-changed="sortingChanged"
    >
      <template v-if="hasSearchedForTasks" #empty>
        <be-alert variant="info">
          {{ $t("components.shared.be_table.no_results") }}
        </be-alert>
      </template>

      <template #description="data">
        {{ data.item.description }}
      </template>

      <template #done_at="data">
        <div v-if="data.item.done_at">
          {{ $d(new Date(data.item.done_at), "date") }}
        </div>
      </template>

      <template #due_at="data">
        <div v-if="data.item.due_at">
          {{ $d(new Date(data.item.due_at), "date") }}
        </div>
      </template>

      <template #user_id="data">
        <user-avatar v-if="data.item.user_id" :user="data.item.user_id" />
      </template>

      <template #options="data">
        <be-button
          v-if="referenceFor(data.item.id)"
          :loading="loadingIds.includes(data.item.id)"
          variant="danger"
          size="sm"
          icon="fa-times"
          inline
          @click="$emit('remove-reference', referenceFor(data.item.id))"
        />

        <be-button
          v-else
          :loading="loadingIds.includes(data.item.id)"
          size="sm"
          inline
          @click="$emit('add-reference', data.item.id)"
        >
          {{ $t("buttons.titles.add_more") }}
        </be-button>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { parsePagination } from "@/helpers/pagination";

import UserAvatar from "@/components/user/UserAvatar.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";

export default {
  components: {
    UserAvatar,
    PaginatedTable,
  },

  props: {
    references: {
      type: Array,
      required: true,
    },

    loadingIds: {
      type: Array,
      required: true,
    },
  },

  emits: ["add-reference", "remove-reference"],

  data() {
    return {
      tasks: [],
      pagination: parsePagination({}),
      searchQuery: "",
      searchDateStart: null,
      searchDateEnd: null,
      searchState: "active",
      searchSource: "all",
      searchLoading: true,
      sortBy: "description",
      sortDirection: "asc",
    };
  },

  computed: {
    stateOptions() {
      return [
        {
          text: this.$t("models.task.states.all"),
          value: "all",
        },
        {
          text: this.$t("models.task.states.active"),
          value: "active",
        },
        {
          text: this.$t("models.task.states.done"),
          value: "done",
        },
        {
          text: this.$t("models.task.states.archived"),
          value: "archived",
        },
      ];
    },

    sourceOptions() {
      return [
        {
          text: this.$t("models.task.sources.all"),
          value: "all",
        },
        {
          text: this.$t("models.task.sources.programmatic"),
          value: "programmatic",
        },
        {
          text: this.$t("models.task.sources.manual"),
          value: "manual",
        },
      ];
    },

    taskFields() {
      return [
        {
          key: "description",
          label: this.$t("activerecord.models.task.one"),
          sortable: true,
        },
        {
          key: "done_at",
          label: this.$t("activerecord.attributes.task.done_at"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "due_at",
          label: this.$t("activerecord.attributes.task.due_at"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "user_id",
          label: this.$t("activerecord.attributes.task.assigned_to"),
          class: "col-shrink text-center",
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink text-center",
        },
      ];
    },

    maxDateStart() {
      return this.searchDateEnd ? new Date(this.searchDateEnd) : null;
    },

    minDateEnd() {
      return this.searchDateStart ? new Date(this.searchDateStart) : null;
    },

    hasSearchedForTasks() {
      return (
        this.searchQuery ||
        this.searchDateStart ||
        this.searchDateEnd ||
        this.searchState != "all" ||
        this.searchSource != "all"
      );
    },
  },

  watch: {
    searchQuery: debounce(function () {
      this.searchTasks(true);
    }, 500),

    searchDateStart() {
      this.searchTasks(true);
    },

    searchDateEnd() {
      this.searchTasks(true);
    },

    searchState() {
      this.searchTasks(true);
    },

    searchSource() {
      this.searchTasks(true);
    },
  },

  mounted() {
    this.searchTasks();
  },

  methods: {
    referenceFor(task_id) {
      return this.references.find((reference) => {
        return (
          reference.reference_type === "Task" &&
          reference.reference_id === task_id
        );
      });
    },

    async searchTasks(resetPage) {
      const page = resetPage ? 1 : this.pagination.currentPage;
      try {
        const { data, headers } = await axios.post(this.url("/tasks/search"), {
          page: page,

          search: {
            query: this.searchQuery,
            date_start: this.searchDateStart,
            date_end: this.searchDateEnd,
            state: this.searchState,
            source: this.searchSource,
            sort_by: this.sortBy,
            sort_direction: this.sortDirection,
          },
        });

        this.pagination = parsePagination(headers);
        this.tasks = data;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.searchLoading = false;
      }
    },

    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.searchTasks();
    },

    sortingChanged({ sortBy, sortDirection }) {
      this.sortBy = sortBy;
      this.sortDirection = sortDirection;
      this.searchTasks();
    },
  },
};
</script>
