<template>
  <component
    :is="computedGroupTag"
    :class="computedGroupClasses"
    v-bind="computedGroupAttrs"
  >
    <component
      :is="computedLabelTag"
      v-if="renderLabel"
      :class="computedLabelClasses"
      v-bind="computedLabelAttrs"
      @click="isFieldSet ? onLegendClick : null"
    >
      <slot name="label">
        {{ label }}
      </slot>

      <abbr v-if="localRequired" v-be-tooltip="$t('forms.required_field')">
        <span>*</span>
      </abbr>

      <span v-if="renderLabelAfter" :class="labelAfterClass">
        <slot name="label-after">
          {{ labelAfter }}
        </slot>
      </span>
    </component>

    <!-- Slot with scoped data -->
    <slot :id="id" ref="content" :aria-describedby="ariaDescribedBy" />

    <be-form-invalid-feedback
      v-if="(invalidFeedback || error) && computedState === false"
      :aria-live="feedbackAriaLive"
      :state="computedState"
      :class="errorClass"
    >
      <slot name="invalid-feedback">
        {{ computedInvalidFeedback }}
      </slot>
    </be-form-invalid-feedback>

    <be-form-valid-feedback
      v-if="validFeedback && computedState === true"
      :aria-live="feedbackAriaLive"
      :state="computedState"
    >
      <slot name="valid-feedback">
        {{ validFeedback }}
      </slot>
    </be-form-valid-feedback>

    <small
      v-if="description || $slots.description"
      class="form-text text-muted"
    >
      <slot name="description">
        {{ description }}
      </slot>
    </small>
  </component>
</template>

<script>
import { generateId } from "@/utils/id";
import formStateMixin from "@/mixins/forms/form-state";

const INPUTS = ["input", "select", "textarea"];
const INPUT_SELECTOR = INPUTS.map((v) => `${v}:not([disabled])`).join();
const LEGEND_INTERACTIVE_ELEMENTS = [...INPUTS, "a", "button", "label"];

export default {
  name: "BeFormGroup",

  mixins: [formStateMixin],

  props: {
    description: {
      type: String,
      required: false,
      default: undefined,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    error: {
      type: [Boolean, String, Array],
      required: false,
      default: false,
    },

    errorClass: {
      type: [String, Array, Object],
      required: false,
      default: undefined,
    },

    feedbackAriaLive: {
      type: String,
      required: false,
      default: "assertive",
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("be-form-group"),
    },

    inline: {
      type: Boolean,
      required: false,
      default: false,
    },

    invalidFeedback: {
      type: String,
      required: false,
      default: undefined,
    },

    label: {
      type: String,
      required: false,
      default: undefined,
    },

    labelAfter: {
      type: String,
      required: false,
      default: undefined,
    },

    labelAfterClass: {
      type: [String, Array, Object],
      required: false,
      default: undefined,
    },

    labelClass: {
      type: [String, Array, Object],
      required: false,
      default: undefined,
    },

    labelFor: {
      type: String,
      required: false,
      default: undefined,
    },

    labelSize: {
      type: String,
      required: false,
      default: undefined,

      validator: (value) => {
        return ["sm", "lg"].includes(value);
      },
    },

    labelSrOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    validFeedback: {
      type: String,
      required: false,
      default: undefined,
    },

    validated: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      ariaDescribedBy: null,
      localRequired: this.required,
    };
  },

  computed: {
    computedGroupAttrs() {
      return {
        id: this.id,
        disabled: this.isFieldSet ? this.disabled : null,
        role: this.isFieldSet ? null : "group",
        "aria-invalid": this.computedAriaInvalid || null,
      };
    },

    computedGroupClasses() {
      return [
        "form-group",
        this.stateClass,
        {
          "was-validated": this.validated,
          "form-inline": this.inline,
        },
      ];
    },

    computedGroupTag() {
      return this.labelFor ? "div" : "fieldset";
    },

    computedInvalidFeedback() {
      switch (typeof this.error) {
        case "string":
          return this.error;
        case "boolean":
          return this.invalidFeedback;
        default:
          return this.error.join(", ");
      }
    },

    computedLabelAttrs() {
      return {
        for: this.labelFor || null,
      };
    },

    computedLabelClasses() {
      const { isFieldSet, labelClass, labelSize, labelSrOnly } = this;

      return labelSrOnly
        ? "sr-only"
        : [
            labelClass,
            {
              "be-no-focus-ring": isFieldSet,
              "pt-0": isFieldSet,
              "col-form-label": isFieldSet,
              "d-block": !isFieldSet,
              [`col-form-label-${labelSize}`]: labelSize,
            },
          ];
    },

    computedLabelTag() {
      return this.labelFor ? "label" : "legend";
    },

    isFieldSet() {
      return this.computedGroupTag === "fieldset";
    },

    renderLabel() {
      return this.label || this.$slots.label;
    },

    renderLabelAfter() {
      return this.labelAfter || this.$slots["label-after"];
    },
  },

  watch: {
    ariaDescribedBy(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateAriaDescribedBy(newValue, oldValue);
      }
    },

    required(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.localRequired = newValue;
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      // Set `aria-describedby` on the input specified by `labelFor`
      // We do this in a `nextTick` to ensure that the input has been rendered
      this.updateAriaDescribedBy(this.ariaDescribedBy);

      // Check if a child input is required
      // We do this in a `nextTick` to ensure that the input has been rendered
      this.updateRequired();
    });
  },

  methods: {
    updateAriaDescribedBy(newValue, oldValue) {
      const { labelFor } = this;

      if (labelFor) {
        const $input = document.getElementById(labelFor);

        if ($input) {
          const newIds = (newValue || "").split(/\s+/);
          const oldIds = (oldValue || "").split(/\s+/);
          const ariaDescribedBy = (
            $input.getAttribute("aria-describedby") || ""
          ).split(/\s+/);

          // Update ID list, preservering any original IDs and ensuring the ID's are unique
          const ids = [...new Set([...ariaDescribedBy, ...newIds, ...oldIds])];

          if (ids) {
            $input.setAttribute("aria-describedby", ids.join(" "));
          } else {
            $input.removeAttribute("aria-describedby");
          }
        }
      }
    },

    updateRequired() {
      const { labelFor } = this;

      // Check if a child input is required
      if (labelFor) {
        const $input = document.getElementById(labelFor);

        if ($input) {
          this.localRequired = $input.required;
        }
      }

      // If a child input is not required, use the `required` prop
      if (!this.localRequired) {
        this.localRequired = this.required;
      }
    },

    onLegendClick(event) {
      // Don't do anything if `labelFor` is set
      if (this.labelFor) {
        return;
      }

      const { target } = event;
      const tagName = target ? target.tagName : "";

      // If the user clicked an interactive element inside legends,
      // we just let the default happen
      if (LEGEND_INTERACTIVE_ELEMENTS.includes(tagName.toLowerCase())) {
        return;
      }

      // Get all visible inputs inside the content ref
      const inputs = this.$refs.content.querySelectorAll(INPUT_SELECTOR);

      // If only a single input is found, focus it to emulate label behavior
      if (inputs.length === 1) {
        inputs[0].focus();
      }
    },
  },
};
</script>
