<template>
  <div>
    <intl-tel-input
      ref="telInput"
      :options="intlTelInputOptions"
      :value="localValue"
      :input-props="computedAttrs"
      @change-number="onChangeNumber"
    />

    <small v-if="showLocalError" class="text-danger mt-2">
      <i class="fas fa-hexagon-exclamation" />
      {{ localError }}
    </small>
  </div>
</template>

<script>
import IntlTelInput from "intl-tel-input/vueWithUtils";
import formStateMixin from "@/mixins/forms/form-state";
import formTextMixin from "@/mixins/forms/form-text";
import { generateId } from "@/utils/id";

export default {
  name: "BeTelInput",

  components: {
    IntlTelInput,
  },

  mixins: [formStateMixin, formTextMixin],

  props: {
    // This is used for ERB forms
    hiddenInput: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,

      default: () => {
        return generateId("be-tel-input");
      },
    },

    modelValue: {
      type: [String, Number],
      required: false,
      default: "",
    },
  },

  emits: ["input", "change", "update:modelValue"],

  data() {
    return {
      localError: null,
      localValue: this.modelValue,
    };
  },

  computed: {
    computedAttrs() {
      const {
        id,
        name,
        form,
        disabled,
        autocomplete,
        readonly,
        plaintext,
        required,
        computedAriaInvalid,
        computedRequired,
      } = this;

      return {
        id,
        name,
        form,
        disabled: disabled || null,
        required: computedRequired || null,
        autocomplete: autocomplete || null,
        readonly: readonly || plaintext || null,
        "aria-required": required ? "true" : null,
        "aria-invalid": computedAriaInvalid || null,
      };
    },

    intlTelInputOptions() {
      return {
        autoPlaceholder: "off",
        initialCountry: "se",

        // Creates a hidden input with the same name as the tel input,
        // which can be used in ERB forms to get the full international
        // phone number value. It also creates a hidden input with the
        // country code, which can be used to get the country code value.
        // If the tel input name ends in brackets, e.g. "user[phone]",
        // we need to manually define the correct hidden input name by
        // returning an object and appending the "_country" suffix inside
        // the brackets, otherwise Rails will not be able to parse the
        // params correctly.
        hiddenInput: this.hiddenInput
          ? function (telInputName) {
              // Check if the name ends in brackets
              if (telInputName.endsWith("]")) {
                return {
                  // Append "_country" to the name inside the last brackets
                  country: telInputName.replace(
                    /(\[[^\]]+)(\])$/,
                    `$1_country$2`
                  ),

                  phone: telInputName,
                };
              } else {
                return telInputName;
              }
            }
          : null,

        // Translations
        // TODO: Maybe add translations for each country?
        i18n: {
          selectedCountryAriaLabel: this.$t(
            "components.form.be_tel_input.selected_country_aria_label"
          ),

          countryListAriaLabel: this.$t(
            "components.form.be_tel_input.country_list_aria_label"
          ),

          searchPlaceholder: this.$t(
            "components.form.be_tel_input.search_placeholder"
          ),
        },
      };
    },

    showLocalError() {
      return this.localError && !this.$parent?.error;
    },
  },

  watch: {
    modelValue(newValue) {
      this.localValue = String(newValue);
    },
  },

  mounted() {
    this.$nextTick(() => {
      const { instance } = this.$refs.telInput || {};

      if (this.modelValue && instance) {
        // We need to do this to make sure the number is set in ERB-forms
        // when the component is mounted, I don't know why it's necessary.
        instance.setNumber(this.modelValue);

        this.checkValidity(this.modelValue);
      }
    });
  },

  methods: {
    onChangeNumber(value) {
      this.$emit("input", value);
      this.$emit("change", value);
      this.$emit("update:modelValue", value);

      this.checkValidity(value);
    },

    checkValidity(value) {
      const { instance, input } = this.$refs.telInput;

      if (value && !instance.isValidNumber()) {
        input.classList.add("is-invalid");

        this.localError = this.$t(
          "components.form.be_tel_input.invalid_phone_number"
        );
      } else {
        input.classList.remove("is-invalid");
        this.localError = null;
      }
    },
  },
};
</script>
