<template>
  <textarea
    v-bind="computedAttrs"
    ref="input"
    v-be-visible="observeVisibilityOptions"
    :class="computedClass"
    :style="computedStyles"
    :value="localValue"
    @input="onInput"
    @change="onChange"
    @focus="onFocus"
    @blur="onBlur"
    @paste="onPaste"
  />
</template>

<script>
import formStateMixin from "@/mixins/forms/form-state";
import formTextMixin from "@/mixins/forms/form-text";
import { generateId } from "@/utils/id";

export default {
  name: "BeFormTextarea",

  mixins: [formStateMixin, formTextMixin],

  props: {
    autoResize: {
      type: Boolean,
      required: false,
      default: true,
    },

    id: {
      type: String,
      required: false,

      default: () => {
        return generateId("be-form-textarea");
      },
    },

    maxlength: {
      type: [Number, String],
      required: false,
      default: 5000,
    },

    maxRows: {
      type: [Number, String],
      required: false,
      default: 6,
    },

    modelValue: {
      type: [String, Number],
      required: false,
      default: "",
    },

    noAutoShrink: {
      type: Boolean,
      required: false,
      default: false,
    },

    noResize: {
      type: Boolean,
      required: false,
      default: false,
    },

    rows: {
      type: [Number, String],
      required: false,
      default: 2,
    },

    size: {
      type: String,
      required: false,
      default: null,
    },

    wrap: {
      type: String,
      required: false,
      default: "soft",
    },
  },

  emits: ["blur", "change", "input", "update", "update:modelValue"],

  data() {
    return {
      heightInPx: null,

      observeVisibilityOptions: {
        callback: this.visibilityChanged,

        intersection: {
          rootMargin: "640px",
        },
      },
    };
  },

  computed: {
    computedStyles() {
      const styles = {
        // Setting `noResize` to true will disable the ability for the user to
        // manually resize the textarea. We also disable when in auto resize mode.
        resize: this.noResize || this.autoResize ? "none" : null,
      };

      if (this.autoResize) {
        styles.height = this.heightInPx;

        // We always add a vertical scrollbar to the textarea when auto-height is
        // enabled so that the computed height calculation returns a stable value.
        // TODO: I'm not sure if this is needed? :/
        //styles.overflowY = "scroll";
      }

      return styles;
    },

    computedMinRows() {
      // Ensure rows is at least 2 and positive (2 is the native textarea value)
      // A value of 1 can cause issues in some browsers, and most browsers
      // only support 2 as the smallest value.
      return Math.max(Number(this.rows), 2);
    },

    computedMaxRows() {
      // If computedMinRows is greater than maxRows, we use computedMinRows
      return Math.max(Number(this.maxRows), this.computedMinRows);
    },

    computedRows() {
      // This is used to set the attribute `rows` on the textarea
      // If `autoResize` is enabled, then we return `null` as we use CSS to control height
      return this.autoResize ? null : this.computedMinRows;
    },

    computedAttrs() {
      return {
        id: this.id,
        name: this.name || null,
        form: this.form || null,
        disabled: this.disabled || null,
        placeholder: this.placeholder || null,
        required: this.computedRequired || null,
        maxlength: this.maxlength,
        autocomplete: this.autocomplete || null,
        readonly: this.readonly || this.plaintext || null,
        rows: this.computedRows,
        wrap: this.wrap || null,
        "aria-required": this.required ? "true" : null,
        "aria-invalid": this.computedAriaInvalid || null,
      };
    },
  },

  watch: {
    localValue() {
      this.setHeight();
    },
  },

  mounted() {
    this.setHeight();
  },

  methods: {
    // Called by intersection observer directive
    visibilityChanged(visible) {
      if (visible) {
        // We use a `$nextTick()` here just to make sure any
        // transitions or portalling have completed
        this.$nextTick(this.setHeight);
      }
    },

    setHeight() {
      this.$nextTick(() => {
        this.heightInPx = this.computeHeight();
      });
    },

    computeHeight() {
      if (!this.autoResize) {
        return null;
      }

      const el = this.$refs.input;

      // Element must be visible (not hidden) and in document
      if (!el || el.offsetHeight === 0 || !el.offsetParent) {
        return null;
      }

      // Get current computed styles
      const computedStyle = window.getComputedStyle(el, null);
      // Height of one line of text in px
      const lineHeight =
        Number(computedStyle.lineHeight.replace("px", "")) || 1;
      // Calculate height of border and padding
      const border =
        parseInt(computedStyle.borderTopWidth, 0) +
        parseInt(computedStyle.borderBottomWidth, 0);
      const padding =
        parseInt(computedStyle.paddingTop, 0) +
        parseInt(computedStyle.paddingBottom, 0);
      // Calculate offset
      const offset = border + padding;
      // Minimum height for mins rows (which must be 2 rows or greater for cross-browser support)
      const minHeight = lineHeight * this.computedMinRows + offset;

      // Get the current style height (in px)
      const oldHeight = el.style.height || computedStyle.height;
      // Probe scrollHeight by temporarily changing the height to `auto`
      el.style.height = "auto";
      const scrollHeight = el.scrollHeight;
      // Place the original old height back on the element, just in case `computedStyle`
      // returns the same value as before
      el.style.height = oldHeight;

      // Calculate content height in `rows` (scrollHeight includes padding but not border)
      const contentRows = Math.max((scrollHeight - padding) / lineHeight, 2);
      // Calculate number of rows to display (limited within min/max rows)
      const rows = Math.min(
        Math.max(contentRows, this.computedMinRows),
        this.computedMaxRows
      );
      // Calculate the required height of the textarea including border and padding (in px)
      const height = Math.max(Math.ceil(rows * lineHeight + offset), minHeight);

      // Computed height remains the larger of `oldHeight` and new `height`,
      // when height is in "sticky" mode (prop `no-auto-shrink` is true)
      if (this.noAutoShrink && parseFloat(oldHeight) > height) {
        return oldHeight;
      }

      // Return the new computed CSS height in px
      return `${height}px`;
    },
  },
};
</script>
