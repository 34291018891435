import i18n from "@/i18n";

const FLAG_MAPPING = {
  en: "GB",
  sv: "SE",
};

export default i18n.global.availableLocales
  .filter((locale) => locale !== "default")
  .map((locale) => {
    return {
      code: FLAG_MAPPING[locale],
      language: i18n.global.t(`locales.${locale}`),
      value: locale,
    };
  });
