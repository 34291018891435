/**
 * Hashtags
 *
 * Add new hashtags by creating an object with the keys:
 *
 * - id: The internal value that we use to match and update (must be unique)
 * - value: The text that's displayed for the user in the text editor
 * - placeholder: What the user will see when typing
 */

const MEETING_HASHTAGS = [
  "chairman",
  "secretary",
  "reviewer",
  "reviewer_including_chairman",
  "next_meeting",
  "ceo",
  "financialmanager",
].map((hashtag) => {
  return {
    id: hashtag,
    value: "",
    placeholder: null,
  };
});

/**
 * Ignore these for now since they're not implemented yet:
 *   ["due_date", "assigned_to"]
 */

export { MEETING_HASHTAGS };
