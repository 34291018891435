<template>
  <div class="d-flex align-items-center">
    <img
      v-if="computedFlagUrl"
      :alt="option[optionLabel]"
      :src="computedFlagUrl"
      :style="computedFlagIconStyle"
      class="mr-2"
      @error.prevent
    />

    {{ option[optionLabel] }}
  </div>
</template>

<script>
export default {
  inject: ["optionLabel"],

  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  computed: {
    computedFlagIconStyle() {
      return {
        borderRadius: "0.15rem",
        height: "0.9rem",
      };
    },

    computedFlagUrl() {
      if (this.option?.code) {
        return this.imageSrc(
          `icons/flags/${this.option.code.toUpperCase()}.svg`
        );
      } else {
        return null;
      }
    },
  },
};
</script>
