<template>
  <be-badge v-if="count && count > 0" variant="notification" pill :class="css">
    {{ count }}
  </be-badge>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    css: {
      type: String,
      required: false,
      default: "ml-1",
    },

    // Supports: [total, pending_signatures, contracts]
    mode: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters("company", ["unreads", "unreadsLoading"]),

    count() {
      if (this.mode === "total") {
        return this.pendingSignatures + this.contractNotifications;
      } else if (this.mode === "pending_signatures") {
        return this.pendingSignatures;
      } else if (this.mode === "contracts") {
        return this.contractNotifications;
      }

      return 0;
    },

    pendingSignatures() {
      return (
        (this.unreads?.pending_document_signatures || 0) +
        (this.unreads?.pending_meeting_signatures || 0)
      );
    },

    contractNotifications() {
      return this.unreads?.contract_notifications || 0;
    },
  },

  async created() {
    this.loadUnreads();
  },

  methods: {
    ...mapActions("company", ["loadUnreads"]),
  },
};
</script>
