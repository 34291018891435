<template>
  <be-table v-if="userIds.length > 0" :fields="fields" :items="references">
    <template
      v-for="user_id in userIds"
      #[`head(user-${user_id})`]
      :key="`avatar-${user_id}`"
    >
      <user-avatar :user="user_id" />
    </template>

    <template #description="{ item }">
      <i v-be-tooltip="`${item.description}`" :class="`fal ${item.icon}`" />
    </template>

    <template #reference="{ item }">
      {{ item.title }}
    </template>

    <template v-for="user_id in userIds" #[`user-${user_id}`]="{ item }">
      <be-status
        v-if="referenceRead(item, user_id)"
        :key="`status-${user_id}`"
        size="xl"
        variant="success"
        class="justify-content-center"
        :tooltip="$t('activerecord.attributes.reading.read')"
      />
    </template>
  </be-table>

  <be-alert v-else variant="info">
    {{ $t("components.meetings.overview.no_readings") }}
  </be-alert>
</template>

<script>
export default {
  props: {
    references: {
      type: Array,
      required: true,
    },

    userIds: {
      type: Array,
      required: true,
    },

    readings: {
      type: Array,
      required: true,
    },
  },

  computed: {
    fields() {
      let fields = [
        {
          key: "description",
          label: "",
          class: "col-shrink text-center",
        },
        {
          key: "reference",
          label: this.$t("attributes.title"),
        },
      ];

      this.userIds.forEach((id) => {
        fields.push({
          key: `user-${id}`,
          class: "col-shrink text-center",
        });
      });

      return fields;
    },
  },

  methods: {
    referenceRead(reference, user_id) {
      const ids = reference.ids || [reference.id];
      return ids.every(
        (id) =>
          this.readings.find((reading) => {
            let condition =
              reading.reference_type === reference.type &&
              reading.reference_id === id &&
              reading.user_id === user_id;
            if (reference.changedAt) {
              condition =
                condition &&
                new Date(reading.updated_at) >= reference.changedAt;
            }

            return condition;
          })?.read
      );
    },
  },
};
</script>
