<template>
  <ul
    v-if="filteredOptions.length > 0"
    ref="options"
    class="be-form-custom-select-dropdown-options"
    :style="{ maxHeight }"
  >
    <custom-select-dropdown-option
      v-for="(option, index) in filteredOptions"
      :key="`${option[optionValue]}-${index}`"
      :index="index"
      :option="option"
      @click="onOptionClick(option)"
      @mouseover="highlightOption(index)"
    >
      <template #option="optionScope">
        <slot name="option" v-bind="optionScope" />
      </template>
    </custom-select-dropdown-option>
  </ul>
</template>

<script>
import CustomSelectDropdownOption from "@/components/shared/be_form_select/CustomSelectDropdownOption.vue";

export default {
  components: {
    CustomSelectDropdownOption,
  },

  inject: [
    "filteredOptions",
    "maxHeight",
    "multiple",
    "groupSelectable",
    "optionLabel",
    "optionValue",
    "selected",
  ],

  emits: ["highlight-option", "option-click"],

  methods: {
    getOptions() {
      return Array.from(
        this.$refs.options.querySelectorAll(
          ".be-form-custom-select-dropdown-option:not(.disabled):not(:disabled)"
        )
      );
    },

    highlightOption(index) {
      this.$emit("highlight-option", index);
    },

    onOptionClick(option) {
      // Prevent selecting disabled options or groups (if groupSelectable is false)
      if (option.disabled || (option.options && !this.groupSelectable)) {
        return;
      }

      this.$emit("option-click", option);
    },
  },
};
</script>
