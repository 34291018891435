<template>
  <div>
    <template v-if="inline">
      <date-time-picker
        v-bind="{ ...dateTimePickerProps, ...startDateTimePickerProps }"
        v-model="startTime"
        :disabled="disabled"
      />

      <slot name="divider">
        <i class="fas fa-dash" />
      </slot>

      <date-time-picker
        v-bind="{ ...dateTimePickerProps, ...endDateTimePickerProps }"
        v-model="endTime"
        :min="notBeforeStartWithDuration"
        :disabled="disabled"
      />
    </template>

    <template v-else>
      <label v-if="label" class="d-none d-md-block" v-text="label"></label>

      <div class="row mb-lg-0">
        <slot name="left" />

        <div class="col-12 col-mg-8 col-lg" :class="leftPickerColumnClass">
          <date-time-picker
            v-bind="{ ...dateTimePickerProps, ...startDateTimePickerProps }"
            v-model="startTime"
            :label="$t('simple_form.labels.date_time_range_picker.from_date')"
            label-class="d-block d-lg-none"
            :disabled="disabled"
          />
        </div>

        <div
          class="col col-md-auto d-none d-lg-flex justify-content-center align-items-center justify-self-center"
        >
          <slot name="divider">
            <i class="fas fa-dash" />
          </slot>
        </div>

        <div class="col-12 col-md-8 col-lg" :class="rightPickerColumnClass">
          <date-time-picker
            v-bind="{ ...dateTimePickerProps, ...endDateTimePickerProps }"
            v-model="endTime"
            :label="$t('simple_form.labels.date_time_range_picker.to_date')"
            label-class="d-block d-lg-none"
            :min="notBeforeStartWithDuration"
            :disabled="disabled"
          />
        </div>

        <slot name="right" />
      </div>
    </template>
  </div>
</template>

<script>
import {
  isAfter,
  isBefore,
  differenceInMinutes,
  addMinutes,
  isEqual,
} from "date-fns";
import DateTimePicker from "./DateTimePicker.vue";

export default {
  components: { DateTimePicker },

  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },

    // Note if string: will return UTC iso string
    startAt: {
      type: [Date, String],
      required: false,
      default: new Date(),
    },

    endAt: {
      type: [Date, String],
      required: false,
      default: new Date(),
    },

    minutesStep: {
      type: Number,
      required: false,
      default: 5,
    },

    notBeforeNow: {
      type: Boolean,
      default: false,
      required: false,
    },

    /**
     *  Min range duration in minutes
     * if endAt is less than startTime, endTime becomes startTime + this value (minutes)
     */
    minDuration: {
      type: Number,
      default: 60,
      required: false,
    },

    /**
     *  Extra props for DateTimePickers components
     */
    dateTimePickerProps: {
      type: Object,
      required: false,
      default: () => {},
    },

    startDateTimePickerProps: {
      type: Object,
      required: false,
      default: () => {},
    },

    endDateTimePickerProps: {
      type: Object,
      required: false,
      default: () => {},
    },

    size: {
      type: String,
      required: false,
      default: "md",
    },

    inline: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    leftPickerColumnClass: {
      type: String,
      required: false,
      default: "",
    },

    rightPickerColumnClass: {
      type: String,
      required: false,
      default: "",
    },
  },

  emits: ["update:start-at", "update:end-at"],

  computed: {
    startTime: {
      get() {
        return this.startAt;
      },

      set(value) {
        // make sure it's dates when comparing
        const startValue = new Date(this.startAt);
        const endValue = new Date(this.endAt);
        const newStartValue = new Date(value);

        // update endValue with startValue + old time range
        if (
          isAfter(newStartValue, endValue) ||
          isEqual(newStartValue, endValue)
        ) {
          const minutesDiff = differenceInMinutes(endValue, startValue);
          let newEndValue = addMinutes(newStartValue, minutesDiff);

          if (minutesDiff === 0) {
            newEndValue = addMinutes(newStartValue, 60);
          }

          if (typeof value == "string") {
            this.$emit("update:end-at", newEndValue.toISOString());
          } else {
            this.$emit("update:end-at", newEndValue);
          }
        }
        this.$emit("update:start-at", value);
      },
    },

    notBeforeStartWithDuration() {
      return addMinutes(new Date(this.startAt), this.minDuration);
    },

    endTime: {
      get() {
        return this.endAt;
      },

      set(value) {
        const startValue = new Date(this.startAt);
        let newValue = new Date(value);

        if (isBefore(newValue, startValue)) {
          newValue = addMinutes(startValue, this.minDuration);
          if (typeof value == "string") {
            return this.$emit("update:end-at", newValue.toISOString());
          } else {
            return this.$emit("update:end-at", newValue);
          }
        }

        this.$emit("update:end-at", value);
      },
    },

    startPickerProps() {
      return {
        ...this.dateTimePickerProps,
        ...this.startDateTimePickerProps,
        min: this.notBeforeNow ? new Date() : null,
        minutesStep: this.minutesStep,
      };
    },

    endPickerProps() {
      return {
        ...this.dateTimePickerProps,
        ...this.endDateTimePickerProps,
        minutesStep: this.minutesStep,
      };
    },
  },
};
</script>
