<template>
  <div class="document-uploader">
    <file-uploader
      :name="name"
      :files="files"
      :aws-url="awsUrl"
      :max-files="maxFiles"
      :accepted-types="acceptedTypes"
      :support-folders="supportFolders"
      :support-select-from-archive="supportSelectFromArchive"
      :validation-error="state === false"
      :manual-save="manualSave"
      :show-files="showFiles"
      attachment-name="uploaded_file"
      @file-added="fileAdded"
      @file-removed="fileRemoved"
      @file-restored="fileRestored"
      @new-empty-folder="handleNewEmptyFolder"
      @uploading="(value) => $emit('uploading', value)"
    >
      <template #button>
        <be-button
          v-be-modal.modal-scrollable="`picker-${$.uid}`"
          variant="primary"
          class="py-0 px-1"
        >
          {{ $t("document_dropzone.select_uploaded") }}
        </be-button>
      </template>
    </file-uploader>

    <document-picker-modal
      :id="`picker-${$.uid}`"
      :is-single-file="maxFiles == 1"
      @archive-document-selected="selectedFromArchive"
    />

    <div v-for="file in destroyedDocuments" :key="file.id">
      <input type="hidden" :name="getRemovedName(file)" value="1" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    documents: {
      default: () => [],
      type: Array,
    },

    htmlForm: {
      type: Boolean,
      required: false,
      default: false,
    },

    name: {
      type: String,
      default: "",
    },

    awsUrl: {
      type: String,
      required: false,
      default: null,
    },

    maxFiles: {
      type: Number,
      default: -1,
    },

    acceptedTypes: {
      type: Array,
      default: () => [],
    },

    supportFolders: {
      type: Boolean,
      default: false,
    },

    supportSelectFromArchive: {
      type: Boolean,
      required: false,
      default: false,
    },

    state: {
      type: Boolean,
      required: false,
      default: null,
    },

    invalidFeedback: {
      type: [Array, String],
      required: false,
      default: "",
    },

    // If true, will show `Will be removed on save` while files are pending removal
    // If false, will show loading indicator
    manualSave: {
      type: Boolean,
      required: false,
      default: true,
    },

    showFiles: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: [
    "uploading",
    "document-added",
    "document-removed",
    "document-restored",
    "new-empty-folder",
  ],

  data() {
    return {
      localDocuments: this.cloneDeep(this.documents),
      uuid: this.generateUuid(),
      localOptions: this.cloneDeep(this.options) || {},
    };
  },

  computed: {
    currentDocuments() {
      if (this.htmlForm) {
        return this.localDocuments;
      } else {
        return this.documents;
      }
    },

    destroyedDocuments() {
      if (this.htmlForm) {
        return this.localDocuments.filter(
          (existingDocument) => existingDocument._destroy == true
        );
      }

      return [];
    },

    files() {
      const files = [];

      for (const item of this.currentDocuments) {
        if (item.uploaded_file) {
          files.push(item.uploaded_file);
        } else if (item.metadata) {
          files.push(item);
        }
      }

      return files;
    },
  },

  methods: {
    fileAdded(file) {
      const currentDocument = this.fileToDocument(file);

      if (this.htmlForm) {
        this.localDocuments.push(currentDocument);
      } else {
        this.$emit("document-added", currentDocument);
      }
    },

    fileRemoved(file) {
      const index = this.findDocumentIndexByFile(this.currentDocuments, file);

      if (index === -1) {
        return;
      }

      if (this.htmlForm) {
        const currentDocument = this.localDocuments[index];

        currentDocument._destroy = true;
        currentDocument.uploaded_file = file;
        // We should remove the document completely if it was never saved.
        if (!currentDocument.id) {
          this.localDocuments.splice(index, 1);
        }
      } else {
        const currentDocument = this.cloneDeep(this.documents[index]);
        currentDocument._destroy = true;
        currentDocument.uploaded_file = file;

        this.$emit("document-removed", currentDocument);
      }
    },

    fileRestored(file) {
      const index = this.findDocumentIndexByFile(this.currentDocuments, file);

      if (index == -1) {
        return;
      }

      if (this.htmlForm) {
        const currentDocument = this.localDocuments[index];
        currentDocument._destroy = false;
        currentDocument.uploaded_file = file;
      } else {
        const currentDocument = this.cloneDeep(this.documents[index]);
        currentDocument._destroy = false;
        currentDocument.uploaded_file = file;

        this.$emit("document-restored", currentDocument);
      }
    },

    fileToDocument(file) {
      if (file.copy_from_id) {
        return file;
      } else {
        let folder_path = "";
        if (this.supportFolders && file.fullPath) {
          folder_path = file.fullPath.replace(this.filename(file), "");
        }
        return {
          file_uuid: file.uuid,
          folder_path: folder_path,
          uploaded_file: file,
        };
      }
    },

    handleNewEmptyFolder(folderPath) {
      this.$emit("new-empty-folder", folderPath);
    },

    selectedFromArchive(documents) {
      documents.forEach((doc) => {
        const file = {
          copy_from_id: doc.id,
          copy_to_attachment: this.attachmentName,
          upload_state: "added",
          metadata: doc.uploaded_file.metadata,
        };

        this.fileAdded(file);
      });
    },

    filename(file) {
      return file?.name || file?.filename || file?.metadata?.filename || "";
    },

    findDocumentIndexByFile(documents, file) {
      return documents.findIndex(
        (existing) =>
          (existing.uploaded_file &&
            ((file.record_id &&
              file.record_id === existing.uploaded_file.record_id) ||
              (file.uuid && file.uuid === existing.uploaded_file.uuid))) ||
          (existing.uploaded_file &&
            ((file.record_id &&
              file.record_id === existing.uploaded_file.record_id) ||
              (file.uuid && file.uuid === existing.uploaded_file.uuid)))
      );
    },

    getRemovedName(currentDocument) {
      const index = this.currentDocuments.findIndex(
        (existing) => existing.id && currentDocument.id === existing.id
      );

      if (index == -1) {
        return;
      }

      return `${this.name}[_destroy]`.replace("_index_", index);
    },
  },
};
</script>
