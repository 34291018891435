<template>
  <be-form-group
    :label="label"
    :label-class="labelClass"
    :description="description"
    :error="errorMessage"
    :state="errorState"
    class="mb-0"
  >
    <div class="row no-gutters">
      <div class="col-12 col-md-6 col-lg-7 mb-2 mb-lg-0 pr-0 pr-md-2">
        <be-form-datepicker
          v-bind="computedDatePickerProps"
          v-model="date"
          :state="errorState"
          :clearable="false"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-5 mb-2 mb-lg-0">
        <be-form-datepicker
          v-bind="computedTimePickerProps"
          v-model="time"
          type="time"
          :state="errorState"
          :clearable="false"
        />
      </div>
    </div>
  </be-form-group>
</template>

<script>
import { isAfter, setMinutes, set as setDateTime } from "date-fns";

export default {
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },

    labelClass: {
      type: String,
      required: false,
      default: null,
    },

    description: {
      type: String,
      required: false,
      default: null,
    },

    minutesStep: {
      type: Number,
      required: false,
      default: 5,
    },

    /**
     * Date is used internally, value updates are ISO UTC string if input is string
     */
    modelValue: {
      type: [Date, String],
      required: false,
      default: () => new Date(),
    },

    /**
     * Do not allow date value before this
     */
    min: {
      type: [Date, String],
      required: false,
      default: null,
    },

    max: {
      type: [Date, String],
      required: false,
      default: null,
    },

    /**
     * Extra props on DatePicker
     */
    datePickerProps: {
      type: Object,
      required: false,
      default: () => {},
    },

    /**
     * Extra props on TimePicker
     */
    timePickerProps: {
      type: Object,
      required: false,
      default: () => {},
    },

    errorMessage: {
      type: String,
      required: false,
      default: null,
    },

    errorState: {
      type: Boolean,
      required: false,
      default: null,
    },
  },

  emits: ["input", "update:modelValue"],

  data() {
    return {
      timeValue: {
        hours: 8,
        minutes: 0,
      },

      isString: false,
    };
  },

  computed: {
    date: {
      get() {
        return new Date(this.modelValue);
      },

      set(newValue) {
        this.updateValue(newValue);
      },
    },

    time: {
      get() {
        return {
          hours: this.date.getHours(),
          minutes: this.date.getMinutes(),
        };
      },

      set(newValue) {
        this.timeValue = newValue;
        this.updateValue(this.date);
      },
    },

    computedDatePickerProps() {
      return {
        minDate: this.minDate,
        maxDate: this.maxDate,
        ...this.datePickerProps,
      };
    },

    computedTimePickerProps() {
      return {
        minutesIncrement: this.minutesStep,
        ...this.timePickerProps,
      };
    },

    maxDate() {
      return this.max ? this.stepDateTime(new Date(this.max)) : null;
    },

    minDate() {
      return this.min ? this.stepDateTime(new Date(this.min)) : null;
    },
  },

  mounted() {
    if (typeof this.modelValue === "string") {
      this.isString = true;
    }

    this.timeValue = {
      hours: this.date.getHours(),
      minutes: this.date.getMinutes(),
    };
  },

  methods: {
    updateValue(newValue) {
      // combine date + time
      const { hours, minutes } = this.timeValue;

      let dateTime = setDateTime(new Date(newValue), {
        hours,
        minutes,
        seconds: 0,
        milliseconds: 0,
      });

      if (this.minDate && isAfter(this.minDate, dateTime)) {
        dateTime = this.minDate;
        this.timeValue = {
          hours: dateTime.getHours(),
          minutes: dateTime.getMinutes(),
        };
      }

      if (this.isString) {
        this.$emit("input", dateTime.toISOString());
        this.$emit("update:modelValue", dateTime.toISOString());
      } else {
        this.$emit("input", dateTime);
        this.$emit("update:modelValue", dateTime);
      }
    },

    stepDateTime(date) {
      let minutes = date.getMinutes();

      if (minutes % this.minutesStep !== 0) {
        minutes = Math.round(minutes / this.minutesStep) * this.minutesStep;
      }
      return setMinutes(date, minutes);
    },
  },
};
</script>
