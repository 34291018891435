<template>
  <component
    :is="component"
    v-bind="computedProps"
    :label="filter.label"
    @change="onChange"
  />
</template>

<script>
import DateFilter from "./filter_types/DateFilter.vue";
import DateRangeFilter from "./filter_types/DateRangeFilter.vue";
import SelectFilter from "./filter_types/SelectFilter.vue";

const VALID_TYPES = ["date", "date-range", "select"];

export default {
  components: {
    DateFilter,
    DateRangeFilter,
    SelectFilter,
  },

  props: {
    filter: {
      type: Object,
      required: true,

      validator: (value) => {
        return VALID_TYPES.includes(value.type);
      },
    },

    items: {
      type: Array,
      required: true,
    },
  },

  emits: ["change"],

  computed: {
    component() {
      if (!this.filter.type) {
        return null;
      }

      switch (this.filter.type) {
        case "date":
          return "DateFilter";

        case "date-range":
          return "DateRangeFilter";

        case "select":
          return "SelectFilter";

        default:
          return null;
      }
    },

    computedProps() {
      switch (this.filter.type) {
        case "select":
          return {
            options: this.selectOptions,
          };

        default:
          return null;
      }
    },

    selectOptions() {
      if (this.filter.type !== "select") {
        return null;
      }

      if (this.filter.options) {
        return [...this.filter.options].sort((a, b) =>
          a.text.localeCompare(b.text)
        );
      }

      return [...new Set(this.items.map((item) => item[this.filter.field]))]
        .map((option) => ({
          text: this.translateOption(option),
          value: option,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
  },

  methods: {
    onChange(value) {
      const filter = this.filter;
      this.$emit("change", { filter, value });
    },

    translateOption(option) {
      return this.filter.translationPath
        ? this.$t(`${this.filter.translationPath}.${option}`)
        : option;
    },
  },
};
</script>
