export const defaultConfirmOptions = {
  title: "Are you sure?",
};

export const defaultRetryOptions = {
  icon: "error",
  showCloseButton: true,
  showCancelButton: true,
};

export const defaultOptions = {
  /** User must confirm request */
  confirm: false,
  confirmMessage: "Are you sure?",

  /** Show retry dialog */
  retry: true,
  /** Message to display in retry dialog */
  retryMessage: "Try again?",
  retryIcon: "error",
};

/* Typedef of error types that can be returned by RequestHandler */
export const RequestHandlerError = {
  /** User pressed cancel */
  USER_CANCEL: "user-cancel",
  /** Network error. request was made but bad response */
  NETWORK_ERROR: "network-error",
  /** Bad request (422) */
  BAD_REQUEST: "bad-request",
  /** User skipped retry */
  RETRY_CANCEL: "retry-cancel",
};

/**
 * Initiates network requests with optional retry and confirmation dialogs.
 * The `makeRequest` function supports both async/await and promise-based syntax for handling responses and errors.
 *
 * @param {Object} request - The request configuration object, including method, url, and optional data.
 * @param {Object} [options] - Optional settings for the request such as retry and confirmation dialogs.
 * @param {boolean} [options.retry=false] - If true, retries the request upon failure.
 * @param {boolean} [options.confirm=false] - If true, shows a confirmation dialog before making the request.
 * @param {boolean} [options.confirmRemoval=false] - If true, shows a removal confirmation dialog before making the request.
 * @param {string} [options.retryMessage=''] - Message to display in the retry confirmation dialog.
 *
 * @returns {Promise<Object>} A promise that resolves with the result of the request or rejects with an error object.
 *
 * @example
 * // Using async/await:
 * try {
 *   const request = { method: 'get', url: 'https://example.com', data: {...} };
 *   const options = { retry: true, confirm: true, retryMessage: 'Please confirm to retry.' };
 *   const result = await this.makeRequest(request, options);
 *   // Process result here
 * } catch (error) {
 *   const { errorType, details } = error;
 *   switch (errorType) {
 *     case RequestHandlerError.USER_CANCEL:
 *       // Handle user cancellation (request not made)
 *       break;
 *     case RequestHandlerError.RETRY_CANCEL:
 *       // Handle user opting out of retry
 *       break;
 *     case RequestHandlerError.NETWORK_ERROR:
 *       // Handle network errors (request made but failed)
 *       break;
 *     default:
 *       // Handle other errors
 *   }
 * }
 *
 * @example
 * // Using promises:
 * const request = { method: 'post', url: 'https://example.com/api', data: {...} };
 * this.makeRequest(request, { retry: true, confirm: false })
 *   .then(result => {
 *     // Process result here
 *   })
 *   .catch(error => {
 *     const { errorType, details } = error;
 *     switch (errorType) {
 *       case RequestHandlerError.USER_CANCEL:
 *         // Handle user cancellation
 *         break;
 *       case RequestHandlerError.RETRY_CANCEL:
 *         // Handle retry cancellation
 *         break;
 *       case RequestHandlerError.NETWORK_ERROR:
 *         // Handle network error
 *         break;
 *       default:
 *         // Handle other errors
 *     }
 *   });
 */
export const RequestHandler = {
  created() {
    defaultOptions.confirmMessage = this.$t("buttons.prompts.confirm");
    defaultOptions.retryMessage = this.$t("users.u2f.errors.unknown"); // change!
  },

  methods: {
    /**
     * Make a confirmable/retryable network request using axios
     * @param {Object} request - Axios request configuration
     * @param {Object} [dialogOptions=defaultOptions] - Options for confirmation and retry dialogs
     * @example
     * { confirm: true, retry: false, confirmMessage: "", retryMessage: "" }
     * @default retry: true, confirm: false. generic translations are used.
     * @returns {Promise<Object>} - Resolves with Axios response or rejects with an error object
     */
    async makeRequest(request, dialogOptions = defaultOptions) {
      const options = {
        ...defaultOptions,
        ...dialogOptions,
      };

      const confirmOptions = {
        ...defaultConfirmOptions,
        title: options.confirmMessage,
        description: options.description || "",

        confirmButtonText:
          options.confirmButtonText || this.$t("buttons.titles.ok"),
      };

      const retryOptions = {
        ...defaultConfirmOptions,
        title: options.retryMessage,
      };

      try {
        // Handle confirmation dialog if needed
        if (dialogOptions.confirm || dialogOptions.confirmRemoval) {
          let isConfirmed = false;

          if (dialogOptions.confirm) {
            isConfirmed = await this.promptConfirm(confirmOptions);
          } else if (dialogOptions.confirmRemoval) {
            isConfirmed = await this.promptRemovalConfirm(confirmOptions);
          }

          if (!isConfirmed) {
            throw {
              errorType: RequestHandlerError.USER_CANCEL,
              error: {},
            };
          }
        }

        // Send the actual request
        return await this.sendRequest(
          request,
          options,
          retryOptions,
          dialogOptions
        );
      } catch (error) {
        if (error.errorType) {
          throw error;
        }

        if (error.response && error.response.status === 422) {
          throw {
            errorType: RequestHandlerError.BAD_REQUEST,
            ...error,
          };
        }

        if (options.retry) {
          try {
            await this.showRetryDialog(retryOptions);

            return await this.makeRequest(request, {
              ...dialogOptions,
              confirm: false, // Do not confirm on second try.
              confirmRemoval: false, // Do not confirm on second try.
            });
          } catch (retryError) {
            throw {
              errorType: RequestHandlerError.RETRY_CANCEL,
              ...error,
            };
          }
        } else {
          throw {
            errorType: RequestHandlerError.NETWORK_ERROR,
            ...error,
          };
        }
      }
    },

    /**
     * Send a network request using axios and handle retries
     * @param {Object} request - Axios request configuration
     * @param {Object} options - Combined default and dialog options
     * @param {Object} retryOptions - Options for retry dialog
     * @param {Object} dialogOptions - Original dialog options
     * @returns {Promise<Object>} - Resolves with Axios response or rejects with an error object
     */
    async sendRequest(request, options, retryOptions, dialogOptions) {
      try {
        // Log the request configuration for debugging
        console.log("Sending request with config:", request);
        const result = await axios.request(request);
        return result;
      } catch (error) {
        if (error.response && error.response.status === 422) {
          throw {
            errorType: RequestHandlerError.BAD_REQUEST,
            ...error,
          };
        }

        if (options.retry) {
          try {
            await this.showRetryDialog(retryOptions);

            return await this.makeRequest(request, {
              ...dialogOptions,
              confirm: false, // Do not confirm on second try.
              confirmRemoval: false, // Do not confirm on second try.
            });
          } catch (retryError) {
            throw {
              errorType: RequestHandlerError.RETRY_CANCEL,
              ...error,
            };
          }
        } else {
          throw {
            errorType: RequestHandlerError.NETWORK_ERROR,
            ...error,
          };
        }
      }
    },

    /**
     *
     * @param {Object} confirmOptions - Options for the confirmation dialog
     * @returns {Promise<void>} - Resolves if user confirms, rejects if user cancels
     */
    async showRetryDialog(confirmOptions) {
      const isConfirmed = await this.promptConfirm(confirmOptions);

      if (isConfirmed) {
        return Promise.resolve();
      } else {
        return Promise.reject({
          errorType: RequestHandlerError.RETRY_CANCEL,
        });
      }
    },
  },
};

export default RequestHandler;
