import Quill from "quill";

const Inline = Quill.import("blots/inline");

class Phrase extends Inline {
  static blotName = "phrase";
  static tagName = "span";
}

export default Phrase;
// Dummy format just to have something to add to the editor.
