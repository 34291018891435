<template>
  <div>
    <job-callback
      v-if="callback.id"
      :key="callbackKey"
      :callback="callback"
      @update="callbackUpdated"
    >
      <template #failed>
        <be-alert variant="danger" class="mb-3">
          {{
            $t("components.financials.corporate_groups.upgrade_button.failed")
          }}
        </be-alert>
      </template>

      <template #timeout>
        <be-alert variant="danger" class="mb-3">
          {{
            $t("components.financials.corporate_groups.upgrade_button.failed")
          }}
        </be-alert>
      </template>

      <template #completed>
        <be-alert variant="success" class="mb-3">
          {{
            $t(
              "components.financials.corporate_groups.upgrade_button.completed"
            )
          }}
        </be-alert>
      </template>
    </job-callback>

    <be-button
      v-if="callback.status === 'completed'"
      :href="url('/corporate_groups/consolidation')"
      variant="primary"
    >
      {{ $t("buttons.titles.continue") }}
    </be-button>

    <be-button
      v-else
      :loading="['in_progress', 'initial'].includes(callback.status)"
      @click="upgrade"
    >
      {{
        $t(
          "views.companies.corporate_groups.consolidations.introduction.upgrade"
        )
      }}
    </be-button>
  </div>
</template>

<script>
export default {
  props: {
    jobCallback: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      callback: this.cloneDeep(this.jobCallback),
      callbackKey: this.generateUuid(),
    };
  },

  methods: {
    async upgrade() {
      try {
        const { data } = await axios.patch(
          this.url("/corporate_groups/consolidation")
        );

        this.callback = data;
        this.callbackKey = this.generateUuid();
      } catch (error) {
        this.handleError(error);
      }
    },

    callbackUpdated(callback) {
      this.callback = callback;
    },
  },
};
</script>
