<template>
  <div v-if="enabled">
    <be-list-group-item
      :id="`${id}-link`"
      v-be-tooltip="{
        id: `${id}-tooltip`,
        title: text,
        placement: 'right',
        disabled: !collapsed || (link.children && link.children.length > 0),
      }"
      v-be-toggle="id"
      :href="href"
      :active="isActive"
      :class="[{ 'px-4 justify-content-center': collapsed }]"
    >
      <i v-if="icon" :class="[`fal fa-fw ${icon}`, { 'mr-0': collapsed }]" />

      <navigation-link-badge
        v-if="collapsed && showBadge"
        :badge="link.badge"
        :collapsed="collapsed"
        :class="[
          'd-flex align-items-center',
          { 'position-absolute': collapsed },
        ]"
      />

      <template v-if="!collapsed">
        {{ text }}

        <div class="d-flex flex-grow-1 align-items-center justify-content-end">
          <navigation-link-badge
            v-if="showBadge"
            :badge="link.badge"
            :collapsed="collapsed"
            class="d-inline-block"
          />

          <i
            v-if="link.children && enabledChildren.length > 1"
            class="fal fa-fw fa-angle-right mx-0"
          />
        </div>
      </template>
    </be-list-group-item>

    <template v-if="link.children && enabledChildren.length > 1">
      <be-collapse
        v-if="!collapsed"
        :id="id"
        class="flex-column"
        :visible="isActive"
      >
        <be-nav vertical>
          <navigation-child-link
            v-for="(child, i) in link.children"
            :key="i"
            :link="child"
            :collapsed="collapsed"
            :policies="policies"
          />
        </be-nav>
      </be-collapse>

      <be-popover
        v-else
        :target="`${id}-link`"
        trigger="hover focus"
        placement="right"
      >
        <template #title>
          <i :class="`fal fa-fw ${link.icon} mr-1`" />
          {{ text }}
        </template>

        <be-nav vertical>
          <navigation-child-link
            v-for="(child, i) in link.children"
            :key="i"
            :link="child"
            :collapsed="collapsed"
            :policies="policies"
          />
        </be-nav>
      </be-popover>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import menuUtilities from "@/mixins/menuUtilities";

import NavigationChildLink from "./NavigationChildLink.vue";
import NavigationLinkBadge from "./NavigationLinkBadge.vue";

function isFunction(value) {
  return value !== undefined && typeof value === "function";
}

export default {
  components: {
    NavigationChildLink,
    NavigationLinkBadge,
  },

  mixins: [menuUtilities],

  inject: ["action", "controller"],

  props: {
    link: {
      type: Object,
      required: true,
    },

    collapsed: {
      type: Boolean,
      required: true,
    },

    policies: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      id: this.generateUuid(),
    };
  },

  computed: {
    ...mapGetters("company", ["hasActiveFeature", "hasCorporateGroup"]),

    enabled() {
      if (isFunction(this.link.enabled)) {
        return this.link.enabled(this);
      } else if (this.link.children) {
        return this.isLinkEnabledBasedOnChildren();
      } else {
        return true;
      }
    },

    hasActiveCorporateGroup() {
      return (
        this.platformEnabledAndSubscribed(
          "economy-corporate-group-consolidation"
        ) && this.hasCorporateGroup
      );
    },

    href() {
      let url, absoluteUrl;

      if (this.enabledChildren.length === 1) {
        url = this.convertFunctionToValue(this.enabledChildren[0].url);
        absoluteUrl = this.enabledChildren[0].absoluteUrl;
      } else {
        if (this.link.url === "#") {
          return null;
        }

        url = this.convertFunctionToValue(this.link.url);
        absoluteUrl = this.link.absoluteUrl;
      }

      if (absoluteUrl) {
        return url;
      } else {
        return this.url(url);
      }
    },

    // Either active if it matches the link.active function or if any of the children are active
    isActive() {
      return (
        (isFunction(this.link.active) && this.link.active(this)) ||
        (this.link.children && this.isLinkActiveBasedOnChildren())
      );
    },

    sbsManagerLink() {
      return this.$platform?.sbs_manager_link;
    },

    showBadge() {
      return this.link.badge;
    },

    text() {
      if (
        this.enabledChildren.length === 1 &&
        this.link.promoteSingleChildText
      ) {
        return this.convertFunctionToValue(this.enabledChildren[0].text);
      }

      return this.convertFunctionToValue(this.link.text);
    },

    icon() {
      if (
        this.enabledChildren.length === 1 &&
        this.enabledChildren[0].promotedIcon
      ) {
        return this.convertFunctionToValue(
          this.enabledChildren[0].promotedIcon
        );
      }

      return this.convertFunctionToValue(this.link.icon);
    },

    enabledChildren() {
      return (
        this.link.children?.filter((child) => {
          return isFunction(child.enabled) ? child.enabled(this) : true;
        }) || []
      );
    },
  },

  methods: {
    isLinkEnabledBasedOnChildren() {
      return this.link.children.some((child) => {
        return isFunction(child.enabled) ? child.enabled(this) : true;
      });
    },

    isLinkActiveBasedOnChildren() {
      return this.link.children.some((child) => {
        return isFunction(child.active) ? child.active(this) : false;
      });
    },
  },
};
</script>
