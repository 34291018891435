<template>
  <be-table-simple :class="{ 'border-spacing-0': depth > 0 }">
    <tbody>
      <tr>
        <td class="shrink-column pr-0" :class="{ 'border-0': depth > 0 }">
          <i
            class="fal fa-fw"
            :class="opened ? 'fa-folder-open' : 'fa-folder'"
          />
        </td>

        <td :class="{ 'border-0': depth > 0 }">
          <be-link @click="opened = !opened">
            {{ localFolder.title }}
          </be-link>
        </td>

        <td />

        <td />
      </tr>

      <tr
        v-if="
          activeFolder.children && activeFolder.children.length > 0 && opened
        "
      >
        <td colspan="4" class="p-0 pl-3">
          <folder-list
            v-for="(childFolder, index) in activeFolder.children"
            :key="childFolder.id"
            :folder="localFolder.children[index]"
            :depth="depth + 1"
            :editable="editable"
            :opened="false"
            is-sub-folder
            @update:folder="folderUpdated"
            @file-delete="onSubFolderFileDelete"
          />
        </td>
      </tr>

      <template v-if="activeFolder.documents.length > 0 && opened">
        <tr
          v-for="doc in activeFolder.documents"
          :key="doc.id"
          :class="{ 'pl-2': depth > 0, 'alert-warning': isFreshDocument(doc) }"
        >
          <td>
            <i :class="`fal ${getFileIconClass(doc.filename || doc.title)}`" />
          </td>

          <td>
            <document-link
              :company-id="ownerId"
              :document-id="doc.id"
              :filename="doc.filename"
              :show-icon="false"
              :title="doc.title"
            />
          </td>

          <td class="col-shrink text-right">
            {{ showFileSize ? doc.file_size : "" }}
          </td>

          <td v-if="canDeleteDocument(doc)" class="text-right shrink-column">
            <be-button
              v-be-tooltip="$t('buttons.titles.remove')"
              variant="danger"
              size="sm"
              icon="fa-times"
              inline
              @click="deleteDocument(doc)"
            />
          </td>

          <td v-else />
        </tr>
      </template>

      <tr v-if="editable && activeFolder.policy.update && opened">
        <td colspan="4">
          <document-uploader
            :name="`folder_documents_${localFolder.id}`"
            @documents-updated="onUpdatedDocuments"
            @uploading="(value) => (loading = value)"
          />

          <be-button
            v-if="dirty"
            variant="primary"
            :loading="loading"
            @click="onSave"
          >
            {{ $t("buttons.titles.save") }}
          </be-button>
        </td>
      </tr>
    </tbody>
  </be-table-simple>
</template>

<script>
import { sub, isAfter } from "date-fns";
import without from "lodash/without";

import RequestHandler from "@/mixins/RequestHandler";
import { getClassNameForFilename } from "@/vendor/font-awesome-filetypes";

export default {
  name: "FolderList",
  mixins: [RequestHandler],

  props: {
    folder: {
      type: Object,
      required: true,
    },

    depth: {
      type: Number,
      required: false,
      default: 0,
    },

    initiallyOpened: {
      type: Boolean,
      required: false,
      default: false,
    },

    editable: {
      type: Boolean,
      default: false,
      required: false,
    },

    showFileSize: {
      type: Boolean,
      default: true,
      required: false,
    },

    isSubFolder: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  emits: ["update:folder", "file-delete"],

  data() {
    return {
      localFolder: this.cloneDeep(this.folder),
      opened: this.initiallyOpened,
      dirty: false,
      loading: false,
      documents: [],
    };
  },

  computed: {
    ownerId() {
      return this.localFolder?.owner_id;
    },

    activeFolder: {
      get() {
        return this.localFolder;
      },

      set(newValue) {
        this.$emit("update:folder", newValue);
      },
    },

    getFolderKey() {
      return `${this.activeFolder.id}_${this.activeFolder.documents.length}`;
    },
  },

  mounted() {
    // only subscribe once otherwise it will create duplicates
    if (!this.isSubFolder) {
      this.$cable.subscribe(
        {
          channel: "Companies::DocumentsChannel",
          company_id: this.$currentCompany.nanoid,
        },
        "CompaniesDocumentsChannel"
      );
    }
  },

  methods: {
    onUpdatedDocuments(documents) {
      this.dirty = documents.length > 0;
      this.documents = documents;
    },

    onSubFolderFileDelete(document) {
      if (this.isSubFolder) {
        // propagate up the tree
        this.$emit("file-delete", document);
      } else {
        this.activeFolder.subtree_documents =
          this.activeFolder.subtree_documents.filter(
            (doc) => doc.id !== document.id
          );
        this.$emit("update:folder", this.activeFolder);
      }
    },

    canDeleteDocument(document) {
      return this.editable && document.destroyable && document.policy.destroy;
    },

    isFreshDocument(document) {
      const timeStamp = sub(new Date(), {
        minutes: this.$config.MEETING.MATERIAL.FRESH_DOCUMENT_PERIOD,
      });
      return isAfter(new Date(document.created_at), timeStamp);
    },

    async deleteDocument(document) {
      try {
        this.loading = true;
        await this.makeRequest(
          {
            method: "delete",
            url: this.url(`/documents/${document.id}`),
          },
          {
            confirmRemoval: true,

            confirmMessage: this.$t("nav.confirm_delete_w_title", {
              title: document.title,
            }),
          }
        );

        this.activeFolder.documents = without(
          this.activeFolder.documents,
          document
        );

        if (!this.isSubFolder) {
          this.activeFolder.subtree_documents =
            this.activeFolder.subtree_documents.filter(
              (doc) => doc.id !== document.id
            );
        } else {
          // propagate the delete up the folder tree
          this.$emit("file-delete", document);
        }
        this.$emit("update:folder", this.activeFolder);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    async onSave() {
      try {
        this.loading = true;

        const documentsForm = {
          documents_attributes: {},
          folder_id: this.activeFolder.id,
        };
        this.documents.forEach((document, index) => {
          documentsForm.documents_attributes[index] = document;
        });

        await this.makeRequest({
          method: "post",
          url: this.url("/documents"),
          data: { documents_form: documentsForm },
        });
        this.documents = [];

        this.dirty = false;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    getFileIconClass(filename) {
      return getClassNameForFilename(filename);
    },

    folderUpdated(folder) {
      const index = this.localFolder.children.findIndex(
        (child) => child.id && child.id === folder.id
      );

      if (index > -1) {
        this.localFolder.children[index] = folder;
      }
    },
  },

  channels: {
    CompaniesDocumentsChannel: {
      connected() {},
      rejected() {},

      received(response) {
        const { folder, document } = response;
        let changed = false;

        if (folder && folder.parent_id === this.activeFolder.id) {
          // When multiple folder lists exist, this will be called multiple times.
          // Make sure it does not create duplicates
          if (
            !this.activeFolder.folders.filter(
              (existing) => existing.id === folder.id
            ).length > 0
          ) {
            this.activeFolder.folders = [...this.activeFolder.folders, folder];
            changed = true;
          }
        }

        if (document && document.folder_id === this.activeFolder.id) {
          // When multiple folder lists exist, this will be called multiple times.
          // Make sure it does not create duplicates
          if (
            !this.activeFolder.documents.filter((doc) => doc.id === document.id)
              .length > 0
          ) {
            this.activeFolder.documents = [
              ...this.activeFolder.documents,
              document,
            ];
            changed = true;
          }
        }

        if (!this.isSubFolder) {
          this.activeFolder.subtree_documents = [
            ...this.activeFolder.subtree_documents,
            document,
          ];
          changed = true;
        }
        if (changed) {
          this.$emit("update:folder", this.activeFolder);
        }
      },

      disconnected() {},
    },
  },
};
</script>
