<template>
  <component :is="tag" :class="computedClasses">
    <slot />
  </component>
</template>

<script>
export default {
  name: "BeListGroup",

  props: {
    flush: {
      type: Boolean,
      required: false,
      default: false,
    },

    horizontal: {
      type: [Boolean, String],
      required: false,
      default: false,
    },

    tag: {
      type: String,
      required: false,
      default: "div",
    },
  },

  computed: {
    computedHorizontal() {
      if (this.flush) {
        return false;
      } else if (this.horizontal === "") {
        return true;
      } else {
        return this.horizontal;
      }
    },

    computedClasses() {
      const { computedHorizontal, flush } = this;

      return [
        "list-group",
        {
          "list-group-flush": flush,
          "list-group-horizontal": computedHorizontal === true,

          [`list-group-horizontal-${computedHorizontal}`]:
            computedHorizontal && typeof computedHorizontal === "string",
        },
      ];
    },
  },
};
</script>
