function parsePagination(headers) {
  return {
    currentPage: parseInt(headers["current-page"]) || 1,
    pageItems: parseInt(headers["page-items"]) || 20,
    totalCount: parseInt(headers["total-count"]) || 0,
    totalPages: parseInt(headers["total-pages"]) || 0,
  };
}

export { parsePagination };
