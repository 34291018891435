<template>
  <div :class="['be-accordion', computedClasses]">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    flush: {
      type: Boolean,
      required: false,
      default: false,
    },

    iconLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return {
        flush: this.flush,
      };
    },
  },
};
</script>
