<template>
  <file-uploader
    :name="name"
    :attachment-name="attachmentName"
    :files="files"
    :max-files="1"
    :accepted-types="acceptedTypes || []"
    :aws-url="awsUrl"
    @file-removed="removeFile"
    @file-restored="restoreFile"
    @file-added="addedFile"
    @uploading="(value) => $emit('uploading', value)"
  />
</template>

<script>
import FileUploader from "@/components/file_uploader/FileUploader.vue";

export default {
  components: {
    FileUploader,
  },

  props: {
    name: {
      type: String,
      required: false,
      default: "file",
    },

    attachmentName: {
      type: String,
      required: false,
      default: "file",
    },

    file: {
      type: Object,
      required: true,
    },

    htmlForm: {
      type: Boolean,
      required: false,
      default: false,
    },

    remove: {
      type: Boolean,
      default: false,
    },

    awsUrl: {
      type: String,
      required: false,
      default: null,
    },

    acceptedTypes: {
      type: Array,
      required: false,
      default: null,
    },
  },

  emits: ["file-updated", "file-removed", "uploading", "update:remove"],

  data() {
    if (this.remove) {
      return {
        localFile: {},
        removedFile: this.cloneDeep(this.file),
      };
    } else {
      return {
        localFile: this.cloneDeep(this.file),
        removedFile: {},
      };
    }
  },

  computed: {
    files() {
      if (Object.keys(this.localFile).length > 0) {
        return [this.localFile];
      } else if (Object.keys(this.removedFile).length > 0) {
        return [this.removedFile];
      } else {
        return [];
      }
    },

    localRemove: {
      get() {
        return this.remove;
      },

      set(value) {
        this.$emit("update:remove", value);
      },
    },
  },

  watch: {
    file(value) {
      if (this.htmlForm) {
        return;
      }

      if (Object.keys(value).length === 0) {
        this.localFile = {};
        this.removedFile = {};
      } else if (this.remove) {
        this.removedFile = this.cloneDeep(value);
        this.localFile = {};
      } else {
        this.localFile = this.cloneDeep(value);
        if (this.localFile && this.localFile.upload_state === "saved") {
          this.removedFile = {};
        }
      }
    },
  },

  methods: {
    addedFile(file) {
      if (file.upload_state !== "removed") {
        if (this.htmlForm) {
          this.localFile = file;
        } else {
          this.$emit("file-updated", file);
        }
        this.localRemove = false;
      }
    },

    removeFile(file) {
      if (file.upload_state !== "added") {
        this.removedFile = file;
      }

      this.localRemove = true;
      this.localFile = {};
      this.$emit("file-removed");
    },

    // Only file that was originally saved can be restored,
    // therefore we should clear current removedFile.
    restoreFile(file) {
      this.localFile = file;
      this.localRemove = false;
      this.removedFile = {};
      this.$emit("file-updated", file);
    },
  },
};
</script>
