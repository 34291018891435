<template>
  <div>
    <div class="ms-container mb-2">
      <select
        v-model="selectedIds"
        :name="name"
        class="d-none"
        multiple
        @change="$emit('change', $event)"
      >
        <optgroup
          v-for="(optgroup, groupIndex) in items"
          :key="groupIndex"
          :label="optgroup[0]"
        >
          <option
            v-for="(item, index) in optgroup[1]"
            :key="index"
            :value="item[keySelector]"
          >
            {{ item[valueSelector] }}
          </option>
        </optgroup>
      </select>

      <div class="ms-selectable">
        <div class="mb-2 font-weight-semibold">
          {{ $t("components.shared.multi_select.available") }}
          ({{ unselectedItems.length }})
        </div>

        <div class="border rounded mb-2">
          <be-input-group>
            <be-input-group-prepend>
              <be-input-group-text
                class="bg-transparent pr-0 border-0 border-bottom rounded-0"
              >
                <i class="search-icon fal fa-search" />
              </be-input-group-text>
            </be-input-group-prepend>

            <be-form-input
              v-model="search.unselected"
              type="search"
              class="border-0 border-bottom rounded-0"
              :placeholder="searchPlaceholder"
              @keyup="searchList('unselected')"
            />
          </be-input-group>

          <div class="ms-list">
            <grouped-optgroup
              v-for="(optgroup, index) in unselectedItems"
              :key="'selectedItems-' + index"
              list-name="unselected"
              :optgroup="optgroup"
              :value-selector="valueSelector"
              @selected-item="selectItem"
              @selected-group="selectGroup"
            />
          </div>
        </div>
      </div>

      <div class="ms-selection">
        <div class="mb-2 font-weight-semibold">
          {{ $t("components.shared.multi_select.selected") }}
          ({{ selectedItems.length }})
        </div>

        <div
          :class="['border rounded mb-2', { 'border-danger': state === false }]"
        >
          <be-input-group>
            <be-input-group-prepend>
              <be-input-group-text
                class="bg-transparent pr-0 border-0 border-bottom rounded-0"
              >
                <i class="search-icon fal fa-search" />
              </be-input-group-text>
            </be-input-group-prepend>

            <be-form-input
              v-model="search.selected"
              type="search"
              class="border-0 border-bottom rounded-0"
              :placeholder="searchPlaceholder"
              @keyup="searchList('selected')"
            />
          </be-input-group>

          <div :class="['ms-list', { 'border-danger': state === false }]">
            <grouped-optgroup
              v-for="(optgroup, index) in selectedItems"
              :key="'unselectedItems-' + index"
              list-name="selected"
              :optgroup="optgroup"
              :value-selector="valueSelector"
              @selected-item="selectItem"
              @selected-group="selectGroup"
            />
          </div>
        </div>

        <be-form-invalid-feedback :state="state">
          {{ invalidFeedback }}
        </be-form-invalid-feedback>
      </div>
    </div>

    <selection-row @select-all="selectAll" @deselect-all="deselectAll" />
  </div>
</template>

<script>
import SelectionRow from "../SelectionRow.vue";
import GroupedOptgroup from "./GroupedOptgroup.vue";

export default {
  components: {
    SelectionRow,
    GroupedOptgroup,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    preselectedIds: {
      type: Array,
      required: false,
      default: () => [],
    },

    keySelector: {
      type: String,
      required: false,
      default: "id",
    },

    valueSelector: {
      type: String,
      required: true,
    },

    state: {
      type: Boolean,
      required: false,
      default: null,
    },

    invalidFeedback: {
      type: String,
      required: false,
      default: "",
    },
  },

  emits: ["change"],

  data() {
    return {
      selectedIds: this.preselectedIds || [],

      searchPlaceholder: this.$i18n.t(
        "components.shared.be_table.type_to_search"
      ),

      search: {
        selected: "",
        unselected: "",

        options: {
          id: "id",
          keys: ["name"],
          threshold: 0.3,
          distance: 100,
        },
      },

      searchResults: {
        selected: [],
        unselected: [],
      },
    };
  },

  computed: {
    selectedItems() {
      return this.filterItems("selected");
    },

    unselectedItems() {
      return this.filterItems("unselected");
    },
  },

  watch: {
    preselectedIds: {
      handler(value) {
        this.selectedIds = value;
      },

      deep: true,
    },
  },

  methods: {
    /**
     * Callback from the v-optgroup element whenever auser clicks on the item
     * @param  {[type]} data Object of event data
     */
    selectItem(itemId) {
      let idx = this.selectedIds.findIndex((i) => i == itemId);

      if (idx > -1) {
        // Remove from selected
        this.selectedIds.splice(idx, 1);
      } else {
        // Add to selected
        this.selectedIds.push(itemId);
      }

      this.$emit("change", this.selectedIds);
    },

    /**
     * Callback from the v-optgroup element whenever the user clicks on the optgroup title
     * @param  {[type]} data Object of event data
     */
    selectGroup(data) {
      let group = this.items.find((g) => g.id == data.groupId);

      if (!group) {
        console.warn("Unable to find group with id", data.groupId);
        return false;
      }

      if (data.listName == "unselected") {
        // Select all unselected
        group[1].forEach((item) => {
          if (!this.selectedIds.includes(item.id)) {
            this.selectedIds.push(item.id);
          }
        });
      } else {
        // Deselect all selected
        group[1].forEach((item) => {
          let idx = this.selectedIds.findIndex((i) => i == item.id);
          if (idx > -1) {
            this.selectedIds.splice(idx, 1);
          }
        });
      }
    },

    /**
     * Search the list for products with the given search term
     * @param  {[type]} list Which list to search from. unselectedItems or selectedItems
     */
    searchList(list) {
      let searchList = (searchList = JSON.parse(JSON.stringify(this.items))
        .map((k) => k[1])
        .reduce((a, b) => {
          return a.concat(b);
        }, []));

      if (list == "selected") {
        searchList = searchList.filter((i) => this.selectedIds.includes(i.id));
      }

      // Initialize search
      this.$search(this.search[list], searchList, this.search.options).then(
        (ids) => {
          this.searchResults[list] = ids.map((i) => Number(i));
        }
      );
    },

    /**
     * Filter the items to display
     * @param  {[string]} column selected/unselected
     * @return {[array]} Array of filtered items to display
     */
    filterItems(column) {
      let shouldSelect = column == "selected";
      let results = JSON.parse(JSON.stringify(this.items));

      // Remove all non-selected items
      results.forEach((group, index) => {
        results[index][1] = group[1].filter((i) => {
          if (this.search[column].length > 0) {
            return this.searchResults[column].includes(i.id);
          }

          return this.selectedIds.includes(i.id) == shouldSelect;
        });
      });

      // Remove all empty groups
      return results.filter((group) => group[1].length);
    },

    selectAll() {
      this.selectedIds = this.items
        .map((itemGroup) => itemGroup[1].map((item) => item.id))
        .flat();
      this.$emit("change", this.selectedIds);
    },

    deselectAll() {
      this.selectedIds = [];
      this.$emit("change", this.selectedIds);
    },
  },
};
</script>
