<template>
  <div
    :id="id"
    class="d-flex"
    :class="{ 'flex-column flex-md-row': responsive }"
  >
    <label
      v-for="(option, index) in options"
      :key="index"
      :for="`be-radio-${name}-${index}`"
      class="form-check border m-0 d-flex flex-even align-items-center cursor-pointer"
      :class="[
        labelClass,
        selectedClasses(option),
        hideAdjacentBorderClasses(option, index),
        roundedBorderClasses(index),
      ]"
    >
      <input
        :id="`be-radio-${name}-${index}`"
        v-model="selected"
        type="radio"
        :name="name"
        class="form-check-input position-static m-0"
        :value="option.value"
        :disabled="option.disabled"
        @change="$emit('change', option.value)"
      />

      <span class="form-check-label ml-2 mr-3 text-body" :class="textClass">
        {{ option.text }}
      </span>

      <i
        v-if="option.icon"
        :class="[
          option.icon,
          `text-${option.variant || 'primary'}`,
          'ml-auto fa-fw',
        ]"
      />
    </label>
  </div>
</template>

<script>
import { generateId } from "@/utils/id";

export default {
  name: "BeFormRadioGroupAlternative",

  props: {
    labelClass: {
      type: String,
      required: false,
      default: "p-3",
    },

    id: {
      type: String,
      required: false,
      default: () => generateId(),
    },

    modelValue: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: false,
      default: null,
    },

    options: {
      type: Array,
      required: true,
    },

    responsive: {
      type: Boolean,
      required: false,
      default: true,
    },

    textClass: {
      type: String,
      required: false,
      default: "",
    },
  },

  emits: ["change", "input", "update:modelValue"],

  data() {
    return {
      selected: this.modelValue,
    };
  },

  watch: {
    modelValue(value) {
      this.selected = value;
    },

    selected(value) {
      this.$emit("input", value);
      this.$emit("update:modelValue", value);
    },
  },

  methods: {
    selectedClasses(option) {
      if (option.value === this.selected) {
        return `border-${option.variant || "primary"} bg-${
          option.variant || "primary"
        }-light`;
      } else {
        return null;
      }
    },

    hideAdjacentBorderClasses(option, index) {
      let classes = [];

      if (option.value !== this.selected) {
        if (index !== this.options.length - 1) {
          if (this.responsive) {
            classes.push("border-md-right-0");
            classes.push("border-bottom-0");
            classes.push("border-md-bottom");
          } else {
            classes.push("border-right-0");
          }
        }

        if (index !== 0 && this.indexOfSelectedValue() === index - 1) {
          if (this.responsive) {
            classes.push("border-md-left-0");
            classes.push("border-top-0");
            classes.push("border-md-top");
          } else {
            classes.push("border-left-0");
          }
        }
      }

      return classes.join(" ");
    },

    roundedBorderClasses(index) {
      let classes = [];

      if (index === 0) {
        if (this.responsive) {
          classes.push("rounded-md-left");
          classes.push("rounded-top");
          classes.push("rounded-md-right-0");
        } else {
          classes.push("rounded-left");
        }
      }

      if (index === this.options.length - 1) {
        if (this.responsive) {
          classes.push("rounded-md-right");
          classes.push("rounded-bottom");
          classes.push("rounded-md-left-0");
        } else {
          classes.push("rounded-right");
        }
      }

      return classes.join(" ");
    },

    indexOfSelectedValue() {
      return this.options.findIndex((option) => option.value === this.selected);
    },
  },
};
</script>
