<template>
  <div>
    <div class="ms-container mb-2">
      <select v-model="selectedIds" :name="name" class="d-none" multiple>
        <option
          v-for="item in items"
          :key="item[keySelector]"
          :value="item[keySelector]"
        >
          {{ valueLabel(item) }}
        </option>
      </select>

      <input v-if="!selectedIds.length" type="hidden" :name="name" value="" />

      <input
        v-if="selectedIds.length"
        type="hidden"
        :name="name"
        :value="selectedIds"
      />

      <div class="ms-selectable">
        <div class="mb-2 font-weight-semibold">
          {{ $t("components.shared.multi_select.available") }}
          ({{ unselectedItems.length }})
        </div>

        <div class="border rounded mb-2">
          <be-input-group>
            <be-input-group-prepend>
              <be-input-group-text
                class="bg-transparent pr-0 border-0 border-bottom rounded-0"
              >
                <i class="search-icon fal fa-search" />
              </be-input-group-text>
            </be-input-group-prepend>

            <be-form-input
              v-model="search.unselected"
              type="search"
              :placeholder="$t('components.shared.be_table.type_to_search')"
              class="border-0 border-bottom rounded-0"
              @keyup="searchList('unselected')"
            />
          </be-input-group>

          <div class="ms-list">
            <div class="list-group list-group-flush">
              <a
                v-for="item in unselectedItems"
                :key="item[keySelector]"
                href="#"
                class="list-group-item list-group-item-action"
                @click.prevent="selectItem(item)"
              >
                {{ valueLabel(item) }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="ms-selection">
        <div class="mb-2 font-weight-semibold">
          {{ $t("components.shared.multi_select.selected") }}
          ({{ selectedItems.length }})
        </div>

        <div
          :class="['border rounded mb-2', { 'border-danger': state === false }]"
        >
          <be-input-group>
            <be-input-group-prepend>
              <be-input-group-text
                class="bg-transparent pr-0 border-0 border-bottom rounded-0"
              >
                <i class="search-icon fal fa-search" />
              </be-input-group-text>
            </be-input-group-prepend>

            <be-form-input
              v-model="search.selected"
              type="search"
              :placeholder="$t('components.shared.be_table.type_to_search')"
              class="border-0 border-bottom rounded-0"
              @keyup="searchList('selected')"
            />
          </be-input-group>

          <div class="ms-list">
            <div class="list-group list-group-flush">
              <a
                v-for="item in selectedItems"
                :key="item[keySelector]"
                href="#"
                class="list-group-item list-group-item-action"
                @click.prevent="selectItem(item)"
              >
                {{ valueLabel(item) }}
              </a>
            </div>
          </div>
        </div>

        <be-form-invalid-feedback :state="state">
          {{ invalidFeedback }}
        </be-form-invalid-feedback>
      </div>
    </div>

    <selection-row @select-all="selectAll" @deselect-all="deselectAll" />
  </div>
</template>

<script>
import Fuse from "fuse.js";
import SelectionRow from "../SelectionRow.vue";

export default {
  components: {
    SelectionRow,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    preselectedIds: {
      type: Array,
      required: false,
      default: () => [],
    },

    keySelector: {
      type: String,
      required: false,
      default: "id",
    },

    valueSelector: {
      type: String,
      required: true,
    },

    state: {
      type: Boolean,
      required: false,
      default: null,
    },

    invalidFeedback: {
      type: String,
      required: false,
      default: "",
    },
  },

  emits: ["change"],

  data() {
    return {
      selectedIds: this.preselectedIds || [],

      searchPlaceholder: this.$i18n.t("components.multi_select.search"),

      search: {
        selected: "",
        unselected: "",

        options: {
          id: this.keySelector,
          keys: [this.valueSelector],
          threshold: 0.3,
          distance: 100,
          findAllMatches: true,
          includeMatches: true,
        },
      },

      searchResults: {
        selected: [],
        unselected: [],
      },
    };
  },

  computed: {
    selectedItems() {
      return this.filterItems("selected");
    },

    unselectedItems() {
      return this.filterItems("unselected");
    },
  },

  watch: {
    preselectedIds: {
      handler(value) {
        this.selectedIds = value;
      },

      deep: true,
    },
  },

  methods: {
    valueLabel(item) {
      return this.valueSelector
        .split(".")
        .reduce((acc, val) => acc && acc[val], item);
    },

    /**
     * Callback from the v-optgroup element whenever auser clicks on the item
     * @param  {[type]} data Object of event data
     */
    selectItem(item) {
      let idx = this.selectedIds.findIndex((i) => i == item[this.keySelector]);

      if (idx > -1) {
        // Remove from selected
        this.selectedIds.splice(idx, 1);
      } else {
        // Add to selected
        this.selectedIds.push(item[this.keySelector]);
      }

      this.$emit("change", this.selectedIds);
    },

    /**
     * Search the list for products with the given search term
     * @param  {[type]} list Which list to search from. unselectedItems or selectedItems
     */
    searchList(list) {
      let searchList = JSON.parse(JSON.stringify(this.items));

      if (list == "selected") {
        searchList = searchList.filter((item) =>
          this.selectedIds.includes(item[this.keySelector])
        );
      } else {
        searchList = searchList.filter(
          (item) => !this.selectedIds.includes(item[this.keySelector])
        );
      }

      this.searchResults[list] = [];

      // Initialize search
      const fuse = new Fuse(searchList, this.search.options);
      const results = fuse.search(this.search[list]);
      results.map((item) => {
        this.searchResults[list].push(item.item[this.keySelector]);
      });
    },

    /**
     * Filter the items to display
     * @param  {[string]} column selected/unselected
     * @return {[array]} Array of filtered items to display
     */
    filterItems(column) {
      let shouldSelect = column == "selected";
      let items = JSON.parse(JSON.stringify(this.items));

      // Remove all non-selected items
      return items.filter((item) => {
        if (this.search[column].length > 0) {
          return this.searchResults[column].includes(item[this.keySelector]);
        }

        return (
          this.selectedIds.includes(item[this.keySelector]) == shouldSelect
        );
      });
    },

    selectAll() {
      this.selectedIds = this.items
        .map((item) => item[this.keySelector])
        .flat();

      this.$emit("change", this.selectedIds);
    },

    deselectAll() {
      this.selectedIds = [];
      this.$emit("change", this.selectedIds);
    },
  },
};
</script>
