<template>
  <li role="presentation">
    <component
      :is="tag"
      :id="id"
      ref="header"
      :class="headerClasses"
      :role="tag === 'header' ? 'heading' : null"
    >
      <slot />
    </component>
  </li>
</template>

<script>
export default {
  name: "BeDropdownHeader",

  props: {
    headerClass: {
      type: [Array, Object, String],
      required: false,
      default: null,
    },

    id: {
      type: String,
      required: false,
      default: null,
    },

    tag: {
      type: String,
      required: false,
      default: "header",
    },

    variant: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    headerClasses() {
      const { headerClass, variant } = this;

      return [
        "dropdown-header",
        headerClass,
        {
          [`text-${variant}`]: variant,
        },
      ];
    },
  },
};
</script>
