<template>
  <div ref="toasts-container" class="toasts-container">
    <div
      v-for="(toast, index) in activeToasts"
      :key="index"
      class="toast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-body" :class="toastClass(toast)">
        <div class="row no-gutters">
          <div v-if="toast.user && toast.user.id" class="col-auto">
            <user-avatar :user="toast.user" class="mr-2" size="lg" />
          </div>

          <div class="col">
            <p class="mb-0">
              <strong class="text-dark">{{ toast.user.name }}</strong>
            </p>

            <p v-dompurify-html="toast.message" class="text-muted mb-0" />
          </div>

          <div class="col-auto">
            <button
              type="button"
              class="ml-2 close"
              data-dismiss="toast"
              aria-label="Close"
              @click="remove(index)"
            >
              <span aria-hidden="true">&times;</span>

              <span class="sr-only">
                {{ $t("buttons.titles.close") }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import KeepNotificationsAlive from "@/mixins/KeepNotificationsAlive";

export default {
  mixins: [KeepNotificationsAlive],

  props: {
    toasts: {
      type: Array,
      default: () => [],
    },

    offset: {
      type: Object,
      required: false,

      default: () => ({
        height: 0,
        width: 0,
      }),
    },
  },

  data() {
    return {
      activeToasts: this.cloneDeep(this.toasts),
    };
  },

  watch: {
    toasts: {
      handler(toasts) {
        this.activeToasts = this.cloneDeep(toasts);
        this.activeToasts.forEach(() => this.erodeToasts());
      },

      deep: true,
    },
  },

  mounted() {
    window.addEventListener("resize", this.positionContainer);
    window.addEventListener("scroll", this.positionContainer);
    window.addEventListener("DOMContentLoaded", this.positionContainer, {
      once: true,
    });

    this.positionContainer();

    window.onbeforeunload = () => {
      this.storeActiveNotifications(this.activeToasts);
    };

    window.addEventListener("new-notification", (data) => {
      EventBus.emit("new-notification", data.detail);
    });

    EventBus.on("new-notification", (event) => {
      const messageIndex = this.activeToasts.findIndex(
        (message) => message.message === event.message
      );

      if (messageIndex > -1) {
        return;
      }

      this.activeToasts.push({
        user: {
          ...{
            name: event.name,
            image_url: null,
          },

          ...this.$currentUser,
        },

        message: event.message,
        created_at: new Date(),
        status: event.status,
      });

      this.erodeToasts();
    });

    this.activeToasts.forEach(() => this.erodeToasts());
  },

  methods: {
    erodeToasts() {
      setTimeout(() => {
        this.activeToasts.shift();
      }, this.$config.TOAST_LIFETIME);
    },

    remove(index) {
      this.activeToasts.splice(index, 1);
    },

    positionContainer() {
      let navbarHeight =
        document.querySelector("#application-header")?.offsetHeight ||
        this.offset.height;
      let sidebarWidth = this.offset.width;
      let activityLog = document.querySelector(".card-activity-log");
      if (activityLog) {
        sidebarWidth = activityLog.offsetWidth;
      }

      let calculatedHeight = 0;
      if (window.scrollY >= 0 && window.scrollY <= navbarHeight) {
        calculatedHeight = navbarHeight - window.scrollY;
      }

      const $el = this.$refs["toasts-container"];
      if ($el) {
        $el.style.top = `${calculatedHeight}px`;
        $el.style.right = `${sidebarWidth}px`;
      }
    },

    toastClass(toast) {
      if (toast.status === "notice") {
        return "";
      }

      return `toast-bg-${toast.status}`;
    },
  },
};
</script>
