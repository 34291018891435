<template>
  <div class="row">
    <div class="col-auto">
      <be-button
        variant="outline-secondary"
        size="sm"
        @click="$emit('selectAll')"
      >
        {{ $t("components.multi_select.buttons.select_all") }}
      </be-button>
    </div>

    <div class="col-auto ml-auto">
      <be-button
        variant="outline-secondary"
        size="sm"
        @click="$emit('deselectAll')"
      >
        {{ $t("components.multi_select.buttons.deselect_all") }}
      </be-button>
    </div>
  </div>
</template>
