<template>
  <multi-select-grouped v-if="grouped" v-bind="$props" @change="onChange" />

  <multi-select-flat v-else v-bind="$props" @change="onChange" />
</template>

<script>
import multiSelectGrouped from "./grouped/MultiSelectGrouped.vue";
import multiSelectFlat from "./flat/MultiSelectFlat.vue";

export default {
  components: {
    multiSelectGrouped,
    multiSelectFlat,
  },

  props: {
    name: {
      type: String,
      required: false,
      default: "multi-select",
    },

    items: {
      type: Array,
      required: true,
    },

    preselectedIds: {
      type: Array,
      required: false,
      default: () => [],
    },

    keySelector: {
      type: String,
      required: false,
      default: "id",
    },

    valueSelector: {
      type: String,
      required: true,
    },

    grouped: {
      type: Boolean,
      required: false,
      default: false,
    },

    state: {
      type: Boolean,
      required: false,
      default: null,
    },

    invalidFeedback: {
      type: String,
      required: false,
      default: "",
    },
  },

  emits: ["change"],

  methods: {
    onChange(selectedItems) {
      this.$emit("change", selectedItems);
    },
  },
};
</script>
