<template>
  <span>
    <slot v-if="timedOut" name="timeout"></slot>

    <slot v-if="statusIs('failed')" name="failed"></slot>

    <slot v-if="statusIs('in_progress')" name="in_progress"></slot>

    <slot v-if="statusIs('initial')" name="initial"></slot>

    <slot
      v-if="statusIs('completed') || overrideCompleted"
      name="completed"
    ></slot>

    <slot></slot>
  </span>
</template>

<script>
const POLLING_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds
const timeout = async (timeout) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

import { createNamespacedHelpers } from "vuex";
const callbackHelpers = createNamespacedHelpers("job_callbacks");

export default {
  props: {
    referenceType: {
      type: String,
      required: false,
      default: null,
    },

    referenceId: {
      type: [String, Number],
      required: false,
      default: null,
    },

    callback: {
      type: Object,
      default: null,
    },

    overrideCompleted: {
      type: Boolean,
      default: false,
    },

    jobKey: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["status", "update"],

  data() {
    return {
      callbackId: null,
      startedPolling: null,
      timedOut: false,
    };
  },

  computed: {
    ...callbackHelpers.mapGetters(["getJobCallbacks"]),

    jobCallback() {
      return this.getJobCallbacks.find((cb) => cb.id === this.callbackId);
    },

    callbackUrl() {
      return (
        this.callback?.paths?.base ||
        this.url(`/job_callbacks/${this.callbackId}`)
      );
    },
  },

  watch: {
    "callback.id"() {
      this.polling(true);
    },
  },

  async mounted() {
    if (this.callback?.id) {
      this.updateCallback(this.callback);
      this.callbackId = this.callback.id;
      this.polling(true);
    } else {
      await this.findCallback();
    }
  },

  methods: {
    ...callbackHelpers.mapMutations(["updateCallback"]),

    async findCallback() {
      try {
        const { data } = await axios.post(this.url("/job_callbacks"), {
          type: this.referenceType,
          id: this.referenceId,
          key: this.jobKey,
        });

        this.updateCallback(data);
        this.$emit("update", data);
        this.callbackId = data.id;
      } catch (error) {
        if (error?.response?.status != 404) {
          this.handleError(error);
        } else {
          this.$emit("status", "not_found");
        }
      }
    },

    async polling(start = false) {
      if (!this.callback?.id) {
        return;
      }
      if (start || !this.startedPolling) {
        this.startedPolling = Date.now();
      }
      if (Date.now() - this.startedPolling > POLLING_DURATION) {
        this.timedOut = true;
      }

      try {
        const { data } = await axios.get(this.callbackUrl);

        this.updateCallback(data);
        this.$emit("update", data);
        this.$emit("status", data.status);
        if (["in_progress", "initial"].includes(data.status)) {
          await timeout(3000);
          await this.polling();
        }
      } catch (error) {
        if (error?.response?.status != 404) {
          this.handleError(error, { sentryLog: false });
        } else {
          this.$emit("status", "not_found");
        }
      }
    },

    statusIs(status) {
      if (!this.jobCallback?.id || this.timedOut) {
        return false;
      }

      return this.jobCallback.status === status;
    },
  },
};
</script>
