<template>
  <span>
    {{ formattedRelativeTime }}
  </span>
</template>

<script>
import { formatRelative } from "date-fns";
import { sv, enUS } from "date-fns/locale";

export default {
  name: "BeRelativeTime",

  props: {
    value: {
      required: true,
      type: String,
    },
  },

  computed: {
    valueAsDate() {
      return new Date(this.value);
    },

    formattedRelativeTime() {
      return formatRelative(this.valueAsDate, new Date(), {
        locale: this.dateFnsLocale,
      });
    },

    dateFnsLocale() {
      const localeString = this.$i18n.locale;
      if (localeString === "sv") {
        return sv;
      } else {
        return enUS;
      }
    },
  },
};
</script>
