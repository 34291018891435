// Based on app/lib/error_handling.rb#scrub_url
export const scrubUrl = (url: string): string => {
  try {
    const uuidRegex = /[\w-]{8}-[\w-]{4}-[\w-]{4}-[\w-]{4}-[\w-]{12}/g;
    // Match iso8601 dates
    // - 2024-05-17T09:40:20Z
    // - 2024-05-17T09:40:20.123Z
    // - 2024-05-17T09:40:20+02:00
    // - 2024-05-17
    const dateRegex =
      /\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|(\+\d{2}:\d{2}))?)?/g;
    const nanoidPattern = /\/(companies|admin_panel)\/([\w-]{8})/gi;

    const urlFilters: [RegExp, string][] = [
      [uuidRegex, "uuid"],
      [dateRegex, "date"],
      [/\/\d+\//g, "/integer/"],
      [/\/\d+$/, "/integer"],
    ];

    const queryFilters: [RegExp, string][] = [
      [uuidRegex, "uuid"],
      [dateRegex, "date"],
      [/^\d+$/g, "integer"],
    ];

    const baseURL = "https://example.com";
    const uri = new URL(url, baseURL);

    // Apply path filters
    uri.pathname = urlFilters.reduce((path, [regex, replacement]) => {
      return path.replace(regex, replacement);
    }, uri.pathname);

    uri.pathname = uri.pathname.replace(nanoidPattern, (match, p1) => {
      return `/${p1}/nanoid`;
    });

    // Apply query filters
    const searchParams = new URLSearchParams(uri.search);
    for (const [key, value] of searchParams) {
      let newValue = value;
      queryFilters.forEach(([regex, replacement]) => {
        newValue = newValue.replace(regex, replacement);
      });
      searchParams.set(key, newValue);
    }
    uri.search = searchParams.toString();

    return uri.toString().replace(baseURL, "");
  } catch (e) {
    return url;
  }
};
