// This method checks if the current context matches the specified controllers, actions, and excludes.
// If the context matches without any exclusions, it returns true.
function itemActive({ context, controllers, actions = [], exclude = [] }) {
  actions = actions || [];
  return controllers.some((controller) => {
    return (
      context.controller === controller &&
      (actions.length === 0 ||
        actions.some((action) => context.action === action)) &&
      !exclude.some(
        (excluded) =>
          context.controller === excluded.controller &&
          context.action === excluded.action
      )
    );
  });
}

export { itemActive };
