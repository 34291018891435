// Inspired by BootstrapVue
// https://github.com/bootstrap-vue-next/bootstrap-vue-next/blob/main/packages/bootstrap-vue-next/src/utils/filterEvent.ts

const TABLE_TAG_NAMES = ["TD", "TH", "TR"];

// Filter CSS selector for click events
// If any of these selectors match the clicked element, we ignore the event
const eventFilter = [
  "a",
  "a *", // Include content inside links
  "button",
  "button *", // Include content inside buttons
  "input:not(.disabled):not([disabled])",
  "select:not(.disabled):not([disabled])",
  "textarea:not(.disabled):not([disabled])",
  '[role="link"]',
  '[role="link"] *', // Include content inside links
  '[role="button"]',
  '[role="button"] *', // Include content inside buttons
  "[tabindex]:not(.disabled):not([disabled])",
].join(", ");

// Returns `true` if the event should be ignored
// This avoids having to use `@click.stop` on every clickable element
export default (event) => {
  // Exit early if we don't have an event or a target element
  if (!event || !event.target) {
    return false;
  }

  const el = event.target;

  // Exit early when element is disabled or a table element
  if (
    ("disabled" in el && el.disabled) ||
    TABLE_TAG_NAMES.includes(el.tagName)
  ) {
    return false;
  }

  // Ignore the click when it was inside a dropdown menu
  if (el.closest(".dropdown-menu")) {
    return true;
  }

  // Ignore the click if it was on a label that is linked to a non-disabled input
  const label = el.tagName === "LABEL" ? el : el.closest("label");
  if (label) {
    const labelFor = label.getAttribute("for");
    const input = labelFor ? document.getElementById(labelFor) : null;
    if (input && !input.disabled) {
      return true;
    }
  }

  // Otherwise check if the event target matches the filter and
  // return `true` if we should ignore the event
  return el.matches(eventFilter);
};
