<template>
  <div class="d-flex align-items-center">
    <user-avatar v-if="user" :user="user" size="sm" class="mr-1" />

    {{ option[optionLabel] }}
  </div>
</template>

<script>
export default {
  inject: ["optionLabel", "optionValue"],

  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  computed: {
    user() {
      return this.option.user || this.option.id || this.option.label;
    },
  },
};
</script>
