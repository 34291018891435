<template>
  <be-button
    :disabled="copied"
    :variant="variant"
    icon="fa-copy"
    @click="copyToClipboard"
  >
    <span v-if="!copied">{{ $t("buttons.titles.copy") }}</span>
  </be-button>
</template>
<script>
import { EventBus } from "@/event-bus";

export default {
  name: "BeCopyToClipboardButton",

  props: {
    value: {
      type: String,
      required: true,
    },

    variant: {
      type: String,
      required: false,
      default: "primary",
    },
  },

  data() {
    return {
      copied: false,
    };
  },

  methods: {
    async copyToClipboard() {
      await navigator.clipboard.writeText(this.value);
      EventBus.emit("new-notification", {
        message: this.$i18n.t(
          "components.shared.be_copy_to_clipboard_button.content_copied_to_clipboard"
        ),

        status: "notice",
      });
    },
  },
};
</script>
