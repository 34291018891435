export default {
  methods: {
    convertFunctionToValue(value) {
      return typeof value === "function" ? value(this) : value;
    },

    convertFunctionsToValues(obj) {
      Object.keys(obj).forEach((key) => {
        obj[key] = this.convertFunctionToValue(obj[key]);
      });

      return obj;
    },

    formatComponentProps(props) {
      Object.keys(props).forEach((key) => {
        if (typeof props[key] === "function") {
          props[key] = props[key](this);
        }
      });

      return props;
    },
  },
};
