<template>
  <li role="presentation">
    <component
      :is="tag"
      ref="text"
      :class="['be-dropdown-text', textClass, { [`text-${variant}`]: variant }]"
    >
      <slot />
    </component>
  </li>
</template>

<script>
export default {
  name: "BeDropdownText",

  props: {
    tag: {
      type: String,
      required: false,
      default: "p",
    },

    textClass: {
      type: [Array, Object, String],
      required: false,
      default: null,
    },

    variant: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
