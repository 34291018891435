<template>
  <option :value="value" :disabled="disabled || null">
    <slot />
  </option>
</template>

<script>
export default {
  name: "NativeSelectOption",

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    value: {
      type: undefined,
      required: true,
    },
  },
};
</script>
