<template>
  <div :data-status="status">
    <be-status v-if="loading" loading="loading">
      {{ localSavingText }}
    </be-status>

    <template v-else>
      <!-- Failed to save - used for errors we cannot recover from -->
      <be-status
        v-if="failedToSave"
        variant="danger"
        class="text-danger"
        icon="fa-hexagon-exclamation"
        icon-style="fas"
      >
        {{ localFailedToSaveText }}
      </be-status>

      <!-- Validations failed - recoverable -->
      <be-status
        v-else-if="validationFailed"
        variant="danger"
        class="text-danger"
        icon="fa-hexagon-exclamation"
        icon-style="fas"
      >
        {{ localValidationFailedText }}
      </be-status>

      <!-- Unsaved changes -->
      <be-status
        v-else-if="unsavedChanges"
        variant="primary"
        icon="fa-exclamation-circle"
        icon-style="fas"
      >
        {{ localUnsavedChangesText }}
      </be-status>

      <!-- Last saved at -->
      <be-status
        v-else-if="lastSavedAt != null"
        variant="success"
        icon="fa-check-circle"
        icon-style="fas"
      >
        <span v-if="lastSavedAtPrefix" class="text-muted">
          {{ lastSavedAtPrefix }}
        </span>

        <span class="text-muted">
          {{ lastSavedAtText }}
        </span>
      </be-status>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    failedToSave: {
      type: Boolean,
      required: false,
      default: false,
    },

    failedToSaveText: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.save_status.failed_to_save")
    },

    validationFailed: {
      type: Boolean,
      required: false,
      default: false,
    },

    validationFailedText: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.save_status.validation_failed")
    },

    savingText: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.save_status.saving")
    },

    unsavedChangesText: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.save_status.unsaved_changes")
    },

    unsavedChanges: {
      type: Boolean,
      required: false,
      default: false,
    },

    lastSavedAt: {
      type: [Date, String],
      required: false,
      default: null,
    },

    lastSavedAtPrefix: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    formattedLastSavedAt() {
      if (this.lastSavedAt instanceof Date && isFinite(this.lastSavedAt)) {
        return this.$d(
          this.lastSavedAt,
          this.dateFormatIfSameDay(this.lastSavedAt)
        );
      } else {
        return null;
      }
    },

    lastSavedAtText() {
      if (typeof this.lastSavedAt === "string") {
        return this.lastSavedAt;
      } else {
        return this.$t("components.shared.save_status.last_saved_at_w_time", {
          time: this.formattedLastSavedAt,
        });
      }
    },

    localFailedToSaveText() {
      if (this.failedToSaveText === undefined) {
        return this.$t("components.shared.save_status.failed_to_save");
      }

      return this.failedToSaveText;
    },

    localValidationFailedText() {
      if (this.validationFailedText === undefined) {
        return this.$t("components.shared.save_status.validation_failed");
      }

      return this.validationFailedText;
    },

    localSavingText() {
      if (this.savingText === undefined) {
        return this.$t("components.shared.save_status.saving");
      }

      return this.savingText;
    },

    localUnsavedChangesText() {
      if (this.unsavedChangesText === undefined) {
        return this.$t("components.shared.save_status.unsaved_changes");
      }

      return this.unsavedChangesText;
    },

    status() {
      if (this.loading) {
        return "loading";
      } else if (this.failedToSave) {
        return "failed";
      } else if (this.unsavedChanges) {
        return "unsaved";
      } else if (this.lastSavedAt != null) {
        return "saved";
      } else {
        return null;
      }
    },
  },
};
</script>
