<template>
  <component
    :is="templateComponent"
    v-if="templateComponent"
    :option="option"
  />

  <template v-else>
    <div>{{ option[optionLabel] }}</div>
  </template>
</template>

<script>
import CountryTemplate from "@/components/shared/be_form_select/templates/CountryTemplate.vue";
import CurrencyTemplate from "@/components/shared/be_form_select/templates/CurrencyTemplate.vue";
import UserTemplate from "@/components/shared/be_form_select/templates/UserTemplate.vue";

const AVAILABLE_TEMPLATES = {
  country: "CountryTemplate",
  currency: "CurrencyTemplate",
  locale: "CountryTemplate",
  user: "UserTemplate",
};

export default {
  components: {
    CountryTemplate,
    CurrencyTemplate,
    UserTemplate,
  },

  props: {
    option: {
      type: Object,
      required: true,
    },

    template: {
      type: String,
      required: true,
    },
  },

  computed: {
    templateComponent() {
      return AVAILABLE_TEMPLATES[this.template] || null;
    },
  },
};
</script>
