<template>
  <be-form-group :label="label" :label-for="`dp-input-${id}`" class="m-0">
    <be-input-group class="mb-3">
      <be-form-datepicker
        :id="id"
        v-model="localStartDate"
        :max-date="maxDate"
        :allowed-dates="allowedDates"
        :disabled-dates="disabledDates"
        inline
        @change="onChange"
      />

      <be-input-group-separator is-text>-</be-input-group-separator>

      <be-form-datepicker
        v-model="localEndDate"
        :min-date="minDate"
        :allowed-dates="allowedDates"
        :disabled-dates="disabledDates"
        inline
        @change="onChange"
      />
    </be-input-group>
  </be-form-group>
</template>

<script>
import { generateId } from "@/utils/id";

export default {
  props: {
    allowedDates: {
      type: Array,
      required: false,
      default: null,
    },

    disabledDates: {
      type: Array,
      required: false,
      default: null,
    },

    label: {
      type: String,
      required: true,
    },

    minDate: {
      type: [String, Date],
      required: false,
      default: null,
    },

    maxDate: {
      type: [String, Date],
      required: false,
      default: null,
    },
  },

  emits: ["change"],

  data() {
    return {
      id: generateId("be-table-date-filter"),
      localStartDate: null,
      localEndDate: null,
    };
  },

  methods: {
    onChange() {
      this.$emit("change", {
        start: this.localStartDate,
        end: this.localEndDate,
      });
    },
  },
};
</script>
