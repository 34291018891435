<template>
  <div>
    <div class="card-body">
      <template v-if="readOnly">
        <be-table
          v-if="sentInvitations.length > 0"
          :items="sentInvitations"
          :fields="fields"
          :per-page="30"
        >
          <template #avatar="{ item }">
            <user-avatar :user="getUser(item.user_id)" />
          </template>

          <template #name="{ item }">
            <div>
              {{ getUserName(item.user_id) }}
            </div>

            <membership-subtitle class="small" :user-id="item.user_id" />
          </template>

          <template #email="{ item }">
            <be-link :href="`mailto:${getUserEmail(item.user_id)}`">
              {{ getUserEmail(item.user_id) }}
            </be-link>
          </template>

          <template #created_at="{ item }">
            {{ $d(new Date(item.updated_at), { format: "mini" }) }}
          </template>
        </be-table>

        <be-alert v-else variant="info">
          {{ $t("components.meetings.invitations.no_invitations_sent") }}
        </be-alert>
      </template>

      <template v-else>
        <invitation-batch
          v-model:include-agenda="invitationBatch.include_agenda"
          v-model:message="invitationBatch.message"
          :invitations="invitations"
          :invitation-header="$t('components.meetings.invitations.sent_at')"
          :selected-user-ids="selectedUserIds"
          :meeting="meeting"
          @update:selected-user-ids="(value) => (selectedUserIds = value)"
          @memberships-updated="membershipsUpdated"
        />
      </template>
    </div>

    <div v-if="!readOnly" class="card-footer">
      <div class="d-md-flex justify-content-end gap-2">
        <be-button
          variant="outline-secondary"
          :disabled="skipButtonDisabled"
          @click="skipInvitations"
        >
          {{ $t("components.meetings.invitations.skip_invitations") }}
        </be-button>

        <be-button
          v-be-tooltip="{
            title: $t('components.meetings.invitations.invite_disabled'),
            disabled: !sendButtonDisabled,
          }"
          variant="primary"
          :disabled="sendButtonDisabled"
          :loading="isSending"
          icon="fa-envelope"
          @click="sendInvites"
        >
          {{ $t("components.meetings.invitations.send") }}
        </be-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import InvitationBatch from "@/components/meetings/InvitationBatch.vue";
import MembershipSubtitle from "@/components/shared/MembershipSubtitle.vue";

export default {
  components: {
    InvitationBatch,
    MembershipSubtitle,
  },

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    loadedInvitations: {
      type: Array,
      required: true,
    },

    lastInvitationBatch: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    let lastBatchSent = null;
    let message = "";
    if (this.lastInvitationBatch?.created_at) {
      lastBatchSent = new Date(this.lastInvitationBatch.created_at);
      message = this.lastInvitationBatch.message;
    }
    return {
      isSending: false,

      invitationBatch: {
        include_agenda: false,
        message: message,
      },

      lastBatchSent: lastBatchSent,
      selectedUserIds: [],
    };
  },

  computed: {
    ...mapGetters({
      company: "company/getCompany",
    }),

    invitations() {
      return this.$store.getters["meetings/getInvitationsForMeeting"](
        this.meeting?.id
      );
    },

    sentInvitations() {
      return this.invitations.filter((invitation) => invitation.id != null);
    },

    sendButtonDisabled() {
      return (
        this.isSending ||
        !this.meeting.start_at ||
        this.selectedUserIds.length == 0
      );
    },

    skipButtonDisabled() {
      return this.isSending || !this.meeting.start_at;
    },

    boardmembers() {
      return this.company.memberships
        .filter((membership) => membership.board_member)
        .map((membership) => membership.user.id);
    },

    fields() {
      return [
        { key: "avatar", label: "", class: "col-shrink" },
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },
        {
          key: "email",
          label: this.translateAttribute("user", "email"),
        },
        {
          key: "created_at",
          label: this.$t("components.meetings.invitations.sent_at"),
        },
      ];
    },
  },

  async created() {
    await this.$store.commit("meetings/loadInvitationsForMeeting", {
      meeting: this.meeting,
      invitations: this.loadedInvitations,
    });

    this.selectedUserIds = this.boardmembers;
  },

  methods: {
    async skipInvitations() {
      await axios.patch(`${this.meeting.paths.base}/skip_invitations`);
      this.navigateToNextStep();
    },

    navigateToNextStep() {
      const url = this.meeting.paths.base;
      history.pushState(null, null, url);
      location.reload();
    },

    membershipsUpdated() {
      this.$store.dispatch("meetings/fetchInvitationsForMeeting", this.meeting);
    },

    async sendInvites() {
      this.isSending = true;

      try {
        const response = await this.$store.dispatch(
          "meetings/saveInvitationBatchUnhandled",
          {
            meeting: this.meeting,

            invitationBatch: {
              message: this.invitationBatch.message,
              include_agenda: this.invitationBatch.include_agenda,
              user_ids: this.selectedUserIds,
            },
          }
        );
        this.invitationBatch = response.data;
        response.data.invitations.forEach((invitation) => {
          this.$store.commit("meetings/updateInvitation", invitation);
        });

        this.navigateToNextStep();
      } catch (error) {
        if (error?.response?.status === 422) {
          this.invitationBatch = error.response.data;
        } else {
          this.handleError(error);
        }
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>
