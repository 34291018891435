import { Node } from "@tiptap/core";

export const TextColumnsNode = Node.create({
  name: "text-columns",
  group: "block",
  content: "(paragraph|list)+",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        tag: "div.text-columns-2",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", { ...HTMLAttributes, class: "text-columns-2" }, 0];
  },

  addCommands() {
    return {
      toggleTextColumns:
        () =>
        ({ commands }) => {
          return commands.toggleWrap(this.name);
        },
    };
  },
});

export default {
  TextColumnsNode,
};
