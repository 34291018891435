<template>
  <be-form-group :label="label" :label-for="id" class="m-0">
    <be-form-datepicker
      v-model="date"
      :min-date="minDate"
      :max-date="maxDate"
      :allowed-dates="allowedDates"
      :disabled-dates="disabledDates"
      inline
      @change="onChange"
    />
  </be-form-group>
</template>

<script>
import { generateId } from "@/utils/id";

export default {
  props: {
    allowedDates: {
      type: Array,
      required: false,
      default: null,
    },

    disabledDates: {
      type: Array,
      required: false,
      default: null,
    },

    label: {
      type: String,
      required: true,
    },

    minDate: {
      type: [String, Date],
      required: false,
      default: null,
    },

    maxDate: {
      type: [String, Date],
      required: false,
      default: null,
    },
  },

  emits: ["change"],

  data() {
    return {
      id: generateId("be-table-date-filter"),
      date: null,
    };
  },

  methods: {
    onChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>
