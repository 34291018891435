import i18n from "@/i18n";
import COUNTRIES from "@/constants/countries";
import CURRENCIES from "@/constants/currencies";
import LOCALES from "@/constants/locales";

import { compareText } from "@/utils/text-utils";
import { formatOptions } from "@/components/shared/be_form_select/utils";

const SUGGESTED_OPTIONS = {
  countries: ["SE", "NO", "DK", "FI"],
  currencies: ["SEK", "NOK", "DKK", "EUR", "USD"],
};

const presetWithSuggestions = (type, options, labelKey, valueKey) => {
  const suggestedOptions = options.filter((option) => {
    return SUGGESTED_OPTIONS[type].includes(option[valueKey]);
  });

  return [
    {
      label: i18n.global.t("components.shared.be_form_select.suggested"),

      options: formatOptions(suggestedOptions, labelKey, valueKey)
        .map((option) => ({ ...option, suggestion: true }))
        .sort((a, b) => compareText(a, b, "label")),
    },
    {
      label: i18n.global.t("components.shared.be_form_select.all"),

      options: formatOptions(options, labelKey, valueKey).sort((a, b) =>
        compareText(a, b, "label")
      ),
    },
  ];
};

const getPresetOptions = (preset, locale = "sv") => {
  switch (preset) {
    case "countries":
      return presetWithSuggestions("countries", COUNTRIES, locale, "code");
    case "currencies":
      return presetWithSuggestions("currencies", CURRENCIES, "name", "code");
    case "locales":
      return formatOptions(LOCALES, "language", "value");
    default:
      console.warn("Unknown preset", preset);
      return [];
  }
};

export { getPresetOptions };
