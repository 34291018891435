<template>
  <be-modal
    :id="modalId"
    :ok-disabled="disableOK"
    :title="$t('components.documents.inform_users.share_document')"
    size="lg"
    @ok="handleOk"
    @hide="resetForm"
  >
    <template #ok-button>
      <be-spinner v-if="sending" variant="light" />

      <template v-else>
        <i class="fal fa-envelope" />
        {{ $t("buttons.titles.send") }}
      </template>
    </template>

    <table class="table">
      <tbody>
        <tr>
          <td>
            <document-link
              v-if="document && document.id"
              :document-id="document.id"
              :filename="document.filename"
              :icon="document.fontawesome_icon"
              :title="document.title"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <be-form-group :label="$t('agendas.message')" label-for="autotextarea">
      <be-form-textarea
        id="autotextarea"
        v-model="message"
        rows="5"
        max-rows="20"
      />
    </be-form-group>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="col-shrink"></th>

            <th class="col-shrink"></th>

            <th>{{ $t("activerecord.attributes.membership.name") }}</th>

            <th class="col-shrink"></th>
          </tr>
        </thead>

        <tbody>
          <inform-user-membership-row
            v-for="membership in memberships"
            :key="`membership-${membership.id}`"
            :checked="checked.includes(membership.user.id) || false"
            :membership="membership"
            :primary-memberships="primaryMemberships"
            :secondary-memberships="secondaryMemberships"
            @user-checked-updated="updateChecked"
          />
        </tbody>
      </table>
    </div>

    <be-button
      v-if="!disableUserSelection"
      variant="outline-secondary"
      size="sm"
      @click="toggleAllUsers"
    >
      {{ userSelectButtonTitle }}
    </be-button>
  </be-modal>
</template>

<script>
import InformUserMembershipRow from "@/components/shared/InformUserMembershipRow.vue";

export default {
  components: {
    InformUserMembershipRow,
  },

  props: {
    disableUserSelection: {
      type: Boolean,
      required: false,
      default: false,
    },

    document: {
      type: Object,
      required: true,
    },

    primaryMemberships: {
      type: Array,
      required: false,
      default: null,
    },

    secondaryMemberships: {
      type: Array,
      required: false,
      default: null,
    },

    formUrl: {
      type: String,
      required: true,
    },

    excludeCurrentUser: {
      type: Boolean,
      required: false,
      default: false,
    },

    showAutomatically: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["users-informed", "reset"],

  data() {
    return {
      checked: [],
      message: null,
      sending: false,
      modalId: `inform-modal-${this.generateUuid()}`,
    };
  },

  computed: {
    memberships() {
      let selectedMemberships = [];

      if (this.primaryMemberships) {
        selectedMemberships = this.primaryMemberships;
      } else if (this.secondaryMemberships) {
        selectedMemberships = this.secondaryMemberships;
      } else if (this.$currentCompany.memberships) {
        selectedMemberships = this.$currentCompany.memberships;
      }

      if (!this.excludeCurrentUser) {
        return selectedMemberships;
      }

      // Exclude current user
      return selectedMemberships.filter((membership) => {
        return membership.user.id != this.$currentUser.id;
      });
    },

    userSelectButtonTitle() {
      if (this.allChecked) {
        return this.$i18n.t("buttons.toggle_all_selection.deselect_all");
      } else {
        return this.$i18n.t("buttons.toggle_all_selection.select_all");
      }
    },

    disableOK() {
      if (this.sending) {
        return true;
      }
      return this.checked.length < 1;
    },

    allChecked() {
      return this.checked.length === this.memberships.length;
    },
  },

  watch: {
    document(value) {
      if (this.showAutomatically) {
        if (value.id) {
          this.$beModal.show(this.modalId);
        } else {
          this.$beModal.hide(this.modalId);
        }
      }
    },
  },

  methods: {
    checkUser(user) {
      const idx = this.checked.indexOf(user.id);

      if (idx == -1) {
        this.checked.push(user.id);
      }
    },

    resetForm() {
      this.message = null;
      this.$emit("reset");
    },

    uncheckUser(user) {
      const index = this.checked.findIndex((userId) => userId == user.id);

      if (index != -1) {
        this.checked.splice(index, 1);
      }
    },

    updateChecked(user, checked) {
      if (checked) {
        this.checkUser(user);
      } else {
        this.uncheckUser(user);
      }
    },

    handleOk(beModalEvt) {
      // Prevent modal from closing
      beModalEvt.preventDefault();
      this.sendNotifications();
    },

    async sendNotifications() {
      try {
        this.sending = true;
        const response = await axios.post(this.formUrl, {
          document: {
            id: this.document.id,
            folder_id: this.document.folder_id,
          },

          message: this.message,
          users: this.checked,
        });

        this.$emit("users-informed", response.data);
        this.$nextTick(() => {
          this.resetForm();
        });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.sending = false;
      }
    },

    toggleAllUsers() {
      if (this.allChecked) {
        this.checked = [];
      } else {
        const newChecked = [];
        this.memberships.forEach((membership) => {
          newChecked.push(membership.user.id);
        });
        this.checked = newChecked;
      }
    },
  },
};
</script>
