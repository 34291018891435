<template>
  <slot />
</template>
<script>
export default {
  props: {
    initialPolicyLevel: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      policyLevel: this.initialPolicyLevel,
    };
  },

  methods: {
    setPolicyLevel(event) {
      this.policyLevel = event.target.value;
    },
  },
};
</script>
