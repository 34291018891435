// This file is generated by app/lib/prepare_javascript_config.rb
export default [
  { code: "AD", en: "Andorra", sv: "Andorra" },
  { code: "AE", en: "United Arab Emirates", sv: "Förenade Arabemiraten" },
  { code: "AF", en: "Afghanistan", sv: "Afghanistan" },
  { code: "AG", en: "Antigua and Barbuda", sv: "Antigua och Barbuda" },
  { code: "AI", en: "Anguilla", sv: "Anguilla" },
  { code: "AL", en: "Albania", sv: "Albanien" },
  { code: "AM", en: "Armenia", sv: "Armenien" },
  { code: "AO", en: "Angola", sv: "Angola" },
  { code: "AQ", en: "Antarctica", sv: "Antarktis" },
  { code: "AR", en: "Argentina", sv: "Argentina" },
  { code: "AS", en: "American Samoa", sv: "Amerikanska Samoa" },
  { code: "AT", en: "Austria", sv: "Österrike" },
  { code: "AU", en: "Australia", sv: "Australien" },
  { code: "AW", en: "Aruba", sv: "Aruba" },
  { code: "AX", en: "Åland Islands", sv: "Åland" },
  { code: "AZ", en: "Azerbaijan", sv: "Azerbajdzjan" },
  { code: "BA", en: "Bosnia and Herzegovina", sv: "Bosnien-Hercegovina" },
  { code: "BB", en: "Barbados", sv: "Barbados" },
  { code: "BD", en: "Bangladesh", sv: "Bangladesh" },
  { code: "BE", en: "Belgium", sv: "Belgien" },
  { code: "BF", en: "Burkina Faso", sv: "Burkina Faso" },
  { code: "BG", en: "Bulgaria", sv: "Bulgarien" },
  { code: "BH", en: "Bahrain", sv: "Bahrain" },
  { code: "BI", en: "Burundi", sv: "Burundi" },
  { code: "BJ", en: "Benin", sv: "Benin" },
  { code: "BL", en: "Saint Barthélemy", sv: "Saint-Barthélemy" },
  { code: "BM", en: "Bermuda", sv: "Bermuda" },
  { code: "BN", en: "Brunei Darussalam", sv: "Brunei" },
  { code: "BO", en: "Bolivia (Plurinational State of)", sv: "Bolivia" },
  {
    code: "BQ",
    en: "Bonaire, Sint Eustatius and Saba",
    sv: "Bonaire, Sint Eustatius och Saba",
  },
  { code: "BR", en: "Brazil", sv: "Brasilien" },
  { code: "BS", en: "Bahamas", sv: "Bahamas" },
  { code: "BT", en: "Bhutan", sv: "Bhutan" },
  { code: "BV", en: "Bouvet Island", sv: "Bouvetön" },
  { code: "BW", en: "Botswana", sv: "Botswana" },
  { code: "BY", en: "Belarus", sv: "Vitryssland" },
  { code: "BZ", en: "Belize", sv: "Belize" },
  { code: "CA", en: "Canada", sv: "Kanada" },
  { code: "CC", en: "Cocos (Keeling) Islands", sv: "Kokosöarna" },
  {
    code: "CD",
    en: "Congo (Democratic Republic of the)",
    sv: "Kongo, demokratiska republiken",
  },
  {
    code: "CF",
    en: "Central African Republic",
    sv: "Centralafrikanska republiken",
  },
  { code: "CG", en: "Congo", sv: "Kongo" },
  { code: "CH", en: "Switzerland", sv: "Schweiz" },
  { code: "CI", en: "Côte d'Ivoire", sv: "Elfenbenskusten" },
  { code: "CK", en: "Cook Islands", sv: "Cooköarna" },
  { code: "CL", en: "Chile", sv: "Chile" },
  { code: "CM", en: "Cameroon", sv: "Kamerun" },
  { code: "CN", en: "China", sv: "Kina" },
  { code: "CO", en: "Colombia", sv: "Colombia" },
  { code: "CR", en: "Costa Rica", sv: "Costa Rica" },
  { code: "CU", en: "Cuba", sv: "Kuba" },
  { code: "CV", en: "Cabo Verde", sv: "Kap Verde" },
  { code: "CW", en: "Curaçao", sv: "Curaçao" },
  { code: "CX", en: "Christmas Island", sv: "Julön" },
  { code: "CY", en: "Cyprus", sv: "Cypern" },
  { code: "CZ", en: "Czechia", sv: "Tjeckien" },
  { code: "DE", en: "Germany", sv: "Tyskland" },
  { code: "DJ", en: "Djibouti", sv: "Djibouti" },
  { code: "DK", en: "Denmark", sv: "Danmark" },
  { code: "DM", en: "Dominica", sv: "Dominica" },
  { code: "DO", en: "Dominican Republic", sv: "Dominikanska republiken" },
  { code: "DZ", en: "Algeria", sv: "Algeriet" },
  { code: "EC", en: "Ecuador", sv: "Ecuador" },
  { code: "EE", en: "Estonia", sv: "Estland" },
  { code: "EG", en: "Egypt", sv: "Egypten" },
  { code: "EH", en: "Western Sahara", sv: "Västsahara" },
  { code: "ER", en: "Eritrea", sv: "Eritrea" },
  { code: "ES", en: "Spain", sv: "Spanien" },
  { code: "ET", en: "Ethiopia", sv: "Etiopien" },
  { code: "FI", en: "Finland", sv: "Finland" },
  { code: "FJ", en: "Fiji", sv: "Fiji" },
  {
    code: "FK",
    en: "Falkland Islands (Malvinas)",
    sv: "Falklandsöarna (Malvinas)",
  },
  {
    code: "FM",
    en: "Micronesia (Federated States of)",
    sv: "Mikronesien, federala staterna",
  },
  { code: "FO", en: "Faroe Islands", sv: "Färöarna" },
  { code: "FR", en: "France", sv: "Frankrike" },
  { code: "GA", en: "Gabon", sv: "Gabon" },
  {
    code: "GB",
    en: "United Kingdom of Great Britain and Northern Ireland",
    sv: "Storbritannien",
  },
  { code: "GD", en: "Grenada", sv: "Grenada" },
  { code: "GE", en: "Georgia", sv: "Georgien" },
  { code: "GF", en: "French Guiana", sv: "Franska Guyana" },
  { code: "GG", en: "Guernsey", sv: "Guernsey" },
  { code: "GH", en: "Ghana", sv: "Ghana" },
  { code: "GI", en: "Gibraltar", sv: "Gibraltar" },
  { code: "GL", en: "Greenland", sv: "Grönland" },
  { code: "GM", en: "Gambia", sv: "Gambia" },
  { code: "GN", en: "Guinea", sv: "Guinea" },
  { code: "GP", en: "Guadeloupe", sv: "Guadeloupe" },
  { code: "GQ", en: "Equatorial Guinea", sv: "Ekvatorialguinea" },
  { code: "GR", en: "Greece", sv: "Grekland" },
  {
    code: "GS",
    en: "South Georgia and the South Sandwich Islands",
    sv: "Sydgeorgien och södra Sandwichöarna",
  },
  { code: "GT", en: "Guatemala", sv: "Guatemala" },
  { code: "GU", en: "Guam", sv: "Guam" },
  { code: "GW", en: "Guinea-Bissau", sv: "Guinea-Bissau" },
  { code: "GY", en: "Guyana", sv: "Guyana" },
  { code: "HK", en: "Hong Kong", sv: "Hongkong" },
  {
    code: "HM",
    en: "Heard Island and McDonald Islands",
    sv: "Heardön och McDonaldöarna",
  },
  { code: "HN", en: "Honduras", sv: "Honduras" },
  { code: "HR", en: "Croatia", sv: "Kroatien" },
  { code: "HT", en: "Haiti", sv: "Haiti" },
  { code: "HU", en: "Hungary", sv: "Ungern" },
  { code: "ID", en: "Indonesia", sv: "Indonesien" },
  { code: "IE", en: "Ireland", sv: "Irland" },
  { code: "IL", en: "Israel", sv: "Israel" },
  { code: "IM", en: "Isle of Man", sv: "Isle of Man" },
  { code: "IN", en: "India", sv: "Indien" },
  {
    code: "IO",
    en: "British Indian Ocean Territory",
    sv: "Brittiskt territorium i Indiska Oceanen",
  },
  { code: "IQ", en: "Iraq", sv: "Irak" },
  {
    code: "IR",
    en: "Iran (Islamic Republic of)",
    sv: "Iran, islamiska republiken",
  },
  { code: "IS", en: "Iceland", sv: "Island" },
  { code: "IT", en: "Italy", sv: "Italien" },
  { code: "JE", en: "Jersey", sv: "Jersey" },
  { code: "JM", en: "Jamaica", sv: "Jamaica" },
  { code: "JO", en: "Jordan", sv: "Jordanien" },
  { code: "JP", en: "Japan", sv: "Japan" },
  { code: "KE", en: "Kenya", sv: "Kenya" },
  { code: "KG", en: "Kyrgyzstan", sv: "Kirgizistan" },
  { code: "KH", en: "Cambodia", sv: "Kambodja" },
  { code: "KI", en: "Kiribati", sv: "Kiribati" },
  { code: "KM", en: "Comoros", sv: "Comorerna" },
  { code: "KN", en: "Saint Kitts and Nevis", sv: "Sankt Kitts och Nevis" },
  {
    code: "KP",
    en: "Korea (Democratic People's Republic of)",
    sv: "Nordkorea",
  },
  { code: "KR", en: "Korea (Republic of)", sv: "Sydkorea" },
  { code: "KW", en: "Kuwait", sv: "Kuwait" },
  { code: "KY", en: "Cayman Islands", sv: "Caymanöarna" },
  { code: "KZ", en: "Kazakhstan", sv: "Kazakstan" },
  {
    code: "LA",
    en: "Lao People's Democratic Republic",
    sv: "Demokratiska folkrepubliken Lao",
  },
  { code: "LB", en: "Lebanon", sv: "Libanon" },
  { code: "LC", en: "Saint Lucia", sv: "Sankt Lucia" },
  { code: "LI", en: "Liechtenstein", sv: "Liechtenstein" },
  { code: "LK", en: "Sri Lanka", sv: "Sri Lanka" },
  { code: "LR", en: "Liberia", sv: "Liberia" },
  { code: "LS", en: "Lesotho", sv: "Lesotho" },
  { code: "LT", en: "Lithuania", sv: "Litauen" },
  { code: "LU", en: "Luxembourg", sv: "Luxemburg" },
  { code: "LV", en: "Latvia", sv: "Lettland" },
  { code: "LY", en: "Libya", sv: "Libyen" },
  { code: "MA", en: "Morocco", sv: "Marocko" },
  { code: "MC", en: "Monaco", sv: "Monaco" },
  { code: "MD", en: "Moldova (Republic of)", sv: "Moldavien" },
  { code: "ME", en: "Montenegro", sv: "Montenegro" },
  {
    code: "MF",
    en: "Saint Martin (French part)",
    sv: "Saint Martin (franska delen)",
  },
  { code: "MG", en: "Madagascar", sv: "Madagaskar" },
  { code: "MH", en: "Marshall Islands", sv: "Marshallöarna" },
  { code: "MK", en: "North Macedonia", sv: "Nordmakedonien" },
  { code: "ML", en: "Mali", sv: "Mali" },
  { code: "MM", en: "Myanmar", sv: "Myanmar" },
  { code: "MN", en: "Mongolia", sv: "Mongoliet" },
  { code: "MO", en: "Macao", sv: "Macao" },
  { code: "MP", en: "Northern Mariana Islands", sv: "Nordmarianerna" },
  { code: "MQ", en: "Martinique", sv: "Martinique" },
  { code: "MR", en: "Mauritania", sv: "Mauretanien" },
  { code: "MS", en: "Montserrat", sv: "Montserrat" },
  { code: "MT", en: "Malta", sv: "Malta" },
  { code: "MU", en: "Mauritius", sv: "Mauritius" },
  { code: "MV", en: "Maldives", sv: "Maldiverna" },
  { code: "MW", en: "Malawi", sv: "Malawi" },
  { code: "MX", en: "Mexico", sv: "Mexiko" },
  { code: "MY", en: "Malaysia", sv: "Malaysia" },
  { code: "MZ", en: "Mozambique", sv: "Moçambique" },
  { code: "NA", en: "Namibia", sv: "Namibia" },
  { code: "NC", en: "New Caledonia", sv: "Nya Kaledonien" },
  { code: "NE", en: "Niger", sv: "Niger" },
  { code: "NF", en: "Norfolk Island", sv: "Norfolköarna" },
  { code: "NG", en: "Nigeria", sv: "Nigeria" },
  { code: "NI", en: "Nicaragua", sv: "Nicaragua" },
  { code: "NL", en: "Netherlands", sv: "Nederländerna" },
  { code: "NO", en: "Norway", sv: "Norge" },
  { code: "NP", en: "Nepal", sv: "Nepal" },
  { code: "NR", en: "Nauru", sv: "Nauru" },
  { code: "NU", en: "Niue", sv: "Niue" },
  { code: "NZ", en: "New Zealand", sv: "Nya Zeeland" },
  { code: "OM", en: "Oman", sv: "Oman" },
  { code: "PA", en: "Panama", sv: "Panama" },
  { code: "PE", en: "Peru", sv: "Peru" },
  { code: "PF", en: "French Polynesia", sv: "Franska Polynesien" },
  { code: "PG", en: "Papua New Guinea", sv: "Papua Nya Guinea" },
  { code: "PH", en: "Philippines", sv: "Filippinerna" },
  { code: "PK", en: "Pakistan", sv: "Pakistan" },
  { code: "PL", en: "Poland", sv: "Polen" },
  {
    code: "PM",
    en: "Saint Pierre and Miquelon",
    sv: "Sankt Pierre och Miquelon",
  },
  { code: "PN", en: "Pitcairn", sv: "Pitcairn" },
  { code: "PR", en: "Puerto Rico", sv: "Puerto Rico" },
  { code: "PS", en: "Palestine, State of", sv: "Staten Palestina" },
  { code: "PT", en: "Portugal", sv: "Portugal" },
  { code: "PW", en: "Palau", sv: "Palau" },
  { code: "PY", en: "Paraguay", sv: "Paraguay" },
  { code: "QA", en: "Qatar", sv: "Qatar" },
  { code: "RE", en: "Réunion", sv: "Réunion" },
  { code: "RO", en: "Romania", sv: "Rumänien" },
  { code: "RS", en: "Serbia", sv: "Serbien" },
  { code: "RU", en: "Russian Federation", sv: "Ryssland" },
  { code: "RW", en: "Rwanda", sv: "Rwanda" },
  { code: "SA", en: "Saudi Arabia", sv: "Saudiarabien" },
  { code: "SB", en: "Solomon Islands", sv: "Salomonöarna" },
  { code: "SC", en: "Seychelles", sv: "Seychellerna" },
  { code: "SD", en: "Sudan", sv: "Sudan" },
  { code: "SE", en: "Sweden", sv: "Sverige" },
  { code: "SG", en: "Singapore", sv: "Singapore" },
  {
    code: "SH",
    en: "Saint Helena, Ascension and Tristan da Cunha",
    sv: "Saint Helena, Ascension och Tristan da Cunha",
  },
  { code: "SI", en: "Slovenia", sv: "Slovenien" },
  { code: "SJ", en: "Svalbard and Jan Mayen", sv: "Svalbard och Jan Mayen" },
  { code: "SK", en: "Slovakia", sv: "Slovakien" },
  { code: "SL", en: "Sierra Leone", sv: "Sierra Leone" },
  { code: "SM", en: "San Marino", sv: "San Marino" },
  { code: "SN", en: "Senegal", sv: "Senegal" },
  { code: "SO", en: "Somalia", sv: "Somalia" },
  { code: "SR", en: "Suriname", sv: "Surinam" },
  { code: "SS", en: "South Sudan", sv: "Sydsudan" },
  { code: "ST", en: "Sao Tome and Principe", sv: "São Tomé och Príncipe" },
  { code: "SV", en: "El Salvador", sv: "El Salvador" },
  {
    code: "SX",
    en: "Sint Maarten (Dutch part)",
    sv: "Sint Maarten (nederländska delen)",
  },
  { code: "SY", en: "Syrian Arab Republic", sv: "Syriska arabrepubliken" },
  { code: "SZ", en: "Eswatini", sv: "Swaziland" },
  { code: "TC", en: "Turks and Caicos Islands", sv: "Turks- och Caicosöarna" },
  { code: "TD", en: "Chad", sv: "Tchad" },
  {
    code: "TF",
    en: "French Southern Territories",
    sv: "Franska sydterritorierna",
  },
  { code: "TG", en: "Togo", sv: "Togo" },
  { code: "TH", en: "Thailand", sv: "Thailand" },
  { code: "TJ", en: "Tajikistan", sv: "Tadzjikistan" },
  { code: "TK", en: "Tokelau", sv: "Tokelau" },
  { code: "TL", en: "Timor-Leste", sv: "Östtimor" },
  { code: "TM", en: "Turkmenistan", sv: "Turkmenistan" },
  { code: "TN", en: "Tunisia", sv: "Tunisien" },
  { code: "TO", en: "Tonga", sv: "Tonga" },
  { code: "TR", en: "Türkiye", sv: "Turkiet" },
  { code: "TT", en: "Trinidad and Tobago", sv: "Trinidad och Tobago" },
  { code: "TV", en: "Tuvalu", sv: "Tuvalu" },
  { code: "TW", en: "Taiwan, Province of China", sv: "Taiwan" },
  { code: "TZ", en: "Tanzania, United Republic of", sv: "Tanzania" },
  { code: "UA", en: "Ukraine", sv: "Ukraina" },
  { code: "UG", en: "Uganda", sv: "Uganda" },
  {
    code: "UM",
    en: "United States Minor Outlying Islands",
    sv: "USA:s avlägsna mindre öar",
  },
  { code: "US", en: "United States of America", sv: "USA" },
  { code: "UY", en: "Uruguay", sv: "Uruguay" },
  { code: "UZ", en: "Uzbekistan", sv: "Uzbekistan" },
  { code: "VA", en: "Holy See", sv: "Vatikanstaten" },
  {
    code: "VC",
    en: "Saint Vincent and the Grenadines",
    sv: "Sankt Vincent och Grenadinerna",
  },
  { code: "VE", en: "Venezuela (Bolivarian Republic of)", sv: "Venezuela" },
  { code: "VG", en: "Virgin Islands (British)", sv: "Jungfruöarna, brittiska" },
  { code: "VI", en: "Virgin Islands (U.S.)", sv: "Jungfruöarna, amerikanska" },
  { code: "VN", en: "Viet Nam", sv: "Vietnam" },
  { code: "VU", en: "Vanuatu", sv: "Vanuatu" },
  { code: "WF", en: "Wallis and Futuna", sv: "Wallis och Futuna" },
  { code: "WS", en: "Samoa", sv: "Samoa" },
  { code: "YE", en: "Yemen", sv: "Yemen" },
  { code: "YT", en: "Mayotte", sv: "Mayotte" },
  { code: "ZA", en: "South Africa", sv: "Sydafrika" },
  { code: "ZM", en: "Zambia", sv: "Zambia" },
  { code: "ZW", en: "Zimbabwe", sv: "Zimbabwe" },
];
