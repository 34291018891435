<template>
  <div v-if="mode === 'template'" class="mb-3 p-4 border rounded">
    <h5>{{ $t("models.report_component.titles.decisions") }}</h5>

    <be-skeleton-table
      :rows="2"
      :columns="1"
      :table-props="{ striped: true }"
    />
  </div>

  <div v-else class="mb-3" data-element="decisions-viewer">
    <div v-if="decisionReferences.length > 0" class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="col-shrink" />

            <th>{{ $t("activerecord.models.decision.one") }}</th>

            <th class="col-shrink">
              {{ $t("activerecord.attributes.decision.status_title") }}
            </th>

            <th class="col-shrink">
              {{ $t("activerecord.attributes.decision.due_at_title") }}
            </th>

            <th class="col-shrink">
              {{ $t("activerecord.attributes.decision.assigned_to") }}
            </th>

            <th v-if="allowRemove" class="col-shrink" />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="reference in decisionReferences"
            :key="`decision-${reference.reference_id}`"
            :class="`status-decisions-${reference.decision.status}`"
          >
            <td class="col-shrink">
              {{
                `#${reference.decision.meeting_number}-${reference.decision.number}`
              }}
            </td>

            <td>
              <a
                v-dompurify-html="truncateText(reference.decision.description)"
                :href="
                  url(`decisions/${reference.reference_id}`, {
                    company: $currentCompany,
                  })
                "
              />
            </td>

            <td class="col-shrink">
              {{ $t(`models.decision.statuses.${reference.decision.status}`) }}
            </td>

            <td class="col-shrink">
              <span v-if="!!reference.decision.due_at">
                {{ $d(new Date(reference.decision.due_at)) }}
              </span>
            </td>

            <td class="col-shrink text-center">
              <user-avatar
                v-if="reference.decision.user_id"
                :user="reference.decision.user_id"
                class="d-print-none"
              />

              <p class="text-left m-0 d-none d-print-block">
                {{ getUserName(reference.decision.user_id) }}
              </p>
            </td>

            <td v-if="allowRemove" class="col-shrink">
              <be-button
                variant="danger"
                size="sm"
                :loading="loadingIds.includes(reference.reference_id)"
                icon="fa-times"
                @click="$emit('remove-reference', reference)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <comments-viewer
      v-if="!hideComment && element.comments"
      :comments="element.comments"
    />
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import CommentsViewer from "./CommentsViewer.vue";
import TextUtilities from "@/mixins/textUtilities";
import BeButton from "@/components/shared/BeButton.vue"; // Needed since this component is rendered in PDF-view

export default {
  components: {
    BeButton,
    CommentsViewer,
  },

  mixins: [TextUtilities],

  props: {
    editing: {
      type: Boolean,
      required: false,
      default: false,
    },

    element: {
      type: Object,
      required: true,
    },

    hideComment: {
      type: Boolean,
      required: false,
      default: false,
    },

    mode: {
      type: String,
      required: false,
      default: "report",
    },

    loadingIds: {
      type: Array,
      required: false,
      default: () => [],
    },

    allowRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["remove-reference"],

  computed: {
    decisionReferences() {
      return this.element.report_component_references.filter(
        (reference) => reference.reference_type === "Decision"
      );
    },
  },

  mounted() {
    EventBus.emit("report-component-initialized", this.element);
  },
};
</script>
