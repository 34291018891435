<template>
  <button
    :id="id"
    :class="[
      'be-form-custom-select-button',
      stateClass,
      { 'text-truncate': !multiple },
    ]"
    role="button"
    type="button"
    :disabled="disabled"
    @click="onButtonClick"
    @keydown="onButtonKeydown"
  >
    <!-- Multiple selection tags -->
    <div v-if="multiple" class="be-form-custom-select-tags">
      <template v-if="disabled">
        {{
          selected
            .map((option) => getNestedValue(option, optionLabel))
            .join(", ")
        }}
      </template>

      <template v-else v-for="(option, index) in selected" :key="index">
        <div class="be-form-custom-select-tag">
          <span class="be-form-custom-select-tag-label">
            <slot name="tag" :option="option" :index="index">
              {{ getNestedValue(option, optionLabel) }}
            </slot>
          </span>

          <button
            v-be-tooltip="$t('buttons.titles.remove')"
            :aria-label="`${$t('buttons.titles.remove')} '${getNestedValue(option, optionLabel)}'`"
            type="button"
            class="be-form-custom-select-tag-remove"
            @click.stop="deselectOption(option)"
            @keydown.stop="onTagRemoveKeydown($event, option)"
          >
            <i class="fal fa-times fa-sm" />
          </button>
        </div>
      </template>
    </div>

    <!-- Single selection label -->
    <template v-else-if="selected">
      <slot name="selected" :option="selected">
        {{ getNestedValue(selected, optionLabel) }}
      </slot>
    </template>
  </button>
</template>

<script>
import { KEY_CODE_DELETE, KEY_CODE_ENTER } from "@/constants/key-codes";

export default {
  inject: ["disabled", "multiple", "optionLabel", "selected", "stateClass"],

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  emits: ["keydown", "toggle-dropdown", "deselect-option"],

  methods: {
    deselectOption(option) {
      this.$emit("deselect-option", option);
    },

    getNestedValue(obj, path) {
      // Get nested value from object, e.g. "user.name"
      // If nested value is null, return empty string
      return path.split(".").reduce((o, i) => o?.[i] ?? "", obj);
    },

    onButtonClick() {
      this.$emit("toggle-dropdown");
    },

    onButtonKeydown(event) {
      this.$emit("keydown", event);
    },

    onTagRemoveKeydown(event, option) {
      const { keyCode } = event;

      if ([KEY_CODE_DELETE, KEY_CODE_ENTER].includes(keyCode)) {
        event.preventDefault();
        event.stopPropagation();

        this.deselectOption(option);

        const nextTag = event.target.nextElementSibling;
        if (nextTag) {
          nextTag.focus();
        } else {
          this.$el.focus();
        }
      }
    },
  },
};
</script>
