export const ALLOWED_TYPES = {
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  jpeg: "image/jpeg",
  jpg: "image/jpg",
  pdf: "application/pdf",
  png: "image/png",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  svg: "image/svg+xml",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xhtml: "application/xhtml+xml",
};

export const ALLOWED_TYPES_MIME = Object.fromEntries(
  Object.entries(ALLOWED_TYPES).map(([key, value]) => [value, key])
);

export const IMAGE_TYPES = ["jpg", "jpeg", "png", "svg"];
