<template>
  <component :is="tag" class="input-group-text">
    <slot />
  </component>
</template>

<script>
export default {
  name: "BeInputGroupText",

  props: {
    tag: {
      type: String,
      required: false,
      default: "div",
    },
  },
};
</script>
