<template>
  <optgroup :label="label">
    <template v-for="(option, index) in computedOptions" :key="index">
      <native-select-option-group
        v-if="Array.isArray(option.options)"
        :label="option[optionLabel]"
        :options="option.options"
      />

      <native-select-option
        v-else
        :value="option[optionValue]"
        :disabled="option.disabled"
      >
        {{ option[optionLabel] }}
      </native-select-option>
    </template>

    <slot />
  </optgroup>
</template>

<script>
import NativeSelectOption from "@/components/shared/be_form_select/NativeSelectOption.vue";
import { formatOptions } from "@/components/shared/be_form_select/utils";

export default {
  name: "NativeSelectOptionGroup",

  components: {
    NativeSelectOption,
  },

  inject: ["optionLabel", "optionValue"],

  props: {
    label: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    computedOptions() {
      return formatOptions(this.options, this.optionLabel, this.optionValue);
    },
  },
};
</script>
