<template>
  <div id="row-template-editor"></div>
</template>

<script>
export default {
  props: {
    urlPrefix: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selector: "#row-template-editor",
      initialized: false,
      loading: false,
      wzController: null,
      removeConfiguration: false,
    };
  },

  mounted() {
    this.waitForFinancials(this.initializeComponent);
  },

  methods: {
    initializeComponent() {
      if (this.initialized) {
        return;
      }

      this.initialized = true;

      window
        .wzGetController()
        .addRowTemplateEditor(
          this.selector,
          this.$activeFinancialsUuid,
          (uuid) => {
            const url = `${this.urlPrefix}/tables/${uuid}/edit`;
            window.location.href = url;
          }
        );
    },
  },
};
</script>
