<template>
  <div v-if="loading" class="card-body">
    <be-spinner>
      {{ $t("buttons.states.loading") }}
    </be-spinner>
  </div>

  <be-scroll-area v-else :height="scrollableHeight" :options="scrollbarOptions">
    <be-list-group flush>
      <transition-group name="animation-appear">
        <activity
          v-for="(activity, index) in recentActivities"
          :key="`activity-${activity.id}`"
          :activity="activity"
          :class="{ 'border-top-0': index === 0 }"
        />
      </transition-group>
    </be-list-group>

    <p
      v-if="!haveActivities"
      class="text-muted text-center font-italic m-4"
      v-text="$t('components.activity_log.no_activities')"
    />
  </be-scroll-area>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Activity from "./ActivityLogItem.vue";
import { scrollableSidebar } from "@/mixins/scrollableSidebar";

export default {
  components: {
    Activity,
  },

  mixins: [scrollableSidebar],

  data: () => ({
    loading: true,
  }),

  channels: {
    "Companies::PublicActivityChannel": {
      connected() {
        console.debug("Subscribed to PublicActivityChannel");
      },

      rejected() {},

      received(response) {
        this.pushActivity(response);
      },

      disconnected() {
        console.warn("Disconnected from PublicActivityChannel");
      },
    },
  },

  computed: {
    ...mapGetters("session", {
      activities: "getActivities",
      activitiesLoaded: "activityInitialized",
    }),

    recentActivities() {
      if (this.$currentCompany.id) {
        return this.$store.getters["session/getActivitiesForCompany"](
          this.$currentCompany.id
        ).slice(0, this.$config.MAX_ACTIVITIES);
      }

      return this.activities.slice(0, this.$config.MAX_ACTIVITIES);
    },

    haveActivities() {
      return this.recentActivities.length > 0;
    },
  },

  async created() {
    if (this.recentActivities.length > 0) {
      this.loading = false;
    }

    const payload = {
      companyId: this.$currentCompany?.id,
    };

    // ActionCable disabled until implemented per membership
    // if (this.$currentCompany?.id) {
    //   this.$cable.subscribe({
    //     channel: "Companies::PublicActivityChannel",
    //     company_id: this.$currentCompany.id,
    //   });
    // }

    if (!this.activitiesLoaded) {
      await this.fetchActivities(payload);
    } else {
      await this.syncActivities(payload);
    }

    this.loading = false;
  },

  methods: {
    ...mapActions("session", [
      "fetchActivities",
      "syncActivities",
      "pushActivity",
    ]),
  },
};
</script>
