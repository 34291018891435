<template>
  <div
    :id="id"
    class="be-no-focus-ring"
    :aria-invalid="computedAriaInvalid || null"
    :aria-required="required ? 'true' : null"
    role="group"
    tabindex="-1"
  >
    <slot name="first" />

    <be-form-checkbox
      v-for="(option, index) in normalizedOptions"
      :key="index"
      :autofocus="autofocus && index === 0"
      :checked-value="option.value"
      :disabled="disabled || option.disabled"
      :description="option.description"
      :form="form"
      :inline="inline"
      :model-value="optionIsChecked(option) ? option.value : null"
      :name="groupName"
      :required="required"
      :size="size"
      @update:model-value="handleCheckboxUpdate($event, option.value)"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-if="option.html" v-html="option.html" />

      <span v-else v-text="option.text" />
    </be-form-checkbox>

    <slot />
  </div>
</template>

<script>
import formStateMixin from "@/mixins/forms/form-state";
import formOptionsMixin from "@/mixins/forms/form-options";
import { generateId } from "@/utils/id";

export default {
  name: "BeFormCheckboxGroup",

  mixins: [formStateMixin, formOptionsMixin],

  props: {
    ariaInvalid: {
      type: [Boolean, String],
      default: false,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    form: {
      type: String,
      default: null,
    },

    id: {
      type: String,
      default: () => generateId("be-form-checkbox-group"),
    },

    modelValue: {
      type: Array,
      default: () => [],
    },

    name: {
      type: String,
      default: null,
    },

    required: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: null,
    },

    stacked: {
      type: Boolean,
      default: false,
    },

    validated: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["input", "update:modelValue"],

  data() {
    return {
      localChecked: this.modelValue,
    };
  },

  computed: {
    inline() {
      return !this.stacked;
    },

    groupName() {
      return this.name || this.id;
    },
  },

  watch: {
    modelValue: {
      handler(checked) {
        if (checked !== this.localChecked) {
          this.localChecked = checked;
        }
      },

      deep: true,
    },

    localChecked: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit("input", newValue);
          this.$emit("update:modelValue", newValue);
        }
      },

      deep: true,
    },
  },

  methods: {
    handleCheckboxUpdate(checked, value) {
      if (checked) {
        this.localChecked.push(value);
      } else {
        this.localChecked = this.localChecked.filter((v) => v !== value);
      }
    },

    optionIsChecked(option) {
      return this.localChecked.includes(option.value);
    },
  },
};
</script>
