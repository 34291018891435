<template>
  <component :is="tag" class="be-scroll-area" :style="computedStyle">
    <slot />
  </component>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";

const PS_EVENT_NAMES = [
  "scroll",
  "ps-scroll-y",
  "ps-scroll-x",
  "ps-scroll-up",
  "ps-scroll-down",
  "ps-scroll-left",
  "ps-scroll-right",
  "ps-y-reach-start",
  "ps-y-reach-end",
  "ps-x-reach-start",
  "ps-x-reach-end",
];

export default {
  name: "BeScrollArea",

  props: {
    height: {
      type: String,
      required: false,
      default: "100%",
    },

    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    tag: {
      type: String,
      required: false,
      default: "div",
    },

    width: {
      type: String,
      required: false,
      default: "100%",
    },
  },

  data() {
    return {
      ps: null,
    };
  },

  computed: {
    computedStyle() {
      return {
        height: this.height,
        position: "relative",
        width: this.width,
      };
    },
  },

  mounted() {
    this.initializePS();
  },

  methods: {
    initializePS() {
      if (this.ps) {
        this.destroyPS();
      }

      this.ps = new PerfectScrollbar(this.$el, {
        ...this.options,
      });

      PS_EVENT_NAMES.forEach((eventName) => {
        this.ps.element.addEventListener(eventName, (event) => {
          this.$emit(eventName, event);
        });
      });
    },

    updatePS() {
      if (this.ps) {
        this.ps.update();
      }
    },

    destroyPS() {
      if (this.ps) {
        this.ps.destroy();
        this.ps = null;
      }
    },
  },
};
</script>
