<template>
  <div class="progress" :style="progressHeight">
    <slot>
      <be-progress-bar v-bind="$props" />
    </slot>
  </div>
</template>

<script>
export default {
  name: "BeProgress",

  props: {
    animated: {
      type: Boolean,
      required: false,
      default: false,
    },

    height: {
      type: String,
      required: false,
      default: null,
    },

    max: {
      type: Number,
      required: false,
      default: 100,
    },

    precision: {
      type: Number,
      required: false,
      default: 0,
    },

    showProgress: {
      type: Boolean,
      required: false,
      default: false,
    },

    showValue: {
      type: Boolean,
      required: false,
      default: false,
    },

    striped: {
      type: Boolean,
      required: false,
      default: false,
    },

    value: {
      type: Number,
      required: false,
      default: null,
    },

    variant: {
      type: String,
      required: false,
      default: "primary",
    },
  },

  computed: {
    progressHeight() {
      return { height: this.height || null };
    },
  },
};
</script>
