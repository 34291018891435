/**
 * Hashtag and mention wrapper for the TextEditor component
 */
import "quill-mention";
import Quill from "quill";

import { mapGetters } from "vuex";
import materialState from "@/components/meetings/material/materialState";
import HashtagBlot from "@/vendor/quill/be-hashtag-blot";
Quill.register(HashtagBlot, true);

import { MEETING_HASHTAGS } from "./data/hashtags";
import { toSentence } from "@/utils/text-utils";

export default {
  mixins: [materialState],

  data() {
    return {
      hashtagRefreshKey: this.generateUuid(),
    };
  },

  computed: {
    ...mapGetters("material", ["editorType", "meeting"]),
    ...mapGetters("attendances", ["attendances"]),

    beMentionQuillOptions() {
      return {
        mention: {
          allowedChars: /^[A-Za-zÅÄÖåäö]*$/,
          mentionDenotationChars: ["#"],
          isolateCharacter: true,
          spaceAfterInsert: false,

          dataAttributes: [
            "id",
            "value",
            "placeholder",
            "denotationChar",
            "link",
            "target",
            "disabled",
          ],

          blotName: "hashtag-blot",

          renderItem(item) {
            return `#${item.placeholder}`;
          },

          source: (searchTerm, renderList) => {
            // Match against `id`, `placeholder_sv` and `placeholder_en`
            const matchedHashtag = this.hashtags.filter((hashtag) =>
              [
                hashtag.id.toLowerCase(),
                hashtag.placeholder_sv.toLowerCase(),
                hashtag.placeholder_en.toLowerCase(),
              ].some((match) => match.includes(searchTerm.toLowerCase()))
            );

            renderList(matchedHashtag);
          },

          onSelect(item, insertItem) {
            item.replaced = item.value != item.placeholder;

            insertItem(item);
          },
        },
      };
    },

    hashtags() {
      return MEETING_HASHTAGS.map((data) => {
        let hashtag = {
          id: data.id,
          placeholder_sv: this.$t(`models.phrase.tags.${data.id}.value`, "sv"),
          placeholder_en: this.$t(`models.phrase.tags.${data.id}.value`, "en"),
        };

        if (this.$currentCompany.locale === "en") {
          hashtag.placeholder = hashtag.placeholder_en;
        } else {
          hashtag.placeholder = hashtag.placeholder_sv;
        }
        hashtag.value = this.hashtagValue(hashtag);

        return hashtag;
      });
    },

    chairmanHashtag() {
      return this.attendances.find(
        (attendance) => attendance.function === "chairman"
      )?.name;
    },

    secretaryHashtag() {
      return this.attendances.find((attendance) => attendance.secretary)?.name;
    },

    reviewerHashtag() {
      const results = this.attendances.filter(
        (attendance) =>
          attendance.reviewer && attendance.function !== "chairman"
      );
      if (results.length > 0) {
        return this.formatNames(results.map((reviewer) => reviewer.name));
      }
    },

    reviewerIncludingChairmanHashtag() {
      const results = this.attendances.filter(
        (attendance) => attendance.reviewer
      );
      if (results.length > 0) {
        return this.formatNames(results.map((reviewer) => reviewer.name));
      }
    },

    ceoHashtag() {
      const results = this.$currentCompany.memberships.filter(
        (membership) => membership.role === "ceo"
      );
      if (results.length > 0) {
        return this.formatNames(results.map((result) => result.user.name));
      }
    },

    cfoHashtag() {
      const results = this.$currentCompany.memberships.filter(
        (membership) => membership.role === "cfo"
      );
      if (results.length > 0) {
        return this.formatNames(results.map((result) => result.user.name));
      }
    },

    nextmeetingHashtag() {
      if (this.meeting?.next_start_at) {
        return this.$i18n.d(
          new Date(this.meeting?.next_start_at),
          "humanReadableWithTime"
        );
      }
    },
  },

  watch: {
    attendances: {
      handler() {
        this.refreshHashtagValues();
      },

      deep: true,
    },

    hashtagRefreshKey() {
      this.refreshHashtagValues();
    },
  },

  methods: {
    refreshHashtagValues() {
      // Don't replace in template mode or when minutes doesn't have any attendances
      if (this.isTemplate || (this.isMinutes && this.attendances.length == 0)) {
        return;
      }

      this.refreshMentions();
    },

    isValid(tag) {
      return MEETING_HASHTAGS.find((hashtag) => hashtag.id === tag);
    },

    hashtagValue(hashtag) {
      switch (hashtag.id) {
        case "chairman":
          return this.chairmanHashtag || hashtag.placeholder;
        case "secretary":
          return this.secretaryHashtag || hashtag.placeholder;
        case "reviewer":
          return this.reviewerHashtag || hashtag.placeholder;
        case "reviewer_including_chairman":
          return this.reviewerIncludingChairmanHashtag || hashtag.placeholder;
        case "ceo":
          return this.ceoHashtag || hashtag.placeholder;
        case "financialmanager":
          return this.cfoHashtag || hashtag.placeholder;
        case "next_meeting":
          return this.nextmeetingHashtag || hashtag.placeholder;
        default:
          return hashtag.id;
      }
    },

    formatNames(names) {
      const locale = this.$currentCompany.locale;

      return toSentence(names, locale);
    },

    refreshMentions() {
      const parser = new DOMParser();
      let bodyHtml = parser.parseFromString(this.body, "text/html");
      bodyHtml = this.convertLegacyHashtags(bodyHtml);
      const mentions = bodyHtml.querySelectorAll("span.mention");

      mentions.forEach((mention) => {
        const dataset = mention.dataset;
        const mentionDenotationChar = dataset.denotationChar;
        const mentionId = dataset.id;

        if (mentionDenotationChar === "#") {
          const hashtag = this.hashtags.find(
            (hashtag) => hashtag.id == mentionId
          );

          // Refresh Hashtags
          if (hashtag) {
            mention.dataset.value = this.hashtags.find(
              (tags) => tags.id == hashtag.id
            )?.value;

            mention.dataset.replaced =
              mention.dataset.value != hashtag.placeholder;
          } else {
            console.warn(`Couldn't find hashtag with ID ${mentionId}`);
          }
        }
      });

      // Set the content for Quill
      let index = 0;
      if (this.quill) {
        index = this.quill.getSelection()?.index || 0;
      }
      this.body = bodyHtml.body.innerHTML;
      this.quill?.pasteHTML(bodyHtml.body.innerHTML);
      this.quill?.setSelection(index);
    },

    // This also handles replacing hashtags added via phrases
    mapLegacyHashtag(id) {
      // Try to find a placeholder that matches the id. This will allow us to
      // support multiple languages from old, legacy templates.
      return this.hashtags.find((hashtag) => {
        return (
          hashtag.id === id ||
          hashtag.placeholder_en === id ||
          hashtag.placeholder_sv === id
        );
      });
    },

    convertLegacyHashtags(bodyHtml) {
      bodyHtml.body.innerHTML = bodyHtml.body.innerHTML.replace(
        /\B#[A-Za-zÅÄÖåäö]*\b/g,
        (match) => {
          const hashtag = this.mapLegacyHashtag(match.replace("#", ""));

          if (hashtag) {
            return `<span class="mention" data-denotation-char="#" data-id="${hashtag.id}" data-legacy="true">
              ${hashtag.value}
            </span>`;
          } else {
            // If a matched hashtag is not supported by the system,
            // return the hashtag as normal text.
            return match;
          }
        }
      );

      return bodyHtml;
    },
  },
};
